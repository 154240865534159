import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ErrorResponse } from "../../redux_store/error/models";
import { AllActionsOf } from "../utils/Actions";
import {
  PromoState,
  SetAvailablePromos,
  SetAvailablePromoSelected,
  SetPromos,
  SetShowPromotionsModal,
  SetStoreOfferIdSelected,
  SetStoreOffers,
} from "./models";

const initialState: PromoState = {
  promos: [],
  availablePromos: [],
  storeOffers: [],
  storeOfferIdSelected: undefined,
  selectedAvailablePromo: undefined,
  selectedAvailablePromoDetails: undefined,
  isPromotionsModalVisible: false,
  error: undefined,
};

const promoSlice = createSlice({
  name: "promo",
  initialState: initialState,
  reducers: {
    setPromoState(state, action: PayloadAction<PromoState>) {
      return (state = action.payload);
    },
    setPromos(state, action: PayloadAction<SetPromos>) {
      if (action.payload.clearCurrentPromos) {
        state.promos = [];
      }
      state.promos = [...state.promos, ...action.payload.promos];
    },
    setStoreOffers(state, action: PayloadAction<SetStoreOffers>) {
      if (action.payload.clearCurrentStoreOffers) {
        state.storeOffers = [];
      }
      state.storeOffers = [...state.storeOffers, ...action.payload.storeOffers];
    },
    getStoreOffers(state) {
      return state;
    },
    setAvailablePromos(state, action: PayloadAction<SetAvailablePromos>) {
      if (action.payload.clearCurrentAvaiablePromos) {
        state.availablePromos = [];
      }

      state.availablePromos = [
        ...state.availablePromos,
        ...action.payload.availablePromos,
      ];
    },
    getAvailablePromos(state) {
      return state;
    },
    setStoreOfferIdSelected(
      state,
      action: PayloadAction<SetStoreOfferIdSelected>
    ) {
      if (action.payload.clearCurrentPromoSelected) {
        state.storeOfferIdSelected = undefined;
      }

      state.storeOfferIdSelected = action.payload.storeOfferId;
    },
    setAvailablePromoSelected(
      state,
      action: PayloadAction<SetAvailablePromoSelected>
    ) {
      if (
        action.payload.selectedAvailablePromo &&
        action.payload.selectedAvailablePromoDetails
      ) {
        state.selectedAvailablePromo = action.payload.selectedAvailablePromo;
        state.selectedAvailablePromoDetails =
          action.payload.selectedAvailablePromoDetails;
        state.isPromotionsModalVisible = true;
      }
    },
    setShowPromotionsModal(
      state,
      action: PayloadAction<SetShowPromotionsModal>
    ) {
      state.isPromotionsModalVisible = action.payload.isPromotionsModalVisible;
    },
    error(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = promoSlice;
export type PromoActionsType = AllActionsOf<typeof actions>;
export { initialState, actions as promoActions, reducer as promoReducer };
