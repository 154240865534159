import { ScreenParams, Screens } from "./const";

/**
 * Creates path with optional parameter.
 * @param basePath defined screen path
 * @param parameter
 * @returns full route path
 */
export const createPathWithParam = (
  basePath: Screens,
  parameter: ScreenParams
): string => {
  return `${basePath}/:${parameter}?`;
};
