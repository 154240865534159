import {
  Animated,
  Easing,
  NativeScrollEvent,
  NativeSyntheticEvent,
} from "react-native";

import { ProductConst } from "../../";

/**
Function creates fade animation to show or hide sticker header based on scroll value and returns boolean value of sticker should be shown or now
*/
export const handleProductScroll = (
  event: NativeSyntheticEvent<NativeScrollEvent>,
  stickerVisible: boolean,
  setStickerVisible: (isVisible: boolean) => void,
  fadeAnimationProductDetail: Animated.Value,
  fadeAnimationSticky: Animated.Value
): boolean | undefined => {
  if (
    event.nativeEvent.contentOffset.y >=
    ProductConst.STICKY_HEADER_DISPLAY_VALUE
  ) {
    if (!stickerVisible) {
      setStickerVisible(true);
      Animated.parallel([
        Animated.timing(fadeAnimationProductDetail, {
          toValue: 0,
          duration: 150,
          easing: Easing.linear,
          delay: -1,
          useNativeDriver: true,
        }),

        Animated.timing(fadeAnimationSticky, {
          toValue: 1,
          duration: 150,
          easing: Easing.linear,
          delay: -1,
          useNativeDriver: true,
        }),
      ]).start();
      return true;
    }
  } else {
    if (stickerVisible) {
      setStickerVisible(false);
      Animated.parallel([
        Animated.timing(fadeAnimationProductDetail, {
          toValue: 1,
          duration: 150,
          easing: Easing.linear,
          delay: -1,
          useNativeDriver: true,
        }),

        Animated.timing(fadeAnimationSticky, {
          toValue: 0,
          duration: 150,
          easing: Easing.linear,
          delay: -1,
          useNativeDriver: true,
        }),
      ]).start();
      return false;
    }
  }
};

export const getFormattedPrice = (price: number | undefined) => {
  return !!price ? `$${price?.toFixed(2)}` : "$0.00";
};
