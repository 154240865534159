import { CartItem, Modifier } from "../../../redux_store/cart/models";

const modifierSortCompareFn = (a: Modifier, b: Modifier) => a.id - b.id;

/**
 * Compares array of modifiers and returns true if arrays are equal.
 * @param cartItemModis
 * @param favouriteModis
 * @returns
 */
export const compareModis = (
  cartItemModis: Modifier[],
  favouriteModis: Modifier[]
): boolean => {
  if (cartItemModis.length !== favouriteModis.length) {
    return false;
  }

  // note: we don't want modi id dedupe behavior, so we can't use Set() here
  // time complexity:
  // O(nlgn) + O(nlgn) + O(n) = O(2nlgn + n) = O(nlgn)
  // faster than double for-loop O(n^2)
  const sortedCartItemModis = [...cartItemModis].sort(modifierSortCompareFn);
  const sortedFavouriteModis = [...favouriteModis].sort(modifierSortCompareFn);
  for (let i = 0; i < sortedCartItemModis.length; i++) {
    const isMatch = sortedCartItemModis[i].id === sortedFavouriteModis[i].id;
    if (!isMatch) {
      return false;
    }
  }

  return true;
};

/**
 * Checks if two arrays of modifiers are the same.
 * @param cartItemRemoveModi
 * @param favouriteItemRemoveModi
 * @returns
 */
export const compareModifiers = (
  cartItemRemoveModi?: Modifier[],
  favouriteItemRemoveModi?: Modifier[]
): boolean => {
  const result = compareModis(
    cartItemRemoveModi || [],
    favouriteItemRemoveModi || []
  );

  return result;
};
type CompareModifierPredicate = () => boolean;

/**
 * Checks if all existing modifiers in cart item and in favourite item are equal.
 * @param cartItem
 * @param favouriteCartItem
 * @returns true if modifiers are equal
 */
export const compareAllModifiers = (
  cartItem: CartItem,
  favouriteCartItem: CartItem
) => {
  const removeModifierCheck: CompareModifierPredicate = () => {
    const result = compareModifiers(
      cartItem.removeModifier,
      favouriteCartItem.removeModifier
    );
    return result;
  };

  const addModifierCheck: CompareModifierPredicate = () => {
    const result = compareModifiers(
      cartItem.addModifier,
      favouriteCartItem.addModifier
    );
    return result;
  };

  const extraModifierCheck: CompareModifierPredicate = () => {
    const result = compareModifiers(
      cartItem.extraModifier,
      favouriteCartItem.extraModifier
    );
    return result;
  };

  const addFillingCheck: CompareModifierPredicate = () => {
    const result = compareModifiers(
      cartItem.addFilling,
      favouriteCartItem.addFilling
    );
    return result;
  };

  // returns array with failed checks
  const compareModifierPredicates: CompareModifierPredicate[] = [
    removeModifierCheck,
    addModifierCheck,
    extraModifierCheck,
    addFillingCheck,
  ];

  const failedPredicate = compareModifierPredicates.find((predicate) => {
    const result = predicate();
    return !result;
  });

  const hasFailedPredicate = !failedPredicate;
  return hasFailedPredicate;
};
