export enum OrderStatusTestingId {
  orderSummary = "ORDER_SUMMARY",
  orderMap = "ORDER_MAP",
  orderReceipt = "ORDER_RECEIPT",
  orderValue = "ORDER_VALUE",
  totalValue = "TOTAL_VALUE",
  subTotal = "SUB_TOTAL",
  orderTax = "ORDER_TAX",
}

export const VisibleCustomisations = 3;

export const DEFAULT_TIMEZONE = "Australia/Sydney";

export const DELAYED_ORDER_SECONDS = 900000;
