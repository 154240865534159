import { Linking, Platform } from "react-native";

import { AnalyticsInstance } from "../../analytics";
import * as AnalyticsConst from "../../modules/Analytics/const";

/**
 * Triggers opening map app for ios/android.
 * @param lat latitude
 * @param long longtitude
 * @param addressLabel store name
 */
export const openMaps = (
  lat: number,
  long: number,
  storeId: number,
  storeName: string
): Promise<boolean> => {
  const googleMapsUrl = `geo:0,0?q=${lat},${long}(${storeName})`;
  const appleMapsUrl = `maps:0,0?q=${storeName}@${lat},${long}`;
  const browserUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${long}&center=${lat},${long}`;

  AnalyticsInstance.trackEvent(AnalyticsConst.Events.GetDirections, {
    store_id: storeId,
    store_name: storeName,
  });

  switch (Platform.OS) {
    case "ios":
      return Linking.canOpenURL(`comgooglemaps://?center=${lat},${long}`).then(
        (canOpen) => {
          if (canOpen) {
            return Linking.openURL(googleMapsUrl).catch(() => {
              return Linking.openURL(browserUrl);
            });
          } else {
            return Linking.openURL(appleMapsUrl).catch(() => {
              return Linking.openURL(browserUrl);
            });
          }
        }
      );
    case "android":
      return Linking.openURL(googleMapsUrl).catch(() => {
        return Linking.openURL(browserUrl);
      });
    case "web": {
      const fullUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${long}`;
      window.open(fullUrl, "_blank");
      break;
    }
    default:
      return Linking.openURL("").catch(() => {
        return Linking.openURL(browserUrl);
      });
  }
};
