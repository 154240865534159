import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AllActionsOf } from "../utils/Actions";
import { MenuListItemProps } from "../menu/models";
import { UpsellState } from "./model";
import { UpsellType } from "../../services/api/upsell/model";
import { UpsellSection } from "modules/Upsell/model";

const initialState: UpsellState = {
  isUserShownUpsell: false,
  upsellItems: [],
  isLoading: false,
  upsellItemsFromConfigPreCart: [],
  upsellItemsFromConfigInCart: [],
};

const upsellSlice = createSlice({
  name: "upsell",
  initialState: initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getUpsellFromConfig(state, action: PayloadAction<UpsellType>) {
      state.isLoading = true;
    },
    setUpsellItemsInCardFromConfigSuccess(
      state,
      action: PayloadAction<UpsellSection[]>
    ) {
      state.isLoading = false;
      state.upsellItemsFromConfigInCart = action.payload;
    },
    setUpsellItemsPreCardFromConfigSuccess(
      state,
      action: PayloadAction<UpsellSection[]>
    ) {
      state.isLoading = false;
      state.upsellItemsFromConfigPreCart = action.payload;
    },
    getUpsellFromConfigFailed(state) {
      state.isLoading = false;
    },
    setUpsellItems(state, action: PayloadAction<MenuListItemProps[]>) {
      state.upsellItems = action.payload;
    },
    setUserShownUpsell(state, action: PayloadAction<boolean>) {
      state.isUserShownUpsell = action.payload;
    },
  },
});

const { actions, reducer } = upsellSlice;
export type UpsellActionType = AllActionsOf<typeof actions>;
export { actions as upsellActions, reducer as upsellReducer, initialState };
