import { Store as ReduxStore } from "redux";

import { favouriteActions } from "../../redux_store/favourite/favourite.slice";
import {
  FavouriteCartItem,
  GetFavouriteResponse,
} from "../../redux_store/favourite/models";
import { MenuStructure } from "../../redux_store/menu/models";
import { getFavouriteProducts } from "./getFavouriteProducts";

export const updateFavouriteToState = (
  store: ReduxStore,
  menuStructure: MenuStructure,
  isAuthSuccess: boolean,
  userConfirmedOrderSetup: boolean,
  favourites: GetFavouriteResponse[]
): void => {
  if (
    menuStructure &&
    favourites.length > 0 &&
    isAuthSuccess &&
    userConfirmedOrderSetup
  ) {
    const favouriteCartItemsToRedux: FavouriteCartItem[] = [];
    const favouriteProducts: FavouriteCartItem[] | undefined =
      getFavouriteProducts(menuStructure, favourites);

    favouriteProducts?.forEach((favouriteCartItem) => {
      favouriteCartItemsToRedux.push(favouriteCartItem);
    });

    store.dispatch(
      favouriteActions.setFavouriteCartItems(favouriteCartItemsToRedux)
    );
  }

  if (favourites.length === 0) {
    store.dispatch(favouriteActions.removeFavouriteCartItems());
  }
};
