import { Store } from "../../redux_store/store/models";
import { locales } from "../../locales/locales.enum";

export interface Coordinations {
  longitude: number;
  latitude: number;
}
export const toRadians = (degrees: number): number => {
  const pi = Math.PI;
  return degrees * (pi / 180);
};

export const getDistance = (
  source: Coordinations,
  target: Coordinations
): number => {
  const R = 6371e3; // metres
  const φ1 = toRadians(source.latitude);
  const φ2 = toRadians(target.latitude);
  const Δφ = toRadians(target.latitude - source.latitude);
  const Δλ = toRadians(target.longitude - source.longitude);

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; //in metres
  return d;
};
export const getMiles = (metres: number): number => {
  return metres * 0.000621371192;
};
export const getLocaleDistance = (
  metres: number,
  VERSION: locales,
  shortVersion: boolean
): string => {
  let distanceTextShort = "";
  if (VERSION === locales.AU) {
    distanceTextShort =
      metres >= 1000
        ? `${(metres / 1000).toFixed(1)} km`
        : `${metres.toFixed(1)} m`;
  } else {
    distanceTextShort = `${getMiles(metres).toFixed(1)} mi`;
  }
  if (shortVersion) {
    return distanceTextShort;
  } else {
    return distanceTextShort + " away";
  }
};
/**
 * Sort stores by distance(metres) and add distance, distanceText in each object
 * @param props stores, currentLocation
 * @returns list of sorted stores
 */
export const sortByDistance = (
  stores: Store[],
  currentLocation: Coordinations,
  VERSION: locales
): Store[] => {
  try {
    const result = stores.map((store) => {
      const distance = getDistance(currentLocation, {
        longitude: parseFloat(store.longitude),
        latitude: parseFloat(store.latitude),
      });
      return {
        ...store,
        distance: distance,
        distanceText: getLocaleDistance(distance, VERSION, false),
        distanceTextShort: getLocaleDistance(distance, VERSION, true),
      };
    });
    return result.sort((a: Store, b: Store) => {
      return a.distance - b.distance;
    });
  } catch (e) {
    return stores;
  }
};
