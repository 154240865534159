import { FavouriteCartItem } from "../../redux_store/favourite/models";

export const checkAvailableFavourite = (
  menuItems: number[],
  favouriteCartItems: FavouriteCartItem[]
): boolean => {
  for (const favouriteCartItem of favouriteCartItems) {
    if (favouriteCartItem) {
      return menuItems.includes(favouriteCartItem.cartItem.productId);
    }
  }
  return false;
};
