import config from "../../config";
import translationAUError from "../../locales/Error/au.json";
import translationUSError from "../../locales/Error/us.json";
import { locales } from "../../locales/locales.enum";
import { ErrorResponse } from "./models";

interface FallBackTranslationProp {
  au: string;
  us: string;
}

//TODO: update e model to follow axios model
export const buildErrorResponse = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e: any,
  fallbackHeading?: FallBackTranslationProp
): ErrorResponse => {
  return {
    ...e.response?.data,
    statusCode: e.response?.status ?? e.code,
    heading:
      e.response?.data?.heading ?? config.version === locales.AU
        ? fallbackHeading?.au ?? translationAUError.defaultErrorHeading
        : fallbackHeading?.us ?? translationUSError.defaultErrorHeading,
  };
};
