import { StoresTimeZone } from "../../services/api/store/model";

export interface GetMenuStructureProps {
  channelId: number;
  storeId: number;
}

export interface MenuStructure {
  store: Store;
  sections: MenuSection[];
  groupNames?: GroupName[];
  commonSections: CommonSection[];
  posMenuId: number;
  categoryLookup: CategoryLookup;
}

export interface CategoryLookup {
  [key: number]: string;
}

export interface Store {
  storeName: string;
  address: Address;
  timeZoneInfo: StoresTimeZone;
}

export interface Address {
  suburb: null;
  state: string;
  latitude: number;
  longitude: number;
}

export interface GroupName {
  id: number;
  name: string;
  type: string;
  imageUrl?: string;
}
export interface CommonSection {
  id: number;
  title: string;
  displayOrder: number;
  categories: Category[];
}

export interface MultiPart {
  id: number;
  name: string;
  price?: number;
  posPlu?: number;
  displayOrder?: number;
  imageTopDownUrl?: string;
  imageAngleUrl?: string;
  description?: string;
  multiPartSection?: MultipartSection[];
  tags: string[];
  templateId?: string;
  tagLookup?: TagLookup[];
  menuFlowId?: number;
  products?: Product[];
  multiPart?: MultiPart[];
}

export interface MultipartSection {
  name: string;
  categories: Category[];
  displayOrder?: number;
  price: number;
  products: Product[];
  description?: string;
  imageTopDownUrl?: string;
  imageAngleUrl?: string;
  tagLookup?: TagLookup[];
  modifierLookup?: ModifierLookup[];
  modifierOverrideLookup?: ModifierOverrideLookup[];
}

export interface Product {
  id: number;
  name: string;
  posPlu?: number;
  description: string;
  price: number;
  nutritionalInfo?: string;
  imageTopDownUrl?: string;
  imageAngleUrl?: string;
  displayOrder: number;
  extraModifier?: number[];
  addModifier?: number[];
  defaultModifier?: number[];
  removeModifier?: number[];
  tags?: string[];
  extraFilling?: number[];
  defaultFilling?: number[];
  addFilling?: number[];
  tagLookup?: TagLookup[];
  modifierGroups?: ModifierGroups;
  combinedModifierGroups?: CombinedModifierGroups;
  requiredList?: string[];
}

export enum TagType {
  CoffeeModifierGroup = "CoffeeModifierGroup",
  CombinedModifierGroup = "CombinedModifierGroup",
  CombinedModifierQuantity = "CombinedModifierQuantity",
  CombinedModifierType = "CombinedModifierType",
  Filling = "Filling",
  ProductType = "ProductType",
  Size = "Size",
  SpiceLevel = "Spice Level",
  TacoType = "TacoType",
  ProductQuantity = "Product Quantity",
  TemplateID = "TemplateId",
  ControlType = "ModifierControlType",
  CategoryType = "CategoryType",
  FeaturedType = "FeaturedType",
  FeaturedImageApp = "FeaturedImageApp",
  FeaturedImageWeb = "FeaturedImageWeb",
  ChurrosFillings = "Churros Fillings",
  MaxSelection = "MaxSelection",
}

export enum InternalTagType {
  MIAM = "MIAM",
}

export interface MenuSection {
  displayOrder: number;
  id: number;
  title: string;
  tradingHours: TradingHours[];
  subSections: MenuSubsection[];
}

export interface MenuSubsection {
  id: number;
  title: string;
  displayOrder: number;
  categories?: Category[];
  products?: Product[];
  multiParts?: MultiPart[];
  showNutritionalInfoDisclaimer?: boolean; //FIXME: Missing in current API
}
export interface ModifierLookup {
  id: number;
  name: string;
  price: number;
  imageTopDownUrl?: string;
  imageAngleUrl?: string;
  nutritionalInfo?: string;
  tagId?: string[];
  tags?: string[];
  displayOrder?: number;
  description?: string;
}
export interface ModifierOverrideLookup extends ModifierLookup {
  productId: number;
  productPosPlu: number;
}
export interface TagLookup {
  tagId: string;
  type: TagType | null;
  value: null | string;
  tagDisplayOrder: number | null;
  attributes: Attributes | null;
}
export interface Selection {
  max: string;
  min: string;
}
export interface Attributes {
  selection: Selection;
}
export interface Category {
  id: number;
  name: string;
  price?: number;
  products?: Product[];
  description?: string;
  commonSection?: number[];
  categoryTags?: CategoryTags[];
  categoryTagMapper?: CategoryTagMapper;
  imageTopDownUrl?: string;
  imageAngleUrl?: string;
  tags: string[];
  tagLookup: TagLookup[];
  modifierLookup: ModifierLookup[];
  modifierOverrideLookup: ModifierOverrideLookup[];
  templateId?: string;
  displayOrder: number;
  multiPart?: MultiPart[];
  categoryType?: string;
  posPlu?: number;
}
export interface ModifierGroups {
  [key: string]: { modifierLookups: ModifierLookup[]; tagLookup: TagLookup };
}
export interface CombinedModifierGroups {
  [key: string]: CombinedData;
}

export interface CombinedData {
  modifierLookups: ModifierLookup[];
  combinedModifierTypes: TagLookup[];
  combinedModifierQuantity: TagLookup[];
}
export interface CategoryTags {
  type: string;
  data: CategoryTagData[];
}
export interface CategoryTagData {
  value: string;
  price: number;
  nutritionalInfo?: string;
  imageTopDownUrl?: string;
  imageAngleUrl?: string;
  displayOrder?: number;
}
export interface CategoryTagMapper {
  [key: string]: number;
}

export interface TradingHours {
  timePeriods: TimePeriod[];
  enabled: boolean;
  dayOfWeek: string;
}

export interface TimePeriod {
  openTime: string;
  endTime: string;
}

export interface GetMenuStructureSuccess {
  menuStructure: MenuStructure;
  menuSectionEnabled: number[];
  activeTab: string;
  posMenuId: number;
}

export interface MenuSectionEnabledResult {
  sectionIndex: number[];
  sectionTitle: string;
  sectionTradingHoursForToday: TradingHours;
}

export enum MenuTestingId {
  singleProductModalOverlay = "SINGLE_PRODUCT_MODAL_OVERLAY",
}

export interface MenuListData {
  title: string;
  data: MenuListItemProps[];
  id: number;
}

export interface CombinedCategory {
  data: Category[];
  title: string;
  displayOrder?: number;
}

export interface MenuListItemProps {
  category?: Category;
  multiPart?: MultiPart;
  product?: Product;
  favourite?: Product;
  combinedCategory?: CombinedCategory;
  displayOrder?: number;
}

export interface OpenTimePeriod {
  openTime: string;
  day: string;
}

export interface CloseTimePeriod {
  endTime: string;
  day: string;
}

export interface OpeningClosingTimeSortByDay {
  openingTime: OpenTimePeriod[];
  closingTime: CloseTimePeriod[];
}

export type FeaturedItemType = Product & Category & MultiPart;
