import React from "react";
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { PlatformUtils, StoreModules } from "gyg_common";

import colours from "../styles/colours";
import { Typography } from "../styles/typography";

const styles = (props: BasicDetailsBlackButtonProps) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      borderRadius: 8,
      backgroundColor: props.disabled ? colours.lightGrey : colours.black,
      paddingVertical: 12,
    },
    buttonContainer: {
      flex: 1,
      flexDirection: "row",
      paddingRight: 16,
      paddingLeft: 16,
      justifyContent: "center",
      alignItems: "center",
    },
    centerText: {
      ...Typography.titleThree,
      flex: 1,
      justifyContent: "center",
      color: props.disabled ? colours.uiGrey : colours.yellow,
      textAlign: "center",
    },
  });

export interface BasicDetailsBlackButtonProps {
  title: string;
  disabled?: boolean;
  onClick: () => void;
}

export default function BasicDetailsBlackButton(
  props: BasicDetailsBlackButtonProps
) {
  const { title, disabled, onClick } = props;

  return (
    <TouchableOpacity
      {...PlatformUtils.generateTestID(
        Platform.OS,
        StoreModules.StoreModels.StoresTestingId.basicDetailsBlackButton
      )}
      disabled={disabled}
      activeOpacity={0.8}
      onPress={onClick}
      style={styles(props).container}>
      <View style={styles(props).buttonContainer}>
        <Text numberOfLines={1} style={styles(props).centerText}>
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  );
}
