import React from "react";
import {
  Image,
  ImageSourcePropType,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";

import * as PlatformUtils from "../../modules/platformUtils";
import Badge from "../Badge";
import colours from "../styles/colours";
import { BorderRadius, Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

export enum NavigationButtonIconSize {
  BIG = "big",
  SMALL = "small",
}

export interface NavigationButtonProps {
  title: string;
  subtitle?: string;
  onClick: () => void;
  disabled?: boolean;
  rightIcon?: ImageSourcePropType;
  leftIcon?: ImageSourcePropType;
  iconSize?: NavigationButtonIconSize;
  testId?: string;
  countOfNotifications?: number | undefined;
}

interface StyleProps extends NavigationButtonProps {
  hovered?: boolean;
  pressed?: boolean;
}

const style = (props: StyleProps) =>
  StyleSheet.create({
    wrapper: {
      borderRadius: BorderRadius.Medium,
      opacity: props.disabled ? 0.5 : 1,
      backgroundColor: props.pressed
        ? colours.lightestGrey
        : props.hovered
        ? colours.starburstOpacity
        : colours.white,
    },
    container: {
      paddingTop: props.subtitle
        ? Spacing.Light
        : props.iconSize === NavigationButtonIconSize.BIG
        ? Spacing.Thin
        : Spacing.Light,
      paddingBottom: props.subtitle
        ? Spacing.Light
        : props.iconSize === NavigationButtonIconSize.BIG
        ? Spacing.Thin
        : Spacing.Light,
      paddingLeft: Spacing.Light,
      paddingRight: Spacing.Light,
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      alignSelf: Platform.OS === "web" ? "auto" : "flex-start",
      borderRadius: BorderRadius.Medium,
      justifyContent: "center",
    },
    titleView: {
      flexDirection: "column",
      alignContent: "center",
      justifyContent: "center",
      flex: 1,
    },
    title: {
      ...Typography.titleThree,
      textTransform: "uppercase",
      textAlign: "left",
      justifyContent: "center",
      marginTop: Spacing.ExtraThin,
    },
    subtitle: {
      ...Typography.body,
      textAlign: "left",
      justifyContent: "center",
    },
    leftIcon: {
      width: props.iconSize === NavigationButtonIconSize.BIG ? 32 : 24,
      height: props.iconSize === NavigationButtonIconSize.BIG ? 32 : 24,
      marginRight: Spacing.ExtraThin,
    },
    rightIcon: {
      width: props.iconSize === NavigationButtonIconSize.BIG ? 32 : 24,
      height: props.iconSize === NavigationButtonIconSize.BIG ? 32 : 24,
      marginLeft: Spacing.ExtraThin,
    },
  });

const NavigationButton: React.FC<NavigationButtonProps> = (props) => {
  const {
    disabled,
    title,
    onClick,
    rightIcon,
    leftIcon,
    testId,
    subtitle,
    countOfNotifications,
  } = props;
  return (
    <Pressable
      {...PlatformUtils.generateTestID(
        Platform.OS,
        testId ? testId : "navigation-button"
      )}
      disabled={disabled}
      onPress={disabled ? undefined : onClick}
      style={({ hovered, pressed }) =>
        style({ ...props, hovered, pressed }).wrapper
      }>
      <View style={style(props).container}>
        {leftIcon && <Image source={leftIcon} style={style(props).leftIcon} />}
        <View style={style(props).titleView}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={style(props).title}>{title}</Text>
            {!!countOfNotifications && (
              <Badge label={`${countOfNotifications}`} />
            )}
          </View>
          {subtitle && <Text style={style(props).subtitle}>{subtitle}</Text>}
        </View>
        {rightIcon && (
          <Image source={rightIcon} style={style(props).rightIcon} />
        )}
      </View>
    </Pressable>
  );
};

export default NavigationButton;
