import { all, AllEffect, ForkEffect } from "redux-saga/effects";

import loginSaga from "./auth/saga";
import cartSaga from "./cart/saga";
import checkoutSaga from "./checkout/saga";
import dashboardSaga from "./dashboard/saga";
import favouriteSaga from "./favourite/saga";
import loyaltySaga from "./loyalty/saga";
import menuSaga from "./menu/saga";
import notificationsCenterSaga from "./notificationsCenter/saga";
import orderSaga from "./order/saga";
import promoSaga from "./promo/saga";
import registrationSaga from "./registration/saga";
import socialSaga from "./social/saga";
import storeSaga from "./store/saga";
import upsellSaga from "./upsell/saga";
import userSaga from "./user/saga";

export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<void>>,
  void,
  unknown
> {
  try {
    yield all([
      ...storeSaga,
      ...promoSaga,
      ...orderSaga,
      ...menuSaga,
      ...checkoutSaga,
      ...registrationSaga,
      ...loginSaga,
      ...userSaga,
      ...favouriteSaga,
      ...cartSaga,
      ...loyaltySaga,
      ...socialSaga,
      ...dashboardSaga,
      ...upsellSaga,
      ...notificationsCenterSaga,
    ]);
  } catch (err) {
    console.log("error caught in rootsaga", err);
  }
}
