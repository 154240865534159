import React, { ReactNode, useMemo } from "react";
import {
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { Shadow } from "react-native-shadow-2";

import { generateTestID } from "../../../modules/platformUtils";
import colours from "../../styles/colours";
import { Spacing } from "../../styles/number";

const radioDotSize = 20;
const radioWrapperSize = 32;

export interface RadioItemProps {
  id: number | string;
  selectedOption: (id: number | string) => void;
  disabled?: boolean;
  selected?: boolean;
  children?: ReactNode;
}

const defaultStyle = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    flex: 1,
  },
  shadowContainer: {
    margin: Spacing.Light,
  },
  radioWrapper: {
    width: radioWrapperSize,
    height: radioWrapperSize,
    borderRadius: radioWrapperSize / 2,
    justifyContent: "center",
    alignItems: "center",
    shadowRadius: 8,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    elevation: 4,
    shadowOpacity: 0.1,
    shadowColor: colours.darkGrey,
    backgroundColor: colours.white,
  },
  radioDot: {
    width: radioDotSize,
    height: radioDotSize,
    borderRadius: radioDotSize / 2,
    backgroundColor: colours.white,
  },
});

const disabledStyle = StyleSheet.create({
  radioWrapper: {
    ...defaultStyle.radioWrapper,
    shadowColor: "transparent",
    backgroundColor: colours.lightGrey,
  },
  radioDot: {
    ...defaultStyle.radioDot,
    backgroundColor: colours.lightGrey,
  },
});

const selectedStyle = StyleSheet.create({
  radioDot: {
    ...defaultStyle.radioDot,
    backgroundColor: colours.darkGrey,
  },
});

/**
 * Radio item that is displayed in `RadioButtonGroup`.
 * @param props
 * @returns
 */
const RadioItem: React.FC<RadioItemProps> = ({
  selectedOption,
  disabled = false,
  selected = false,
  id,
  children,
}) => {
  const onToggleSelected = () => {
    selectedOption(id);
  };

  const radioDotStyle = useMemo(() => {
    if (disabled) {
      return disabledStyle.radioDot;
    } else if (selected) {
      return selectedStyle.radioDot;
    }
    return defaultStyle.radioDot;
  }, [disabled, selected]);

  return (
    <TouchableWithoutFeedback
      {...generateTestID(Platform.OS, `${id}RadioItem`)}
      disabled={disabled}
      onPress={onToggleSelected}>
      <View style={defaultStyle.wrapper}>
        <View style={defaultStyle.label}>{children}</View>
        <Shadow
          distance={4}
          startColor={colours.shadow}
          offset={[1, 2]}
          containerStyle={defaultStyle.shadowContainer}>
          <View
            style={
              disabled ? disabledStyle.radioWrapper : defaultStyle.radioWrapper
            }>
            <View style={radioDotStyle} />
          </View>
        </Shadow>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default RadioItem;
