import { Dimensions, Platform, StyleSheet } from "react-native";

import colours from "./colours";
import { Spacing } from "./number";
import { Typography } from "./typography";

const width = Dimensions.get("screen").width;
const ratio = 0.555;

export const CAROUSEL_SIZES = {
  STANDARD: 220,
  LARGE: 280,
  ADAPTIVE: ratio * width,
  ADAPTIVE_LARGE: ratio * width + 60,
};

const tableParts = StyleSheet.create({
  row: {
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
  },
});

export const lineStyle = StyleSheet.create({
  dashed: {
    borderTopWidth: 1,
    borderColor: colours.lightGrey,
    borderRadius: 1,
  },
  gradientWrapper: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
  },
  gradient: {
    flex: 1,
    ...Platform.select({
      web: {
        height: 1,
      },
      default: {
        height: 0.5,
      },
    }),
  },
});

export const appTableStyle = StyleSheet.create({
  header: {
    marginTop: Spacing.Thin,
    ...tableParts.row,
    marginBottom: Spacing.Light,
  },
  headerCol: {
    ...Typography.captionOne,
  },
  bodyRow: {
    ...tableParts.row,
    marginVertical: Spacing.ExtraThin,
  },
  bodyCol: {
    ...Typography.titleThree,
  },
  bodyColSmall: {
    ...Typography.titleThree,
    fontSize: 16,
  },
  colProduct: {
    width: "50%",
  },
  colQty: {
    flex: 1,
    alignItems: "flex-end",
  },
  colPrice: {
    width: "28%",
    alignItems: "flex-end",
  },
});

export const webTableStyle = StyleSheet.create({
  header: {
    marginTop: Spacing.Thin,
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    marginBottom: Spacing.Light,
  },
  headerCol: {
    ...Typography.captionOne,
  },
  bodyRow: {
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    marginVertical: Spacing.ExtraThin,
  },
  bodyCol: {
    ...Typography.titleThree,
  },
  bodyColSmall: {
    ...Typography.titleThree,
    fontSize: 16,
  },
  colProduct: {
    width: "50%",
  },
  colQty: {
    flex: 1,
    alignItems: "flex-end",
  },
  colPrice: {
    width: "28%",
    alignItems: "flex-end",
  },
});

const androidTextShadow = (size: number, colour: string = colours.white) =>
  Platform.OS === "android"
    ? {
        textShadowColor: colour,
        textShadowRadius: 1,
        textShadowOffset: {
          width: size,
          height: size,
        },
        elevation: size,
      }
    : {};

const shadow = (size: number, colour: string = colours.white) =>
  Platform.OS === "web"
    ? {
        textShadowOffset: { width: size, height: size },
        textShadowColor: colour,
        textShadowRadius: 0,
      }
    : {
        shadowOpacity: 1,
        shadowRadius: 0,
        shadowColor: colour,
        shadowOffset: {
          width: size,
          height: size,
        },
        ...androidTextShadow(size, colour),
      };

export const shadowTextstyle = (size: number, colour?: string) =>
  StyleSheet.create({
    shadow: shadow(size, colour),
  });

export const buttonShadowStyle = StyleSheet.create({
  shadow: {
    shadowOffset: {
      width: 4,
      height: 2,
    },
    shadowColor: colours.black,
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 12,
  },
});

export const errorStyle = StyleSheet.create({
  caption: {
    ...Typography.captionOne,
    color: colours.red,
  },
});
