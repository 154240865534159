import { PayloadAction } from "@reduxjs/toolkit";
import { call, fork, put, takeLatest } from "redux-saga/effects";

import { CalculateMenuSectionEnabledByOrderTime } from "../../modules/Menu/calculateMenuSectionByOrderTime";
import { mergeMenuSection } from "../../modules/Menu/utils";
import { StoreUtils } from "../../modules/Store";
import { getMenu } from "../../services/api/menu";
import { AnalyticsAction } from "../analytics/analytics.slice";
import { buildErrorResponse } from "../error/utils";
import { handleFavouriteUpdateToState } from "../favourite/saga";
import { MenuActions } from "./menu.slice";
import {
  GetMenuStructureProps,
  GetMenuStructureSuccess,
  MenuStructure,
} from "./models";

function* handleGetMenu(
  action: PayloadAction<GetMenuStructureProps>
): Generator<unknown, void, MenuStructure> {
  try {
    const menuStructure: MenuStructure = yield call(
      getMenu,
      action.payload.storeId,
      action.payload.channelId
    );
    const menuSectionEnabled = CalculateMenuSectionEnabledByOrderTime(
      menuStructure.sections,
      new Date(),
      menuStructure.store.timeZoneInfo.storeTimeZone
    );

    let activeTab = "";
    let validMenuSection = null;

    if (menuSectionEnabled?.length > 0) {
      activeTab = `${
        menuStructure.sections[menuSectionEnabled[0]].subSections[0].title
      }-${menuStructure.sections[menuSectionEnabled[0]].subSections[0].id}`;

      validMenuSection = mergeMenuSection(
        menuSectionEnabled,
        menuStructure.sections
      );

      if (!!validMenuSection) {
        yield put(MenuActions.setValidMenuSection(validMenuSection));
      }
    }

    const menuStructureSuccessModel: GetMenuStructureSuccess = {
      menuStructure,
      menuSectionEnabled: menuSectionEnabled,
      activeTab,
      posMenuId: menuStructure.posMenuId,
    };
    yield put(MenuActions.getMenuStructureSuccess(menuStructureSuccessModel));

    yield call(handleFavouriteUpdateToState, menuStructure);

    const menuOpenTime = StoreUtils.getMenuTimePeriodsForDay(
      menuStructure.sections,
      menuStructure.store.timeZoneInfo.storeTimeZone
    );
    yield put(MenuActions.setMenuOpenTime(menuOpenTime));

    yield put(AnalyticsAction.setMenuSection(validMenuSection));
  } catch (e) {
    console.error(e);
    yield put(MenuActions.error(buildErrorResponse(e)));
  }
}

export function* watchGetMenu(): Generator<unknown, void, MenuStructure> {
  yield takeLatest(MenuActions.getMenuStructure.type, handleGetMenu);
}

const saga = [fork(watchGetMenu)];

export default saga;
