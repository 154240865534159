import React from "react";
import { Platform, StyleSheet, Text, View } from "react-native";

import * as PlatformUtils from "../modules/platformUtils";
import colours from "./styles/colours";
import { BorderRadius, Spacing } from "./styles/number";
import { Typography } from "./styles/typography";

export interface OrderStatusLabelProps {
  bgColor: string;
  title: string;
  titleColor?: string;
}

const style = (bgColor: string, titleColor?: string) =>
  StyleSheet.create({
    container: {
      backgroundColor: bgColor,
      borderRadius: BorderRadius.Medium,
      paddingHorizontal: Spacing.Thin,
    },
    label: {
      ...Typography.badgeText,
      fontSize: 13,
      lineHeight: 20,
      color: titleColor ?? colours.darkGrey,
    },
  });

const OrderStatusLabel: React.FC<OrderStatusLabelProps> = (props) => {
  const { bgColor, title, titleColor } = props;

  return (
    <View
      style={style(bgColor).container}
      {...PlatformUtils.generateTestID(Platform.OS, "orderStatusLabel")}>
      <Text style={style(bgColor, titleColor).label}>
        {title ? title.toUpperCase() : ""}
      </Text>
    </View>
  );
};

export default OrderStatusLabel;
