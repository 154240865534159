import { StyleSheet } from "react-native";

import colours from "../styles/colours";
import { Typography } from "../styles/typography";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 8,
    marginBottom: 8,
  },
  detailsRow: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 8,
  },
  details: {
    padding: 16,
  },
  tagWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 8,
  },
  tag: {
    ...Typography.bodyBold,
    textTransform: "capitalize",
    lineHeight: 24,
  },
  storeInfo: {
    flex: 0.65,
    alignSelf: "flex-start",
    marginRight: 8,
  },
  btnWrapper: {
    flex: 0.35,
    alignSelf: "flex-end",
    maxWidth: 130,
  },
  distanceText: {
    ...Typography.bodyTwoBold,
    color: colours.midGrey,
    letterSpacing: 0.27,
    lineHeight: 16,
  },
  distancePaddings: {
    paddingBottom: 24,
  },
  basicInfo: {
    flex: 1,
  },
  halfFlex: {
    flex: 0.5,
  },
  basicInfoWithDelivery: {
    paddingRight: 24,
  },
});

export default styles;
