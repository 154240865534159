import { useCallback } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { Store as ReduxStore } from "redux";

import {
  BraintreeReduxActions,
  CheckoutAction,
  OrderModules,
  OrderReduxAction,
  RootState,
} from "../index";
import { ErrorResponse, HTTPStatusCode } from "../redux_store/error/models";
import { getStatusCode } from "../utils/order";
import useCreateUpdateOrder from "./useCreateUpdateOrder";

interface OrderErrorHandlerProps {
  store: ReduxStore;
  routeName: string;
  onCheckoutRetry: () => void;
  recreateOrderCallback?: () => void;
}

export const isUnexpectedError = (
  checkoutHasUnexpectedError: boolean,
  error?: ErrorResponse | null | undefined
) => {
  return (
    getStatusCode(error?.statusCode) >= HTTPStatusCode.UNEXPECTEDERROR ||
    checkoutHasUnexpectedError
  );
};

export const isBraintreeError = (error?: ErrorResponse | null | undefined) => {
  return error?.title === "BraintreeTransactionCreationFailedException";
};

const useHandleOrderError = ({
  store,
  routeName,
  onCheckoutRetry,
  recreateOrderCallback,
}: OrderErrorHandlerProps) => {
  const { createOrder } = useCreateUpdateOrder(store, routeName, true);
  const dispatch = useDispatch();
  const { error: checkoutError } = useSelector((s: RootState) => s.checkout);
  const { getOrderError } = useSelector((s: RootState) => s.order);
  const { isTimeout: checkoutTimeout, paymentPayload: savedPaymentPayload } =
    useSelector((state: RootState) => state.braintree);

  const recreateOrder = useCallback(() => {
    if (recreateOrderCallback) {
      recreateOrderCallback();
    }
    batch(() => {
      // clear order response and order ID so a new order can be created
      dispatch(OrderReduxAction.clearOrderResponse());
      //reset order status
      dispatch(OrderReduxAction.clearOrderStatus());
      // reset payment status
      dispatch(CheckoutAction.resetPayment());
      // reset the braintree payment payload
      dispatch(BraintreeReduxActions.clearPaymentPayload());
      dispatch(OrderReduxAction.updateCurrentOrderId(null));
    });

    // create a new order, this param triggers a new order creation
    createOrder();
  }, [createOrder, dispatch, recreateOrderCallback]);

  const clearOrderErrorStates = useCallback(() => {
    dispatch(OrderReduxAction.clearOrderError());
    dispatch(CheckoutAction.resetPayment());
    dispatch(BraintreeReduxActions.setTimeoutError(false));
  }, [dispatch]);

  const onCheckoutErrorModalAction = useCallback(() => {
    if (isBraintreeError(checkoutError)) {
      //recreates for Braintree soft/hard errors encountered
      clearOrderErrorStates();
      recreateOrder();
    } else if (
      (checkoutTimeout ||
        OrderModules.OrderUtils.isCheckoutInProgressError(getOrderError) ||
        OrderModules.OrderUtils.isCheckoutInProgressError(checkoutError)) &&
      savedPaymentPayload
    ) {
      //if there where timeout during checkout or checkout in progress error
      //and payment payload exist, retry /checkout with same payment payload to get order details
      clearOrderErrorStates();
      onCheckoutRetry();
    } else {
      //close checkout error modal and clear error states
      clearOrderErrorStates();
    }
  }, [
    checkoutError,
    checkoutTimeout,
    clearOrderErrorStates,
    getOrderError,
    onCheckoutRetry,
    recreateOrder,
    savedPaymentPayload,
  ]);

  return onCheckoutErrorModalAction;
};

export default useHandleOrderError;
