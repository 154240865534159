import moment, { Moment } from "moment-timezone";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text, View } from "react-native";

import { ComponentsModules, locales, PlatformUtils } from "gyg_common";
import ToggleSelect, {
  ActiveTabType,
} from "gyg_common/components/ToggleSelect";
import { Typography } from "styles/typography";

import {
  DropdownMenu,
  DropdownMenuItem,
} from "../shared/DropdownMenu/DropdownMenu";

import config from "@/../../packages/common/config";
import colours from "@/styles/colours";

const styles = StyleSheet.create({
  space: {
    marginBottom: 16,
  },
  orderType: {
    ...Typography.titleFour,
    paddingBottom: 8,
  },
  toggleButtonWrapper: {
    marginBottom: 16,
  },
  tableServiceWrapper: {
    marginTop: 17,
  },
  orderAsapSetting: {
    marginBottom: 20,
  },
  daySelectWrapper: {
    marginBottom: 20,
    zIndex: "auto",
  },
  timeSelectWrapper: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    zIndex: "auto",
  },
  timeDropdownWrapper: {
    flex: 1,
    paddingRight: 20,
    zIndex: "auto",
  },
  amPm: {
    flex: 1,
  },

  errorImageContainer: {
    flex: 1,
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  errorImage: {
    width: 150,
    height: 150,
  },
  errorMessage: {
    ...Typography.bodyBold,
    color: colours.red,
    marginTop: 8,
  },
});

export interface OrderSetupPickupProps {
  showScheduleForLater: boolean;
  timeZone: string;
  isStoreOpen: boolean;
  isStoreOpenThisWeek: boolean;
  asapTime: Date;
  orderAsapSetting: ActiveTabType;
  validOrderDays: ComponentsModules.DropdownPickerModels.PickerItemProps<Moment>[];
  validOrderHours: ComponentsModules.DropdownPickerModels.PickerItemProps<number>[];
  validOrderMinutes: ComponentsModules.DropdownPickerModels.PickerItemProps<number>[];
  orderDay: Moment;
  orderHour: number;
  orderMinutes: number;
  orderAmPm: ActiveTabType;
  setOrderDay: (day: Moment) => void;
  setOrderHour: (hour: number) => void;
  setOrderMinutes: (minute: number) => void;
  setOrderAmPm: (activeTab: ActiveTabType) => void;
  setOrderAsapSetting: (activeTab: ActiveTabType) => void;
  disableASAP?: boolean;
}

export const OrderSetupPickup: React.FC<OrderSetupPickupProps> = ({
  showScheduleForLater,
  timeZone,
  isStoreOpen,
  isStoreOpenThisWeek,
  asapTime,
  orderAsapSetting,
  validOrderDays,
  validOrderHours,
  validOrderMinutes,
  orderDay,
  orderHour,
  orderMinutes,
  orderAmPm,
  setOrderDay,
  setOrderHour,
  setOrderMinutes,
  setOrderAmPm,
  setOrderAsapSetting,
  disableASAP,
}) => {
  const { t } = useTranslation();

  const handleOrderAsap = (currentActive: ActiveTabType) => {
    if (currentActive === ActiveTabType.LEFT) {
      setOrderAsapSetting(ActiveTabType.RIGHT);
    } else if (
      isStoreOpen &&
      !disableASAP &&
      currentActive === ActiveTabType.RIGHT
    ) {
      setOrderAsapSetting(ActiveTabType.LEFT);
    }
  };
  const handleDaySelected = (selectedDayItem: DropdownMenuItem<Moment>) => {
    setOrderDay(selectedDayItem.value); // set the day for local UI state
  };
  const handleHourSelected = (selectedHourItem: DropdownMenuItem<number>) => {
    setOrderHour(selectedHourItem.value);
  };
  const handleMinuteSelected = (
    selectedMinuteItem: DropdownMenuItem<number>
  ) => {
    setOrderMinutes(selectedMinuteItem.value);
  };
  const handleAmPmSelected = (currentActive: ActiveTabType) => {
    const newAmPM =
      currentActive === ActiveTabType.LEFT
        ? ActiveTabType.RIGHT
        : ActiveTabType.LEFT;
    setOrderAmPm(newAmPM);
  };

  const leftTitle =
    isStoreOpen && !disableASAP
      ? `${t("OrderManagement:asapEstLabelTime")} ${moment(asapTime)
          .tz(timeZone)
          .format("hh:mma")}`
      : `${t("OrderManagement:asapLabelTime")} Unavailable`;

  return (
    <>
      <View style={styles.space}></View>
      <Text style={styles.orderType}>{t("OrderManagement:pickUpTime")}</Text>

      <View style={styles.orderAsapSetting}>
        <ToggleSelect
          onUpdateTab={handleOrderAsap}
          activeTab={orderAsapSetting}
          leftTitle={leftTitle}
          rightTitle={t("OrderManagement:selectATime")}
        />
      </View>

      {isStoreOpenThisWeek &&
        orderAsapSetting === ActiveTabType.RIGHT &&
        !showScheduleForLater && (
          <View>
            <Text style={styles.errorMessage}>
              {t("OrderManagement:restaurantClosedMessage")}
            </Text>
          </View>
        )}

      {isStoreOpenThisWeek &&
        orderAsapSetting === ActiveTabType.RIGHT &&
        showScheduleForLater && (
          <View {...PlatformUtils.generateTestID("web", "SchedulePickupView")}>
            <View style={styles.daySelectWrapper}>
              <DropdownMenu
                items={validOrderDays}
                selectedItem={{
                  label: orderDay.format(
                    config.version === locales.US
                      ? "dddd, MMMM DD"
                      : "dddd DD MMMM"
                  ),
                  value: orderDay,
                }}
                onItemSelected={handleDaySelected}
                dropdownDirection={"top"}
              />
            </View>
            <View style={styles.timeSelectWrapper}>
              <View style={styles.timeDropdownWrapper}>
                <DropdownMenu
                  items={validOrderHours}
                  selectedItem={
                    orderHour // There should be a case where AM is auto switched to PM if no valid orderHours come back
                      ? {
                          label: orderHour.toString(),
                          value: orderHour,
                        }
                      : undefined
                  }
                  onItemSelected={handleHourSelected}
                  dropdownDirection={"top"}
                />
              </View>
              <View style={styles.timeDropdownWrapper}>
                <DropdownMenu
                  items={validOrderMinutes}
                  selectedItem={
                    orderMinutes !== undefined
                      ? {
                          label:
                            orderMinutes < 10
                              ? `0${orderMinutes.toString()}`
                              : orderMinutes.toString(),
                          value: orderMinutes,
                        }
                      : undefined
                  }
                  onItemSelected={handleMinuteSelected}
                  dropdownDirection={"top"}
                />
              </View>
              <View style={styles.amPm}>
                <ToggleSelect
                  onUpdateTab={handleAmPmSelected}
                  activeTab={orderAmPm}
                  leftTitle={"AM"}
                  rightTitle={"PM"}
                />
              </View>
            </View>
          </View>
        )}
    </>
  );
};
