import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import config from "../../config";
import { locales } from "../../locales/locales.enum";

import CheckoutPaymentAU from "../../locales/CheckoutPayment/au.json";
import CheckoutPaymentUS from "../../locales/CheckoutPayment/us.json";
import LoginRegistrationAU from "../../locales/LoginRegistration/au.json";
import LoginRegistrationUS from "../../locales/LoginRegistration/us.json";
import OrderManagementAU from "../../locales/OrderManagement/au.json";
import OrderManagementUS from "../../locales/OrderManagement/us.json";
import OrderStatusAU from "../../locales/OrderStatus/au.json";
import OrderStatusUS from "../../locales/OrderStatus/us.json";
import StoreSearchAU from "../../locales/StoreSearch/au.json";
import StoreSearchUS from "../../locales/StoreSearch/us.json";
import UserManagementAU from "../../locales/UserManagement/au.json";
import UserManagementUS from "../../locales/UserManagement/us.json";
import FavouriteAU from "../../locales/Favourite/au.json";
import FavouriteUS from "../../locales/Favourite/us.json";
import DashboardAU from "../../locales/Dashboard/au.json";
import DashboardUS from "../../locales/Dashboard/us.json";
import LoyaltyAU from "../../locales/Loyalty/au.json";
import LoyaltyUS from "../../locales/Loyalty/us.json";
import BranchAU from "../../locales/Branch/au.json";
import BranchUS from "../../locales/Branch/us.json";
import RecentOrdersAU from "../../locales/RecentOrders/au.json";
import RecentOrdersUS from "../../locales/RecentOrders/us.json";
import NotificationsAU from "../../locales/Notifications/au.json";
import NotificationsUS from "../../locales/Notifications/us.json";
import ErrorAU from "../../locales/Error/au.json";
import ErrorUS from "../../locales/Error/us.json";

i18next.use(initReactI18next).init({
  compatibilityJSON: "v3",
  lng: config.version || locales.AU,
  debug: false,
  resources: {
    AU: {
      OrderManagement: OrderManagementAU,
      CheckoutPayment: CheckoutPaymentAU,
      OrderStatus: OrderStatusAU,
      StoreSearch: StoreSearchAU,
      LoginRegistration: LoginRegistrationAU,
      UserManagement: UserManagementAU,
      Favourite: FavouriteAU,
      Dashboard: DashboardAU,
      Loyalty: LoyaltyAU,
      Branch: BranchAU,
      RecentOrders: RecentOrdersAU,
      Notifications: NotificationsAU,
      Error: ErrorAU,
    },
    US: {
      OrderManagement: OrderManagementUS,
      CheckoutPayment: CheckoutPaymentUS,
      OrderStatus: OrderStatusUS,
      StoreSearch: StoreSearchUS,
      LoginRegistration: LoginRegistrationUS,
      UserManagement: UserManagementUS,
      Favourite: FavouriteUS,
      Dashboard: DashboardUS,
      Loyalty: LoyaltyUS,
      Branch: BranchUS,
      RecentOrders: RecentOrdersUS,
      Notifications: NotificationsUS,
      Error: ErrorUS,
    },
  },
  fallbackLng: locales.AU,
  interpolation: {
    escapeValue: false,
  },
  // https://github.com/i18next/react-i18next/issues/1559
  returnNull: false,
});

export default i18next;
