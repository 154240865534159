import { ImageSourcePropType } from "react-native";

import accessibleIcon from "../components/assets/icons/services/icon_accessible.png";
import alcoholIcon from "../components/assets/icons/services/icon_alcohol.png";
import breakfastIcon from "../components/assets/icons/services/icon_breakfast.png";
import cafeIcon from "../components/assets/icons/services/icon_cafe.png";
import driveThruIcon from "../components/assets/icons/services/icon_drive_thru.png";
import openIcon from "../components/assets/icons/services/icon_open.png";
import pickupIcon from "../components/assets/icons/services/icon_pickup.png";
import tableServiceIcon from "../components/assets/icons/services/icon_table.png";
import * as StoreModules from "../modules/Store/";

export interface ServiceIcon {
  id: StoreModules.StoreModels.StoreTagLabel;
  url: ImageSourcePropType;
}

export const definedServiceIcons: ServiceIcon[] = [
  {
    id: StoreModules.StoreModels.StoreTagLabel.BREAKFAST,
    url: breakfastIcon as ImageSourcePropType,
  },
  {
    id: StoreModules.StoreModels.StoreTagLabel.DRIVE_THRU,
    url: driveThruIcon as ImageSourcePropType,
  },
  {
    id: StoreModules.StoreModels.StoreTagLabel.LIQUOR,
    url: alcoholIcon as ImageSourcePropType,
  },
  {
    id: StoreModules.StoreModels.StoreTagLabel.OPEN_NOW,
    url: openIcon as ImageSourcePropType,
  },
  {
    id: StoreModules.StoreModels.StoreTagLabel.PICKUP,
    url: pickupIcon as ImageSourcePropType,
  },
  {
    id: StoreModules.StoreModels.StoreTagLabel.TABLE_SERVICE,
    url: tableServiceIcon as ImageSourcePropType,
  },
  {
    id: StoreModules.StoreModels.StoreTagLabel.WHEELCHAIR_ACCESSIBLE,
    url: accessibleIcon as ImageSourcePropType,
  },
  {
    id: StoreModules.StoreModels.StoreTagLabel.COFFEE,
    url: cafeIcon as ImageSourcePropType,
  },
];

/**
 * Returns the icon that matches tag.
 * @param tag tag label
 * @returns icon url
 */
export const getServiceIconUrl = (
  tag: StoreModules.StoreModels.StoreTagLabel
): ImageSourcePropType | undefined => {
  return definedServiceIcons.find((icon: ServiceIcon) => icon.id === tag)?.url;
};

export const getDistanceBetweenTwoPoints = (
  cord1: GeolocationCoordinates,
  cord2: GeolocationCoordinates
) => {
  if (cord1.latitude == cord2.latitude && cord1.longitude == cord2.longitude) {
    return 0;
  }
  const radlat1 = (Math.PI * cord1.latitude) / 180;
  const radlat2 = (Math.PI * cord2.latitude) / 180;

  const theta = cord1.longitude - cord2.longitude;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344; //convert miles to km

  return dist;
};
