import { ImageSourcePropType, Platform, StyleSheet } from "react-native";

import colours from "../../styles/colours";
import { BorderRadius, Spacing } from "../../styles/number";
import { Typography } from "../../styles/typography";

export interface ButtonProps {
  buttonName?: string;
  disable?: boolean;
  onClick: () => void;
  shadow?: boolean;
  icon?: ImageSourcePropType;
  rightIcon?: ImageSourcePropType;
  defaultImageType?: boolean;
  testId?: string;
  loading?: boolean;
  border?: boolean;
  iconWidth?: number;
  rightIconWidth?: number;
  fontSize?: number;
}

export interface StyleProps extends ButtonProps {
  hovered?: boolean;
  pressed?: boolean;
}

export const styles = (props: StyleProps) =>
  StyleSheet.create({
    bottomNodge: {
      flex: 0,
      backgroundColor: colours.white,
    },
    borderContainer: {
      width: "100%",
      borderWidth: props.border ? 4 : 0,
      borderColor:
        props.disable || !props.border ? colours.transparent : colours.white,
      backgroundColor:
        props.disable || !props.border ? colours.transparent : colours.white,
      shadowColor: colours.black,
      shadowOffset: {
        width: 0,
        height: -6,
      },
      shadowOpacity: props.shadow ? 0.1 : 0,
      elevation: props.shadow ? 10 : 0,
    },
    container: {
      minHeight: 48,
      flexDirection: "row",
      borderRadius: BorderRadius.Medium,
      backgroundColor: props.loading
        ? colours.starburst
        : props.disable
        ? colours.lightGrey
        : props.pressed
        ? colours.starburst
        : props.hovered
        ? colours.yellowOpacity
        : colours.yellow,
      paddingVertical:
        props.border || props.defaultImageType ? Spacing.Thin : Spacing.Light,
    },
    buttonContainer: {
      flex: 1,
      flexDirection: "row",
      paddingRight: Spacing.Light,
      paddingLeft: Spacing.Light,
      justifyContent: "center",
      alignItems: "center",
      gap: 5,
    },
    leftView: {
      flex: 0.5,
      justifyContent: "flex-start",
    },
    centerText: {
      ...Typography.titleThree,
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: "auto",
      justifyContent: "center",
      color: props.disable ? colours.uiGrey : colours.darkGrey,
      textAlign: "center",
      textTransform: "uppercase",
      fontSize: props.fontSize ?? Typography.titleThree.fontSize,
      paddingTop: Platform.OS == "ios" ? Spacing.ExtraThin : 0,
    },
    icon: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: "auto",
      alignContent: "center",
      justifyContent: "center",
    },
    rightIcon: {
      position: "absolute",
      right: Spacing.Thin,
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: "auto",
      alignContent: "center",
      justifyContent: "center",
    },
    rightIconStyle: {
      width: props.rightIconWidth ?? 80,
      height: 60,
    },
    iconStyle: {
      width: props.iconWidth ?? 80,
      height: 30,
    },
  });
