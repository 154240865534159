import React, { ReactNode } from "react";
import {
  Image,
  ImageSourcePropType,
  LayoutAnimation,
  LayoutAnimationConfig,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  TouchableWithoutFeedback,
  UIManager,
  View,
} from "react-native";

import { generateTestID } from "../modules/platformUtils";
import iconDownImage from "./assets/icons/icon_chevron_down_small.png";
import iconUpImage from "./assets/icons/icon_chevron_up_small.png";
import colours from "./styles/colours";
import { Spacing } from "./styles/number";
import { Typography } from "./styles/typography";

if (
  Platform.OS === "android" &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

export interface StylesProps {
  expand: boolean;
  subTitle?: string;
}

export interface AccordionProps {
  id: string;
  children: ReactNode;
  title: string;
  subTitle?: string;
  subTitleStyle?: TextStyle;
  expand: boolean;
  onExpandChanged: (key: string, expand: boolean) => void;
}
const IOS_ANIMATION: LayoutAnimationConfig = {
  duration: 500,
  create: { type: "linear", property: "opacity" },
  update: { type: "spring", springDamping: 0.95 },
  delete: { type: "linear", property: "opacity" },
};
const ANDROID_ANIMATION = LayoutAnimation.Presets.easeInEaseOut;

const containerStyles = (props: StylesProps) =>
  StyleSheet.create({
    container: {
      maxHeight: props.expand ? undefined : props.subTitle ? 96 : 74,
      overflow: "hidden",
      width: "100%",
      backgroundColor: colours.white,
    },
    top: {
      justifyContent: "center",
      borderBottomWidth: 1,
      borderColor: colours.lightGrey,
      paddingHorizontal: Spacing.Light,
      paddingVertical: Spacing.Regular,
      minHeight: props.subTitle ? 96 : 74,
      backgroundColor: colours.lightestGrey,
    },
  });

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  titleWrapper: {
    flexShrink: 1,
  },
  subTitle: {
    marginTop: Spacing.Thin,
  },
  split: {
    flex: 1,
  },
  icon: {
    width: 32,
    height: 32,
    resizeMode: "cover",
  },
});

export default function Accordion(props: AccordionProps): JSX.Element {
  const { expand, onExpandChanged, id, subTitle } = props;
  const handleSetExpand = () => {
    LayoutAnimation.configureNext(
      Platform.OS === "android" ? ANDROID_ANIMATION : IOS_ANIMATION
    );
    onExpandChanged(id, expand);
  };
  return (
    <View
      style={containerStyles({ expand: expand, subTitle: subTitle }).container}>
      <TouchableWithoutFeedback
        {...generateTestID(Platform.OS, props.title + " AccordionTitle")}
        onPress={() => {
          handleSetExpand();
        }}>
        <View
          style={containerStyles({ expand: expand, subTitle: subTitle }).top}>
          <View style={styles.row}>
            <View style={styles.titleWrapper}>
              <Text style={Typography.titleTwo}>{props.title}</Text>
              {props.subTitle && (
                <View style={styles.subTitle}>
                  <Text style={[Typography.bodyTwo, props.subTitleStyle]}>
                    {props.subTitle}
                  </Text>
                </View>
              )}
            </View>
            <View style={styles.split} />
            <Image
              style={styles.icon}
              source={
                (expand ? iconUpImage : iconDownImage) as ImageSourcePropType
              }
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
      {props.children}
    </View>
  );
}
