import React from "react";
import { Image, Platform, Pressable, StyleSheet, View } from "react-native";

import * as PlatformUtils from "../modules/platformUtils";
import { BorderRadius } from "./styles/number";
import { buttonShadowStyle } from "./styles/shared";

export interface IconButtonProps {
  onPress?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  testID?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  iconImage: any;
  withShadow?: boolean;
  isHidden?: boolean;
}

const shadowStyle = (props: boolean) =>
  StyleSheet.create({
    container: props
      ? {
          ...buttonShadowStyle.shadow,
        }
      : {},
  });

const styles = StyleSheet.create({
  container: {
    width: 48,
    backgroundColor: "#ffffff",
    borderRadius: BorderRadius.Medium,
    height: 48,
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: 32,
    height: 32,
  },
});

export const IconButton: React.FC<IconButtonProps> = ({
  withShadow = true,
  onPress,
  testID,
  iconImage,
  isHidden = false,
}) => {
  return (
    <Pressable
      {...PlatformUtils.generateTestID(Platform.OS, testID ?? "icon-button")}
      style={({ hovered, pressed }) => [
        {
          opacity: (hovered || pressed) && !isHidden ? 0.8 : isHidden ? 0 : 1,
        },
      ]}
      onPress={() => {
        if (onPress && !isHidden) {
          onPress();
        }
      }}>
      <View
        style={{ ...styles.container, ...shadowStyle(withShadow).container }}>
        <Image style={styles.icon} source={iconImage} />
      </View>
    </Pressable>
  );
};

export default IconButton;
