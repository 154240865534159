/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Requeires relative import otherwise the tests break
import { formatUserData } from "../../modules/User/utils";
import { AllActionsOf } from "../../redux_store/utils/Actions";
import {
  DeleteAccountVerifyPayload,
  PatchUserEmailPayload,
  PatchUserEmailResponse,
  PatchUserMobilePayload,
} from "../../services/api/user/model";
import { ErrorResponse } from "../error/models";
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  IApiError,
  IMessageToast,
  IPostTermsAndConditionsRequest,
  LocationObjectCoords,
  SetPasswordRequest,
  UserAccountRequest,
  UserProfile,
  UserState,
} from "./models";

export const initialState: UserState = {
  locationPermissionGranted: false,
  location: null,
  isLocationPrecise: false,
  isLoading: false,
  isDeleteUserLoading: false,
  dataDogSessionId: null,
  profile: null,
  isUpdateUserSuccess: false,
  userLoyalty: null,
  messageToast: null,
  updatedPhoneNumber: null,
  postTermsAndConditionsLoading: false,
  postTermsAndConditionsError: null,
  isMobileNumberUpdated: false,
  isEmailUpdated: false,
  isPasswordUpdated: false,
  isAccountDeleted: false,
  isTermsAndConditionsUpToDate: true,
  isAccountVerificationCodeSent: false,
  errorMessage: undefined,
  isBrazeInitialised: false,
  showMobileVerifyModal: false,
  existingUserMobileVerify: false,
  verifyEmailAddressLoading: false,
  isVerifyEmailAddressSuccess: false,
  verifyEmailAddressError: undefined,
  storeCard: true,
  isOffline: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setMessageToast(state, action: PayloadAction<IMessageToast | null>) {
      state.messageToast = action.payload;
    },

    setLocation(state, action: PayloadAction<LocationObjectCoords>) {
      state.location = action.payload;
    },

    setLocationPermission(state, action: PayloadAction<boolean>) {
      state.locationPermissionGranted = action.payload;
    },

    setPreciseLocationInfo(state, action: PayloadAction<boolean>) {
      state.isLocationPrecise = action.payload;
    },

    loading(state) {
      state.isLoading = true;
    },

    getUserProfile(state) {
      state.isLoading = true;
      state.errorMessage = undefined;
    },

    setUserProfile(state, action: PayloadAction<UserProfile>) {
      state.isLoading = false;
      if (!!action.payload) {
        state.profile = formatUserData(action.payload);
        state.showMobileVerifyModal = !state.profile.verifiedMobileNumber;
        // || !!!state.profile.phoneNumber; socialsignin doesn't contain phoneNumber field
      }
    },

    clearUserProfile(state) {
      state.profile = undefined;
    },

    setUserProfileError(state, action: PayloadAction<ErrorResponse>) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },

    updateUserProfile(state, action: PayloadAction<UserAccountRequest>) {
      state.isLoading = true;
    },

    updateUserProfileSuccess(state) {
      state.isLoading = false;
      state.errorMessage = undefined;
      state.isUpdateUserSuccess = true;
    },

    updateUserProfileError(
      state,
      action: PayloadAction<ErrorResponse | undefined>
    ) {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload;
      }
    },

    resetUserAccount(state) {
      state.isLoading = false;
      state.errorMessage = undefined;
      state.isUpdateUserSuccess = false;
      state.isPasswordUpdated = false;
    },

    setUserPassword(state, action: PayloadAction<SetPasswordRequest>) {
      state.isLoading = true;
      state.errorMessage = undefined;
    },
    changeUserPassword(state, action: PayloadAction<ChangePasswordRequest>) {
      state.isLoading = true;
      state.errorMessage = undefined;
    },

    changeUserPasswordSuccess(
      state,
      action: PayloadAction<ChangePasswordResponse>
    ) {
      state.isLoading = false;
      state.isPasswordUpdated = true;
    },

    changeUserPasswordFailure(state, action: PayloadAction<ErrorResponse>) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },

    setIsMobileNumberUpdated(state, action: PayloadAction<boolean>) {
      state.isMobileNumberUpdated = action.payload;
    },
    getMobileVerificationCode(
      state,
      action: PayloadAction<PatchUserMobilePayload>
    ) {
      state.isLoading = true;
      state.errorMessage = undefined;
    },
    getMobileVerificationCodeSuccess(state) {
      state.isLoading = false;
      state.errorMessage = undefined;
    },
    getMobileVerificationFailed(state, action: PayloadAction<ErrorResponse>) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },

    updateUserEmail(state, action: PayloadAction<PatchUserEmailPayload>) {
      state.isLoading = true;
      state.errorMessage = undefined;
    },
    updateUserEmailSuccess(
      state,
      action: PayloadAction<PatchUserEmailResponse | null>
    ) {
      state.isLoading = false;
      state.errorMessage = undefined;

      if (action.payload !== null) {
        state.isEmailUpdated = action.payload.recordUpdated === 1;
      } else {
        state.isEmailUpdated = false;
      }
    },
    updateUserEmailFailed(state, action: PayloadAction<ErrorResponse>) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },

    setTermsAndConditionsNeedsUpdate(state) {
      state.isTermsAndConditionsUpToDate = false;
      state.postTermsAndConditionsError = null;
      state.postTermsAndConditionsLoading = false;
    },

    resetTermsAndConditionsNeedsUpdate(state) {
      state.isTermsAndConditionsUpToDate = true;
      state.postTermsAndConditionsError = null;
      state.postTermsAndConditionsLoading = false;
    },

    postTermsAndConditions(
      state,
      action: PayloadAction<IPostTermsAndConditionsRequest>
    ) {
      state.postTermsAndConditionsLoading = true;
    },

    postTermsAndConditionsSuccess(state, action: PayloadAction<boolean>) {
      state.postTermsAndConditionsLoading = false;
      state.postTermsAndConditionsError = null;
      state.isTermsAndConditionsUpToDate = true;
    },

    postTermsAndConditionsError(state, action: PayloadAction<IApiError>) {
      state.postTermsAndConditionsLoading = false;
      state.postTermsAndConditionsError = action.payload;
      state.isTermsAndConditionsUpToDate = false;
    },

    deleteAccountVerificationCode(state) {
      state.isDeleteUserLoading = true;
      state.isAccountVerificationCodeSent = false;
    },
    deleteAccountVerificationCodeSuccess(state) {
      state.isDeleteUserLoading = false;
      state.isAccountVerificationCodeSent = true;
    },
    deleteAccountVerificationCodeFailed(
      state,
      action: PayloadAction<ErrorResponse | undefined>
    ) {
      state.isDeleteUserLoading = false;
      state.errorMessage = action.payload;
      state.isAccountVerificationCodeSent = false;
    },

    verifyDeleteAccountCode(
      state,
      action: PayloadAction<DeleteAccountVerifyPayload>
    ) {
      state.isDeleteUserLoading = true;
    },
    verifyDeleteAccountCodeSuccess(state, action: PayloadAction<boolean>) {
      state.isAccountDeleted = action.payload;
      state.isDeleteUserLoading = false;
      state.isAccountVerificationCodeSent = false;
    },
    verifyDeleteAccountFailed(
      state,
      action: PayloadAction<ErrorResponse | undefined>
    ) {
      state.isDeleteUserLoading = false;
      state.errorMessage = action.payload;
    },

    verifyEmailAddress(state) {
      state.verifyEmailAddressLoading = true;
    },
    verifyEmailAddressSuccess(state) {
      state.isVerifyEmailAddressSuccess = true;
      state.verifyEmailAddressError = null;
      state.verifyEmailAddressLoading = false;
    },
    verifyEmailAddressError(state, action: PayloadAction<ErrorResponse>) {
      state.verifyEmailAddressError = action.payload;
      state.isVerifyEmailAddressSuccess = false;
      state.verifyEmailAddressLoading = false;
    },

    resetError(state) {
      state.errorMessage = undefined;
      state.errorMessage = undefined;
      state.postTermsAndConditionsError = null;
    },
    setIsBrazeInitialised(state, action: PayloadAction<boolean>) {
      state.isBrazeInitialised = action.payload;
    },
    setShowMobileVerifyModal(state, action: PayloadAction<boolean>) {
      state.showMobileVerifyModal = action.payload;
    },
    setExistingUserMobileVerify(state, action: PayloadAction<boolean>) {
      state.existingUserMobileVerify = action.payload;
    },
    setOfflineStatus(state, action: PayloadAction<boolean>) {
      state.isOffline = action.payload;
    },
  },
});

const { actions, reducer } = userSlice;
export type UserActionsType = AllActionsOf<typeof actions>;
export { actions as userActions, reducer as userReducer };
