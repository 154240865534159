import React from "react";
import { StyleSheet, View, Platform, Modal } from "react-native";
import MiddleModal, {
  WebModalSize,
} from "../../../components/modals/MiddleModal";
import ErrorView from "../../../components/Error/ErrorView";
import closeIcon from "../../../components/assets/icons/icon_close.png";
import IconButton from "../../../components/IconButton";
import colours from "../../styles/colours";
import { BorderRadius, Spacing } from "../../styles/number";
/**
 * UI: https://zpl.io/amjJJ1v
 */
export interface ErrorModalProps {
  heading?: string;
  message: string;
  isVisible: boolean;
  onClose?: () => void;
  onTryAgain?: () => void;
}

const style = StyleSheet.create({
  errorModal: {
    position: "relative",
    justifyContent: "center",
    alignContent: "center",
    flex: 1,
    backgroundColor: Platform.OS == "web" ? "transparent" : "rgba(0,0,0,0.7)",
  },
  errorModalContainer: {
    backgroundColor: colours.white,
    borderRadius: BorderRadius.Medium,
    margin: Platform.OS == "web" ? 0 : Spacing.Light,
    padding: Spacing.Light,
    paddingBottom: Spacing.Thick,
  },
  errorModalBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 2,
  },
});

export const ErrorModal: React.FC<ErrorModalProps> = ({
  message,
  heading,
  isVisible,
  onClose,
  onTryAgain,
}) => {
  const ContentView = () => {
    return (
      <View style={style.errorModal}>
        <View style={style.errorModalContainer}>
          {onClose && (
            <View style={style.errorModalBtn}>
              <IconButton
                iconImage={closeIcon}
                onPress={onClose}
                withShadow={false}
              />
            </View>
          )}
          <ErrorView
            heading={heading}
            message={message}
            onTryAgain={onTryAgain}
          />
        </View>
      </View>
    );
  };

  return Platform.OS == "web" ? (
    <MiddleModal
      testId='ErrorModal'
      webModalSize={WebModalSize.SMALL}
      isVisible={isVisible}>
      {ContentView()}
    </MiddleModal>
  ) : (
    <Modal animationType='fade' transparent={true} visible={isVisible}>
      {ContentView()}
    </Modal>
  );
};
