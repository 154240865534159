import React from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  Platform,
  Text,
  TouchableOpacity,
} from "react-native";
import { useDispatch } from "react-redux";

import * as PlatformUtils from "../../modules/platformUtils";
import * as StoreModules from "../../modules/Store/";
import * as StoreReduxModels from "../../redux_store/store/models";
import { storeActions as StoreReduxAction } from "../../redux_store/store/store.slice";
import InfoIcon from "../assets/icons/icon_info_filled.png";
import styles from "./StoreMoreInfoButton.styles";

const StoreInfoButton: React.FC<{
  store: StoreReduxModels.Store;
  onPressAction?: () => void;
}> = ({ store, onPressAction }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onPressHandler = () => {
    if (onPressAction) {
      onPressAction();
    } else {
      dispatch(StoreReduxAction.setStoreInfo(store));
    }
  };

  return (
    <TouchableOpacity
      {...PlatformUtils.generateTestID(
        Platform.OS,
        StoreModules.StoreModels.StoresTestingId.moreInfoBtn
      )}
      style={styles.infoBtn}
      activeOpacity={0.6}
      onPress={onPressHandler}>
      <Image style={styles.icon} source={InfoIcon as ImageSourcePropType} />
      <Text style={styles.infoBtnText}>{t("StoreSearch:moreInfoBtn")}</Text>
    </TouchableOpacity>
  );
};

export default StoreInfoButton;
