import React from "react";
import { Image, StyleSheet, Text, View, Pressable } from "react-native";
import { useTranslation } from "react-i18next";

import iconImage from "./assets/icons/icon_tick.png";
import colours from "./styles/colours";
import { Typography } from "./styles/typography";
import { BorderRadius, Spacing } from "./styles/number";

export interface MakeItAMealButtonButtonProps {
  checked: boolean;
  onPress: () => void;
  mealDisabled: boolean;
}

const styles = (disabled: boolean) =>
  StyleSheet.create({
    wrapper: {
      borderRadius: BorderRadius.Medium,
      backgroundColor: colours.white,
      borderWidth: 2,
      borderColor: disabled ? colours.lightGrey : colours.yellow,
      marginBottom: Spacing.Light,
    },
    container: {
      width: "100%",
      height: 48,
      paddingLeft: Spacing.ExtraLight,
      paddingRight: Spacing.ExtraLight,
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      alignSelf: "center",
      borderRadius: BorderRadius.Medium,
      justifyContent: "center",
    },
    title: {
      ...Typography.titleFour,
      color: disabled ? colours.uiGrey : colours.black,
      textTransform: "uppercase",
      textAlign: "center",
      alignSelf: "center",
      justifyContent: "center",
      flex: 3,
    },
    icon: {
      width: 32,
      height: 32,
    },
    split: {
      flex: 1,
      alignItems: "flex-end",
    },
  });

const MakeItAMealButton: React.FC<MakeItAMealButtonButtonProps> = (props) => {
  const { onPress, checked, mealDisabled } = props;
  const { t } = useTranslation();
  return (
    <Pressable
      onPress={onPress}
      disabled={mealDisabled}
      style={styles(mealDisabled).wrapper}>
      <View style={styles(mealDisabled).container}>
        <View style={styles(mealDisabled).split} />
        <Text style={styles(mealDisabled).title}>
          {t("OrderManagement:makeMealSection")}
        </Text>
        <View style={styles(mealDisabled).split}>
          {checked && (
            <Image source={iconImage} style={styles(mealDisabled).icon} />
          )}
        </View>
      </View>
    </Pressable>
  );
};

export default MakeItAMealButton;
