import { StoreTradingHour } from "../services/api/store/model";

export const isStoreOpenThisWeek = (tradingHours?: StoreTradingHour[]) => {
  return tradingHours
    ? tradingHours.some((storeHours) => !!storeHours.timePeriods.length)
    : false;
};

export const getStatusCode = (code?: string | number) => {
  if (!code) return 0;
  return typeof code === "string" ? parseInt(code) : code;
};
