export enum RestaurantsViewType {
  LIST = "LIST",
  MAP = "MAP",
}

export enum StoresTestingId {
  toggleViewBtn = "TOGGLE_STORES_VIEW_BUTTON",
  listViewIcon = "LIST_VIEW_ICON",
  mapViewIcon = "MAP_VIEW_ICON",
  storesMapContainer = "STORES_MAP_CONTAINER",
  mapPin = "MAP_STORE_PIN",
  moreInfoBtn = "STORE_MORE_INFO_BUTTON",
  myLocationButtonWrapper = "MY_LOCATION_BUTTON_WRAPPER",
  basicDetailsBlackButton = "STORE_BASIC_DETAILS_BLACK_BUTTON",
  detailsPopup = "STORE_DETAILS_MAP_POPUP",
  detailsModal = "STORE_DETAILS_MODAL",
  shareStoreButton = "SHARE_STORE_BUTTON",
}

export enum ResultListItemType {
  GOOGLE_PLACE = "GOOGLE_PLACE",
  FUZZY_SEARCH = "FUZZY_SEARCH",
}

export enum StoreTagLabel {
  BREAKFAST = "breakfast",
  DRIVE_THRU = "drive thru",
  OPEN_NOW = "open now",
  PICKUP = "pickup",
  TABLE_SERVICE = "table service",
  WHEELCHAIR_ACCESSIBLE = "wheelchair accessible",
  LIQUOR = "liquor",
  COFFEE = "coffee",
}
