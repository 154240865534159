import * as Sentry from "@sentry/react";

import { ISentryLogger } from "gyg_common/sentry";
import { getErrorForLogging, getErrorLog } from "gyg_common/utils/Logging";

export class SentryLogger implements ISentryLogger {
  sentryRefreshTokenError(error: unknown): void {
    const errorMessage = `Refresh access token failed: ${getErrorForLogging(
      error
    )}`;
    if (!__DEV__) {
      Sentry.captureMessage(errorMessage, "fatal");
    }
    console.error(errorMessage);
  }

  sentryEndpointError(error: unknown, additionalErrorInfo?: string) {
    const errorDetails = getErrorLog(error);
    const errorMessage = `API error ${
      errorDetails.code && `(${errorDetails.code})`
    } ${errorDetails.message && `: "${errorDetails.message}"`}`;
    const fullMessage = additionalErrorInfo
      ? `${errorMessage} ${additionalErrorInfo}`
      : errorMessage;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const httpStatus = (error as any)?.response?.status;
    if (!__DEV__) {
      if (httpStatus) {
        Sentry.setTag("http.status", httpStatus);
      }
      Sentry.captureMessage(fullMessage, "error");
    }
    console.error(fullMessage);
  }

  sentryLowMemoryWarning() {
    // do nothing here - low memory detection is not supported by react
  }

  sentryBraintreeError(error: unknown) {
    const errorMessage = `Braintree error: ${getErrorForLogging(error)}`;
    if (!__DEV__) {
      Sentry.captureMessage(errorMessage, "error");
    }
    console.error(errorMessage);
  }

  sentryCaptureCustomError(description: string, error: unknown) {
    const errorMessage = `${description}: ${getErrorForLogging(error)}`;
    if (!__DEV__) {
      Sentry.captureMessage(errorMessage, "error");
    }
    console.error(errorMessage);
  }

  sentrySetTag(tagName: string, tagValue: string) {
    if (!__DEV__) {
      Sentry.setTag(tagName, tagValue);
    }
  }

  sentryRouteTracing(routeName: string) {
    if (!__DEV__) {
      console.debug("not available on web", routeName);
    }
  }
}
