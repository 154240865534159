import { IMobileAppConfig } from "../../../redux_store/dashboard/models";
import { httpClient } from "../../httpClient";
import { SentryLoggerInstance } from "../../../sentry";

export async function getMobileAppConfig(): Promise<IMobileAppConfig> {
  try {
    console.debug("API: GET config/mobileAppConfig.json");

    const response = await httpClient({
      url: "config/mobileAppConfig.json",
      method: "GET",
    });

    return response.data as IMobileAppConfig;
  } catch (e) {
    SentryLoggerInstance.sentryEndpointError(
      e,
      "GET config/mobileAppConfig.json"
    );
    throw e;
  }
}
