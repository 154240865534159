import "@szhsin/react-menu/dist/index.css";
import "./styles.scss";

import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import React, { useEffect, useRef, useState } from "react";

import iconChevronDown from "gyg_common/components/assets/icons/icon_chevron_down_small.png";

export interface DropdownMenuItem<T> {
  label: string;
  value: T;
}
export interface DropdownMenuProps<T> {
  items: DropdownMenuItem<T>[];
  selectedItem?: DropdownMenuItem<T>;
  dropdownDirection?: "top" | "bottom" | "left" | "right";
  isError?: boolean;
  onItemSelected: (selected: DropdownMenuItem<T>) => void;
}
export const DropdownMenu = <T,>(props: DropdownMenuProps<T>) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [menuItemWidth, setMenuItemWidth] = useState<number>();
  const { items, selectedItem, dropdownDirection, isError, onItemSelected } =
    props;

  const onMenuItemClick = (selected: DropdownMenuItem<T>) => {
    onItemSelected(selected);
  };

  useEffect(() => {
    if (targetRef.current) {
      const width: number = +targetRef.current.offsetWidth;
      const widthWithoutSpace: number = width - 45;
      setMenuItemWidth(widthWithoutSpace);
    }
  }, []);

  return (
    <div ref={targetRef} className={"dropdown-menu-container"}>
      <Menu
        menuClassName='dropdown-menu'
        direction={dropdownDirection ? dropdownDirection : "bottom"}
        transition={false}
        menuButton={
          <MenuButton
            className={
              isError ? "dropdown-menu__button-error" : "dropdown-menu__button"
            }>
            <p className={"body-bold"}>{selectedItem?.label}</p>
            <img alt='' src={iconChevronDown} height={24} width={24} />
          </MenuButton>
        }>
        {items.map((menuItem) => {
          return (
            <MenuItem
              key={menuItem.label}
              onClick={() => onMenuItemClick(menuItem)}
              style={{ width: menuItemWidth }}>
              <p className={"dropdown-menu__item-label"}>{menuItem.label}</p>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
