import React from "react";
import { Platform, StyleSheet, Text, View } from "react-native";

import colours from "./styles/colours";
import { Spacing } from "./styles/number";
import { Typography } from "./styles/typography";

const userInitialsSize = 48;

const styles = StyleSheet.create({
  text: {
    ...Typography.titleTwo,
    ...(Platform.OS !== "web" && { marginTop: 6 }),
  },
  userInitials: {
    justifyContent: "center",
    alignItems: "center",
    width: userInitialsSize,
    height: userInitialsSize,
    backgroundColor: colours.yellow,
    borderRadius: userInitialsSize / 2,
    marginRight: Spacing.Light,
  },
  userInfo: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export interface UserProfileNameProps {
  firstName: string;
  lastName?: string;
}

const UserProfileName = (props: UserProfileNameProps): JSX.Element => {
  const { firstName, lastName } = props;
  return (
    <View style={styles.userInfo}>
      <View style={styles.userInitials}>
        <Text style={styles.text}>
          {firstName.charAt(0)}
          {lastName?.charAt(0)}
        </Text>
      </View>
      <Text style={styles.text}>
        {firstName.toUpperCase()} {lastName?.toUpperCase()}
      </Text>
    </View>
  );
};

export default UserProfileName;
