import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sortDashboardByDisplayOrder } from "../../modules/Dashboard/utils";
import { AllActionsOf } from "../../redux_store/utils/Actions";
import { BasketItem } from "../../services/api/order/model";
import { CheckoutItems } from "../checkout/model";
import { IDashboardState, IMobileAppConfig } from "./models";

export const initialState: IDashboardState = {
  getMobileAppConfigLoading: false,
  getMobileAppConfigError: false,
  mobileAppConfig: undefined,
  recentOrderToAddToCart: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getMobileAppConfig(state) {
      state.getMobileAppConfigLoading = true;
    },
    getMobileAppConfigError(state) {
      state.getMobileAppConfigLoading = false;
      state.getMobileAppConfigError = true;
    },
    getMobileAppConfigSuccess(state, action: PayloadAction<IMobileAppConfig>) {
      state.mobileAppConfig = sortDashboardByDisplayOrder(action.payload);
      state.getMobileAppConfigLoading = false;
      state.getMobileAppConfigError = false;
    },
    setRecentOrderToAddToCart(
      state,
      action: PayloadAction<CheckoutItems[] | BasketItem[] | null>
    ) {
      state.recentOrderToAddToCart = action.payload;
    },
  },
});

const { actions, reducer } = dashboardSlice;
export type DashboardActionsType = AllActionsOf<typeof actions>;
export { actions as dashboardActions, reducer as dashboardReducer };
