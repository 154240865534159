import React from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, Text, View } from "react-native";

import SecondaryButton from "gyg_common/components/Buttons/SecondaryButton";
import TertiaryButton from "gyg_common/components/Buttons/TertiaryButton";
import { BorderRadius, Spacing } from "gyg_common/components/styles/number";
import { Typography } from "gyg_common/components/styles/typography";

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "rgba(52, 52, 52, 0.8)",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    backgroundColor: "white",
    padding: Spacing.Medium,
    borderRadius: BorderRadius.Medium,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: Spacing.Regular,
    width: Platform.OS == "web" ? "70%" : "90%",
  },
  description: {
    ...Typography.bodyTwo,
    paddingTop: Spacing.Regular,
    textAlign: "center",
  },
  buttonsContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: Spacing.Regular,
    alignItems: "center",
  },
  changeAddressButtonContainer: { flex: 1, paddingRight: Spacing.Regular },
  confirmButtonContainer: { flex: 0.7 },
});

interface ToFarAwayViewProps {
  userDistanceFromSelectedLocation: number;
  onChangeAddress: () => void;
  onConfirm: () => void;
}

const ToFarAwayView: React.FC<ToFarAwayViewProps> = ({
  userDistanceFromSelectedLocation,
  onChangeAddress,
  onConfirm,
}) => {
  const { t } = useTranslation();
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text style={{ ...Typography.titleOne }}>
          {t("OrderManagement:farAwayTitle").toUpperCase()}
        </Text>
        <Text style={styles.description}>
          {t("OrderManagement:farAwayDescription", {
            userDistanceFromSelectedLocation: `${userDistanceFromSelectedLocation.toFixed(
              0
            )}`,
          })}
        </Text>
        <View style={styles.buttonsContainer}>
          <View style={styles.changeAddressButtonContainer}>
            <TertiaryButton
              title={t("OrderManagement:changeAddress").toUpperCase()}
              onClick={onChangeAddress}
            />
          </View>
          <View style={styles.confirmButtonContainer}>
            <SecondaryButton
              buttonName={t("OrderManagement:confirm").toUpperCase()}
              onClick={onConfirm}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default ToFarAwayView;
