/* eslint-disable @typescript-eslint/no-explicit-any */
import { fork, put, takeLeading } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";

import { getSocialSignIn } from "../../services/api/social";
import { authActions } from "../auth/auth.slice";
import { CurrentUser } from "../auth/models";
import { buildErrorResponse } from "../error/utils";
import { userActions } from "../user/user.slice";
import { CurrentSocialResponse } from "./models";
import { socialActions, socialActionsType } from "./social.slice";

const call: any = Effects.call;

function* handleSocialSignIn(
  action: socialActionsType
): Generator<any, void, CurrentSocialResponse> {
  try {
    const result: CurrentSocialResponse = yield call(
      getSocialSignIn,
      action.payload
    );
    yield put(socialActions.getSocialSignInSuccess(result));
    if (
      result.verifiedMobileNumber &&
      result.refreshToken &&
      result.accessToken
    ) {
      yield put(authActions.loginSuccess(result as unknown as CurrentUser));
    } else {
      yield put(socialActions.setSocialRegister(true));
      if (!result.verifiedMobileNumber && result.isExistingAccount) {
        yield put(userActions.setExistingUserMobileVerify(true));
      }
    }
  } catch (e) {
    yield put(socialActions.error(buildErrorResponse(e)));
  }
}

export function* watchSocialSignIn(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLeading(socialActions.getSocialSignIn.type, handleSocialSignIn);
}

const saga = [fork(watchSocialSignIn)];

export default saga;
