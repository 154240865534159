import { useEffect, useState } from "react";

import { breakpoints } from "../styles/breakpoints";

export const useMediaQuery = (): {
  isDesktopScreen: boolean;
  isTabletScreen: boolean;
} => {
  const desktopMatch = window.matchMedia(
    `screen and (min-width:${breakpoints.lg})`
  );
  const tabletMatch = window.matchMedia(
    `screen and (min-width:${breakpoints.md})`
  );

  const [isDesktopScreen, setDesktopMatches] = useState(desktopMatch.matches);
  const [isTabletScreen, setTabletMatches] = useState(tabletMatch.matches);

  useEffect(() => {
    const desktopHandler = (e: MediaQueryListEvent) =>
      setDesktopMatches(e.matches);
    const tabletHandler = (e: MediaQueryListEvent) =>
      setTabletMatches(e.matches);

    try {
      desktopMatch.addEventListener("change", desktopHandler);
      tabletMatch.addEventListener("change", tabletHandler);
    } catch (e) {
      desktopMatch.addListener(desktopHandler); // Safari support issue (for older safari browser)
      tabletMatch.addListener(tabletHandler); // Safari support issue (for older safari browser)
    }
    return () => {
      try {
        desktopMatch.removeEventListener("change", desktopHandler);
        tabletMatch.removeEventListener("change", tabletHandler);
      } catch (e) {
        desktopMatch.removeListener(desktopHandler); // Safari support issue (for older safari browser)
        tabletMatch.removeListener(tabletHandler); // Safari support issue (for older safari browser)
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { isDesktopScreen, isTabletScreen };
};
