import illustLocation from "assets/images/illustration_location.png";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { PlatformUtils } from "gyg_common";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import TertiaryButton from "gyg_common/components/Buttons/TertiaryButton";
import MiddleModal, {
  WebModalSize,
} from "gyg_common/components/modals/MiddleModal";
import colours from "styles/colours";
import { Typography } from "styles/typography";

const styles = StyleSheet.create({
  locationImage: {
    alignItems: "center",
    marginTop: 48,
    marginBottom: 2,
  },
  container: {
    paddingTop: 32,
    paddingHorizontal: 16,
    paddingBottom: 16,
    borderRadius: 8,
  },
  title: {
    ...Typography.largeTitle,
    textTransform: "uppercase",
    textAlign: "center",
  },
  message: {
    textAlign: "center",
    paddingTop: 16,
    paddingLeft: 4,
    paddingRight: 4,
  },
  shareButtonWrapper: {
    backgroundColor: colours.white,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  buttonContainer: {
    flex: 1,
    padding: 8,
  },
  image: {
    maxWidth: 374 - 48,
    width: window.innerWidth,
    height: window.innerHeight / 4,
  },
});

export interface LocationPermissionModalProps {
  isVisible: boolean;
  onClose: () => void;
  onPrimaryClick: () => void;
}

export const LocationPermissionModal: React.FC<
  LocationPermissionModalProps
> = ({ isVisible, onClose, onPrimaryClick }) => {
  const { t } = useTranslation();

  if (isVisible) {
    return (
      <MiddleModal
        webModalSize={WebModalSize.SMALL}
        isVisible={isVisible}
        testId='location-permission'>
        <View style={styles.locationImage}>
          <Image
            style={styles.image}
            source={illustLocation as ImageSourcePropType}
            resizeMode='contain'
          />
        </View>
        <View
          {...PlatformUtils.generateTestID(Platform.OS, "ShareLocation")}
          style={styles.container}>
          <Text style={styles.title}>
            {t("OrderManagement:shareLocationMessageTitle")}
          </Text>
          <Text style={[Typography.body, styles.message]}>
            {t("OrderManagement:shareLocationMessageBody")}
          </Text>
          <View style={styles.shareButtonWrapper}>
            <View
              style={styles.buttonContainer}
              {...PlatformUtils.generateTestID(
                Platform.OS,
                "DontShareLocationButton"
              )}>
              <TertiaryButton
                title={t("StoreSearch:locationPromptNotNow")}
                onClick={onClose}
              />
            </View>
            <View
              style={styles.buttonContainer}
              {...PlatformUtils.generateTestID(
                Platform.OS,
                "ShareLocationButton"
              )}>
              <PrimaryBlackButton
                buttonName={t("StoreSearch:locationPromptShare")}
                onClick={onPrimaryClick}
              />
            </View>
          </View>
        </View>
      </MiddleModal>
    );
  } else {
    return <></>;
  }
};
