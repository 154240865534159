import React from "react";
import { Platform, StyleSheet, Text, TextStyle, View } from "react-native";

import * as PlatformUtils from "../modules/platformUtils";
import { Typography } from "./styles/typography";

export interface AllergenStatementProps {
  statement: string;
  label: string;
  onOpenLink: () => void;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  statement: {
    ...Typography.captionTwo,
    fontSize: 13,
    lineHeight: 24,
    flex: 1,
    flexWrap: "nowrap",
  },
  link: {
    ...Typography.bodyBold,
    textDecorationLine: "underline" as TextStyle["textDecorationLine"],
    position: "relative",
  },
});

/**
 * Displays caption on allergen information.
 * Includes link but due to react native limitations designed UI couldn't be achieved with `LinkButton`.
 * @param props
 * @returns
 */
const AllergenStatement: React.FC<AllergenStatementProps> = (props) => {
  const { statement, label, onOpenLink } = props;

  return (
    <View
      style={styles.container}
      {...PlatformUtils.generateTestID(Platform.OS, "AllergenStatement")}>
      <Text style={styles.statement}>
        <Text>{`${statement} `}</Text>
        <Text style={styles.link} onPress={onOpenLink}>
          {label}
        </Text>
      </Text>
    </View>
  );
};

export default AllergenStatement;
