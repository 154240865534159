import React, { useCallback, useState } from "react";
import {
  ActivityIndicator,
  Image,
  ImageResizeMode,
  ImageSourcePropType,
  ImageStyle,
  ImageURISource,
  StyleProp,
  View,
} from "react-native";

import colours from "../styles/colours";
import { styles } from "./MenuImage.styles";

export interface MenuImageProps {
  resizeMode?: ImageResizeMode;
  testId?: string;
  defaultSource?: ImageURISource;
  source: ImageSourcePropType;
  style?: StyleProp<ImageStyle>;
}

/**
 * Re renders image if image fails to load by changing the key on fail
 * @param props ImageProps
 * @returns
 */
const MenuImage: React.FC<Readonly<MenuImageProps>> = (props) => {
  const maxNumOfRetries = 5;

  const [retryCounter, setRetryCounter] = useState(0);
  const [loading, setLoading] = useState(true);

  const onImageError = useCallback(() => {
    if (retryCounter <= maxNumOfRetries) {
      setRetryCounter((prevCounter) => prevCounter + 1);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onImageLoaded = useCallback(() => {
    setLoading(false);
  }, []);

  return (
    <View style={[styles.container, props.style || {}]}>
      <Image
        key={`image-${retryCounter}`}
        {...props}
        source={
          !!props.source && retryCounter <= maxNumOfRetries
            ? props.source
            : props.defaultSource
        }
        defaultSource={loading && props.source ? null : props.defaultSource}
        onError={onImageError}
        onLoad={onImageLoaded}
      />
      {loading && props.source && (
        <ActivityIndicator
          size='small'
          style={{ position: "absolute" }}
          color={colours.yellow}
        />
      )}
    </View>
  );
};

export default MenuImage;
