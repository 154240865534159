import React, { useEffect, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
  ListRenderItem,
  FlatList,
  Platform,
} from "react-native";
import colours from "./styles/colours";
import { Category, Product } from "../redux_store/menu/models";
import * as PlatformUtils from "../modules/platformUtils";
import { Typography } from "./styles/typography";
import { Spacing } from "./styles/number";
import IconButton from "./IconButton";
import arrowIcon from "./assets/icons/icon_chevron_left.png";
import closeIcon from "./assets/icons/icon_close.png";

interface ModalListOptionsProps {
  title: string;
  optionData: (Category | Product)[] | undefined;
  enableCloseMode?: boolean;
  renderItem: ListRenderItem<Category | Product>;
  onBack?: () => void;
}

const styles = StyleSheet.create({
  background: {
    resizeMode: "cover",
    flexDirection: "column",
  },
  top: {
    borderBottomColor: colours.lightGrey,
    backgroundColor: colours.lightestGrey,
    borderBottomWidth: 1,
  },
  title: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: Spacing.Light,
    marginVertical:
      Platform.OS === "web" ? Spacing.ExtraLight : Spacing.Regular,
  },
  titleOffet: {
    marginLeft: Platform.OS === "web" ? Spacing.Regular : 0,
  },
  listContainer: {
    paddingVertical: Platform.OS === "web" ? 0 : Spacing.Light,
  },
  list: {
    maxHeight: Platform.OS === "web" ? "100%" : "88%",
    paddingBottom: Spacing.Light,
  },
});

const ModalListOptions: React.FC<ModalListOptionsProps> = (props) => {
  const { optionData, renderItem, title, onBack, enableCloseMode } = props;

  const refToTop = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (refToTop.current && Platform.OS === "web") {
      refToTop.current.scrollIntoView();
    }
  });

  const childComponent = () => {
    return (
      <>
        <View
          {...PlatformUtils.generateWebID(
            Platform.OS,
            "modal-list-options__header"
          )}>
          <View style={styles.top}>
            <View style={styles.title}>
              {Platform.OS === "web" && (
                <IconButton
                  onPress={onBack}
                  withShadow={true}
                  iconImage={enableCloseMode ? closeIcon : arrowIcon}
                />
              )}
              <Text style={[Typography.titleTwo, styles.titleOffet]}>
                {title.toUpperCase()}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.list}>
          <FlatList
            keyExtractor={(item) => `${item.id}-${item.name}`}
            bounces={false}
            data={optionData}
            renderItem={renderItem}
            contentContainerStyle={styles.listContainer}
          />
        </View>
      </>
    );
  };

  return (
    <View {...PlatformUtils.generateWebID(Platform.OS, "modal-list-options")}>
      {Platform.OS === "web" ? (
        <div ref={refToTop}>{childComponent()}</div>
      ) : (
        <ImageBackground
          style={styles.background}
          source={require("./assets/images/main_bg.jpg")}>
          {childComponent()}
        </ImageBackground>
      )}
    </View>
  );
};

export default ModalListOptions;
