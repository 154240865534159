import { useEffect, useRef } from "react";
import { Platform } from "react-native";
import { useSelector } from "react-redux";

import {
  AnalyticsConst,
  AnalyticsInstance,
  locales,
  RootState,
} from "gyg_common";

type RouteName = string | null | undefined;

/**
 * routeName is for app's purpose.
 * App passes the value from navigationState which is not available from web.
 */
export const useLoginTrack = (routeName: RouteName) => {
  const hasLoginTracked = useRef(false);
  const { currentUser, isRefreshToken, fromRegistration } = useSelector(
    (s: RootState) => s.login
  );
  const { localGyg, storeId } = useSelector((s: RootState) => s.analytics);

  useEffect(() => {
    // Resets hasLoginTracked if the user logged out.
    if (!currentUser) {
      hasLoginTracked.current = false;
    }

    if (currentUser && !hasLoginTracked.current && !fromRegistration) {
      hasLoginTracked.current = true;
      const eventPayload = {
        data_source: Platform.OS,
        data_location: (process.env.REACT_APP_VERSION as locales) || locales.AU,
        closest_store_name: localGyg,
        closest_store_id: storeId,
        logged_in: true,
        customer_id: currentUser.brazeId,
        customer_email: currentUser.userName,
        customer_first_name: currentUser.firstName,
        customer_last_name: currentUser.lastName,
        page_name: Platform.OS === "web" ? "login" : routeName,
        page_url: Platform.OS === "web" ? window.location.href : null,
        used_refresh_token: isRefreshToken,
      };

      AnalyticsInstance.trackEvent(
        AnalyticsConst.Events.LoginSucess,
        eventPayload
      );
    }
  }, [
    routeName,
    currentUser,
    localGyg,
    storeId,
    isRefreshToken,
    fromRegistration,
  ]);
};
