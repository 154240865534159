import React from "react";
import { useTranslation } from "react-i18next";
import { Image, Platform, StyleSheet, Text, View } from "react-native";

import * as CheckoutModels from "../redux_store/checkout/model";
import * as PlatformUtils from "../modules/platformUtils";
import { paymentTypeInfo, PaymentIcon } from "./config/payment";
import { Typography } from "./styles/typography";
import { Spacing } from "./styles/number";

export interface PaymentTypeInfoProps {
  paymentTypes: CheckoutModels.PaymentCheckoutResponse[];
}

const style = StyleSheet.create({
  wrapper: {
    marginVertical: Spacing.Thin,
    flexDirection: "row",
    alignItems: "center",
  },
  paymentText: {
    flexWrap: "wrap",
    width: "75%",
    flexDirection: "row",
    alignItems: "center",
  },
  paymentIcon: {
    marginRight: Spacing.Light,
  },
  image: {
    width: 38,
    height: 24,
  },
});

/**
 * Displays matching payment type icon and copy.
 * @param props paymentTypes
 */
const PaymentTypeInfo: React.FC<PaymentTypeInfoProps> = (props) => {
  const { paymentTypes } = props;
  const { t } = useTranslation();

  let infoObj: PaymentIcon[] = paymentTypes?.map(
    (paymentType: CheckoutModels.PaymentCheckoutResponse) =>
      paymentTypeInfo.filter(
        (info: PaymentIcon) => info.id === paymentType.type
      )[0]
  );

  // removes rewards payment types
  infoObj = infoObj.filter(
    (obj) =>
      obj.id !== CheckoutModels.PaymentType.COUPON &&
      obj.id !== CheckoutModels.PaymentType.GIFTCARD
  );

  return (
    <>
      {infoObj.length !== 0 &&
        infoObj?.map((paymentInfoObj: PaymentIcon, i: number) => (
          <View
            style={style.wrapper}
            key={paymentInfoObj.id + i}
            {...PlatformUtils.generateTestID(
              Platform.OS,
              `PAYMENT_TYPE_${paymentInfoObj.id}`
            )}>
            <View style={style.paymentIcon}>
              <Image style={style.image} source={paymentInfoObj.url} />
            </View>
            <View style={style.paymentText}>
              <Text style={Typography.bodyTwo}>{paymentInfoObj.copy}</Text>
              <Text style={Typography.bodyTwo}>. </Text>
              <Text style={Typography.bodyTwo}>
                {t("OrderStatus:paymentInfo")}
              </Text>
            </View>
          </View>
        ))}
    </>
  );
};

export default PaymentTypeInfo;
