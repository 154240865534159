import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  KeyboardTypeOptions,
  NativeSyntheticEvent,
  Platform,
  Text,
  TextInput,
  TextInputChangeEventData,
  TextInputProps,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";

import { InputType } from "../../modules/Components/Shared/InputType.enum";
import * as PlatformUtils from "../../modules/platformUtils";
import closeIcon from "../assets/icons/icon_close.png";
import editIcon from "../assets/icons/icon_edit.png";
import hideIcon from "../assets/icons/icon_hide_small.png";
import showIcon from "../assets/icons/icon_show_small.png";
import tickIcon from "../assets/icons/icon_tick_small.png";
import colours from "../styles/colours";
import { styles, wrapperStyles } from "./FormInput.styles";

export interface FormInputProps extends TextInputProps {
  isDisabled?: boolean;
  isDisablePadding?: boolean;
  isHidden?: boolean;
  hideEdit?: boolean;
  name?: string | null;
  confirm?: boolean;
  error?: boolean;
  errorMsg?: string | null;
  type?: InputType;
  placeHolder?: string | null;
  value?: string;
  isBorderBottomOnly?: boolean;
  testId?: string;
  inputPrefix?: string;
  contextMenuHidden?: boolean;
  disableClipboard?: boolean;
  onEdit?: () => void;
  // note the prop has to differ from TextInput RN component
  // onChangeText prop due to custom handling for text changes
  handleChangeText?: (text: string) => void;
  onTouchInput?: () => void;
  onActivation?: () => void;
}

const FormInput = React.forwardRef(
  (props: FormInputProps, ref: React.Ref<TextInput>) => {
    const { t } = useTranslation();
    const [isFocused, setFocused] = useState<boolean>(false);
    const [isCensored, setCensored] = useState<boolean>(
      props.type === InputType.PASSWORD
    );

    const containerStyles = props.isBorderBottomOnly
      ? styles(props.error, !!props.inputPrefix, isFocused)
          .inputContainerWithIcon
      : styles(props.error, !!props.inputPrefix, isFocused, !!props.isDisabled)
          .inputContainer;
    const prefixStyles = styles(
      props.error,
      !!props.inputPrefix,
      isFocused
    ).prefix;

    const getTextInputStyles = () => {
      if (Platform.OS === "web") {
        return styles(props.error, !!props.inputPrefix).inputText;
      } else if (props.isBorderBottomOnly) {
        return styles(props.error, !!props.inputPrefix).inputTextBorderBottom;
      } else if (props.value && props.value.length > 0) {
        return styles(props.error, !!props.inputPrefix).inputText;
      } else {
        return styles(props.error, !!props.inputPrefix).placeholderText;
      }
    };

    const keyboardType = (): KeyboardTypeOptions | undefined => {
      switch (props.type) {
        case InputType.BIRTHDAY:
          return "numeric";
        case InputType.PHONE:
          return "phone-pad";
        case InputType.EMAIL:
          return "email-address";
        case InputType.DECIMAL:
          return "decimal-pad";
        case InputType.NUMBER:
          return "number-pad";
        default:
          return "default";
      }
    };

    const onFocus = () => {
      setFocused(true);
      if (Platform.OS === "android" && props.onTouchInput) {
        props.onTouchInput();
      }
      if (props.onActivation) props.onActivation();
    };

    const onBlur = () => {
      setFocused(false);
    };

    const handleOnChange = (
      changeEvent: NativeSyntheticEvent<TextInputChangeEventData>
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { inputType, text } = changeEvent.nativeEvent as any;
      // if native event is cut or paste, set the input value to previous value or empty
      if (props.handleChangeText) {
        if (inputType === "deleteByCut" || inputType === "insertFromPaste") {
          changeEvent.preventDefault();
          changeEvent.stopPropagation();
        }
        props.handleChangeText(text);
      }
    };

    const handlePasswordIconPress = () => setCensored(!isCensored);

    const handleRemoveText = () => props.handleChangeText("");

    return (
      <>
        {!props.isHidden ? (
          <View style={wrapperStyles(props.isDisablePadding).container}>
            {!!props.name && (
              <Text style={styles(props.error).inputName}>{props.name}</Text>
            )}
            <View style={containerStyles}>
              {props.inputPrefix && (
                <Text style={prefixStyles}>{props.inputPrefix}</Text>
              )}
              <TextInput
                {...props}
                {...PlatformUtils.generateTestID(
                  Platform.OS,
                  props.testId ?? "TextInput"
                )}
                ref={ref}
                contextMenuHidden={props.contextMenuHidden}
                autoCorrect={!InputType.EMAIL}
                autoCapitalize={InputType.EMAIL ? "none" : undefined}
                keyboardType={keyboardType()}
                maxLength={props.type === InputType.BIRTHDAY ? 10 : undefined}
                secureTextEntry={isCensored}
                placeholder={props.placeHolder ? props.placeHolder : undefined}
                placeholderTextColor={colours.uiGrey}
                onFocus={onFocus}
                onBlur={onBlur}
                value={props.value}
                editable={!props.isDisabled}
                onChange={handleOnChange}
                style={getTextInputStyles()}
                returnKeyType={
                  InputType.NUMBER || InputType.BIRTHDAY ? "done" : "none"
                }
              />
              {props.isBorderBottomOnly && !props.hideEdit && (
                <TouchableOpacity
                  style={styles(false).editContainer}
                  onPress={props.onEdit}>
                  <Image
                    style={styles(props.error).editIcon}
                    source={editIcon}
                  />
                  <Text style={styles(false).editText}>
                    {t("UserManagement:editLabel")}
                  </Text>
                </TouchableOpacity>
              )}
              {props.type === InputType.PASSWORD ? (
                <TouchableWithoutFeedback onPress={handlePasswordIconPress}>
                  <Image
                    style={styles(props.error).icon}
                    source={isCensored ? showIcon : hideIcon}
                  />
                </TouchableWithoutFeedback>
              ) : props.type === InputType.NUMBER && props.value.length > 0 ? (
                <TouchableWithoutFeedback onPress={handleRemoveText}>
                  <Image
                    style={styles(props.error).closeIcon}
                    source={closeIcon}
                  />
                </TouchableWithoutFeedback>
              ) : props.error ? (
                <Image
                  {...PlatformUtils.generateTestID(
                    Platform.OS,
                    "FormInputEyeIcon"
                  )}
                  style={styles(props.error).redIcon}
                  source={closeIcon}
                />
              ) : (
                props.confirm &&
                !isFocused && (
                  <Image
                    {...PlatformUtils.generateTestID(
                      Platform.OS,
                      "FormInputEyeIcon"
                    )}
                    style={styles(props.error).tickIcon}
                    source={tickIcon}
                  />
                )
              )}
            </View>

            <Text
              {...PlatformUtils.generateTestID(Platform.OS, "FormInputError")}
              style={styles(props.error).errorMsg}>
              {props.error &&
                (props.errorMsg
                  ? props.errorMsg
                  : t("CheckoutPayment:mandatoryField"))}
            </Text>
          </View>
        ) : null}
      </>
    );
  }
);

export default FormInput;
