export enum Screens {
  Dashboard = "/",
  Order = "/order",
  Menu = "/menu",
  OrderStatus = "/status",
  Checkout = "/checkout",
  Payment = "/payment",
  Login = "/authenticate",
  Registration = "/registration",
  Restaurants = "/restaurants",
  UserProfile = "/user",
  MyGomex = "/mygomex",
  Social = "/social",
  RecentOrders = "/recent-orders",
  OrderDetails = "/details",
  Upsell = "/upsell",
  Notifications = "/notifications",
  Rewards = "/rewards",
  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",
}

export enum ScreenParams {
  ID = "id",
  AUTH = "authMode",
  CODE = "code",
}
