export enum InputType {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  PHONE = "phone",
  NUMBER = "number",
  BIRTHDAY = "birthday",
  EMAIL = "email",
  PASSWORD = "password",
  CONFIRM_PASSWORD = "confirmPassword",
  DECIMAL = "decimal",
}
