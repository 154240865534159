import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Image, StyleSheet, Text, View } from "react-native";

import {
  hasLowerCase,
  hasNumber,
  hasUpperCase,
  hasWhiteSpace,
  isEightChars,
} from "../modules/stringValidators";
import bulletIcon from "./assets/icons/icon_bullet_small.png";
import crossIcon from "./assets/icons/icon_close_small.png";
import tickIcon from "./assets/icons/icon_tick_small.png";
import colours from "./styles/colours";
import { Spacing } from "./styles/number";
import { Typography } from "./styles/typography";

interface Input {
  text: string;
  error: boolean;
}

export interface PasswordProps {
  value?: string;
  passwordInput?: Input;
  isError?: boolean;
}

const styles = StyleSheet.create({
  innerCell: {
    flexDirection: "row",
    alignItems: "center",
    minWidth: 130,
  },
  cellContainer: {
    marginTop: -Spacing.Thin,
    flexDirection: "row",
    flex: 1,
    flexWrap: "wrap",
    marginRight: Spacing.Light,
    justifyContent: "space-between",
    marginBottom: Spacing.Regular,
  },
  icon: {
    width: 24,
    height: 24,
  },
  passwordDesc: {
    ...Typography.bodyTwo,
    color: colours.midGrey,
  },
});

const PasswordValidation: React.FC<PasswordProps> = ({
  value,
  passwordInput,
  isError,
}) => {
  const { t } = useTranslation();

  const validators = useMemo(() => {
    const passwordValue = value ?? "";
    return [
      {
        text: t("LoginRegistration:passwordDescOne"),
        iconState: isEightChars(passwordValue),
      },
      {
        text: t("LoginRegistration:passwordDescTwo"),
        iconState: hasLowerCase(passwordValue),
      },
      {
        text: t("LoginRegistration:passwordDescThree"),
        iconState: hasNumber(passwordValue),
      },
      {
        text: t("LoginRegistration:passwordDescFour"),
        iconState: hasUpperCase(passwordValue),
      },
      {
        text: t("LoginRegistration:passwordDescFive"),
        iconState: !hasWhiteSpace(passwordValue),
      },
    ];
  }, [value, t]);

  const renderIcon = useCallback(
    (iconState: boolean) => {
      const icon = iconState
        ? tickIcon
        : passwordInput?.error || isError
        ? crossIcon
        : bulletIcon;
      return (
        <Image
          style={styles.icon}
          // inline icon
          source={icon}
        />
      );
    },
    [isError, passwordInput?.error]
  );

  return (
    <View style={styles.cellContainer}>
      {validators.map((item, idx) => {
        return (
          <View style={styles.innerCell} key={idx}>
            {renderIcon(item.iconState)}
            <Text style={styles.passwordDesc}>{item.text}</Text>
          </View>
        );
      })}
    </View>
  );
};

export default PasswordValidation;
