import { Store } from "../../redux_store/store/models";
import { AnalyticsType } from "./model";

export const barcodePayload = (
  source: string | undefined,
  nearestStore: Store | null
): AnalyticsType => {
  let sourceData: AnalyticsType = null;
  if (source) {
    sourceData = { source: source };
  }

  let nearestStoreData: AnalyticsType = null;
  if (nearestStore) {
    nearestStoreData = {
      ...nearestStoreData,
      nearest_store: nearestStore.name,
      distance_to_nearest_store: `${nearestStore.distance}`,
    };
  }

  return { ...sourceData, ...nearestStoreData };
};
