import bannerBackgroundImageDesktop from "assets/images/hero_banner.png";
import bannerBackgroundImageMobile from "assets/images/hero_banner_mobile.png";

import { locales } from "gyg_common";
import DeviceInfo from "gyg_common/utils/deviceInfo";

export interface ConfigSettings {
  api: ApiConfig;
  version: locales;
  googleMapsKey: string;
  googleMerchantId: string;
  googleMapsScriptId: string;
  googleRecaptchaSiteKey: string;
  locationConfig: LocationConfig;
  heroBannerBackgroundImage: {
    desktop: string;
    mobile: string;
  };
  googleMapsApiSDK: string;
  brandSiteUrl: string;
  allergenStatementSiteUrl: string;
  privacyPolicyUrl: string;
  termsAndConditionsUrl: string;
  contactUsUrl: string;
  jwtSecret: string;
  orderListLimit: number;
  braze: BrazeConfig;
  branchKey: string;
  appStoreLink: string;
  googlePlayLink: string;
  appDeepLink: string;
  showUpsell: boolean;
  analyticsDataSourceKey: string;
  analyticsAccount: string;
  analyticsProfile: string;
  analyticsEnvironment: string;
  packageVersion: string;
  sentry: SentryConfig;
  growthBook: GrowthbookConfig;
}

// Custom declaration for window to include Cypress type.
// As Cypress is only available in the window object
// when running in web app with cypress.
declare global {
  interface Window {
    Cypress: unknown;
  }
}

export interface ApiConfig {
  host: string;
  timeout: number;
}

export interface SentryConfig {
  dsn: string;
  env: string;
}

export interface BrazeConfig {
  apikey: string;
  endpoint: string;
}

export interface LocationConfig {
  nearestStoreThresholdKm: number;
}

export interface GrowthbookConfig {
  apiHost: string;
  clientKey: string;
}

const config: ConfigSettings = {
  api: {
    host: process.env.REACT_APP_BASE_URL || "",
    timeout: 20000,
  },
  braze: {
    apikey: process.env.REACT_APP_BRAZE_API_KEY || "",
    endpoint: process.env.REACT_APP_BRAZE_ENDPOINT || "",
  },
  branchKey: process.env.REACT_APP_BRANCH_KEY || "",
  locationConfig: {
    nearestStoreThresholdKm:
      parseInt(process.env.REACT_NEAREST_STORE_KM_THRESHOLD ?? "5") ?? 5,
  },
  heroBannerBackgroundImage: {
    desktop: bannerBackgroundImageDesktop,
    mobile: bannerBackgroundImageMobile,
  },
  version: (process.env.REACT_APP_VERSION as locales) || locales.AU,
  googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY as string,
  googleMerchantId: process.env.REACT_APP_GOOGLE_MERCHANT_ID as string,
  googleMapsScriptId: "__googleMapsScriptId",
  googleMapsApiSDK: process.env.REACT_APP_GOOGLE_MAPS_API_SDK as string,
  // Used test site_key as suggested by google reCaptcha doc
  // @see https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do
  googleRecaptchaSiteKey: window.Cypress
    ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
    : process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || "",
  brandSiteUrl: process.env.REACT_APP_BRAND_SITE_URL as string, // GOMEX
  termsAndConditionsUrl: process.env
    .REACT_APP_TERMS_AND_CONDITIONS_URL as string,
  privacyPolicyUrl: process.env.REACT_APP_PRIVACY_POLICY_URL as string,
  allergenStatementSiteUrl: process.env
    .REACT_APP_ALLERGEN_STATEMENT_SITE_URL as string,
  contactUsUrl: process.env.REACT_APP_CONTACT_US_URL as string,
  jwtSecret: process.env.REACT_APP_JWT_SECRET as string,
  orderListLimit: Number(String(process.env.REACT_ORDER_LIST_LIMIT)) as number,
  appStoreLink: process.env.REACT_APP_APP_STORE_LINK as string,
  googlePlayLink: process.env.REACT_APP_GOOGLE_PLAY_LINK as string,
  appDeepLink: process.env.REACT_APP_DEEP_LINK as string,
  showUpsell: true,
  analyticsEnvironment: process.env.REACT_APP_ANALYTICS_ENV as string,
  analyticsAccount: process.env.REACT_APP_ANALYTICS_ACCOUNT as string,
  analyticsProfile: process.env.REACT_APP_ANALYTICS_PROFILE as string,
  analyticsDataSourceKey: process.env.REACT_APP_ANALYTICS_KEY as string,
  packageVersion: DeviceInfo.getVersion(),
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN as string,
    env: process.env.REACT_APP_SENTRY_ENV as string,
  },
  growthBook: {
    apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST as string,
    clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY as string,
  },
};

const BANNER_BACKGROUND_IMAGE = config.heroBannerBackgroundImage;
const BASE_URL = config.api.host;
const VERSION = config.version;

export { BANNER_BACKGROUND_IMAGE, BASE_URL, VERSION };

export default config;
