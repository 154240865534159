import colours from "./colours";

const Typography = {
  largeTitle: {
    fontFamily: "Sini-Bold",
    lineHeight: 32,
    fontSize: 32,
    letterSpacing: 0,
    color: colours.darkGrey,
  },
  largeProductTitle: {
    fontFamily: "Sini-BoldItalic",
    lineHeight: 40,
    fontSize: 40,
    letterSpacing: 0,
    color: colours.darkGrey,
  },
  smallProductTitle: {
    fontFamily: "Sini-BoldItalic",
    lineHeight: 24,
    fontSize: 24,
    letterSpacing: 0.25,
    color: colours.darkGrey,
  },
  titleOne: {
    fontFamily: "Sini-Bold",
    lineHeight: 28,
    fontSize: 28,
    letterSpacing: 0,
    color: colours.darkGrey,
  },
  titleTwo: {
    fontFamily: "Sini-Bold",
    lineHeight: 24,
    fontSize: 24,
    letterSpacing: 0.25,
    color: colours.darkGrey,
  },
  titleThree: {
    fontFamily: "Sini-Bold",
    lineHeight: 20,
    fontSize: 18,
    letterSpacing: 0.25,
    color: colours.darkGrey,
  },
  titleFour: {
    fontFamily: "Sini-Bold",
    lineHeight: 20,
    fontSize: 20,
    letterSpacing: 0.25,
    color: colours.darkGrey,
  },
  headline: {
    fontFamily: "Montserrat-SemiBold",
    lineHeight: 24,
    fontSize: 16,
    letterSpacing: 0,
    color: colours.darkGrey,
  },
  body: {
    fontFamily: "Montserrat-Regular",
    lineHeight: 24,
    fontSize: 14,
    letterSpacing: 0.25,
    color: colours.darkGrey,
  },
  bodyBold: {
    fontFamily: "Montserrat-SemiBold",
    lineHeight: 24,
    fontSize: 14,
    letterSpacing: 0.25,
    color: colours.darkGrey,
  },
  bodyTwo: {
    fontFamily: "Montserrat-Regular",
    lineHeight: 20,
    fontSize: 13,
    letterSpacing: 0.25,
    color: colours.darkGrey,
  },
  bodyTwoBold: {
    fontFamily: "Montserrat-SemiBold",
    lineHeight: 20,
    fontSize: 13,
    letterSpacing: 0.25,
    color: colours.darkGrey,
  },
  captionOne: {
    fontFamily: "Montserrat-SemiBold",
    lineHeight: 16,
    fontSize: 13,
    letterSpacing: 0.25,
    color: colours.midGrey,
  },
  captionTwo: {
    fontFamily: "Montserrat-Regular",
    lineHeight: 16,
    fontSize: 13,
    letterSpacing: 0.25,
    color: colours.darkGrey,
  },
  viewCartBtnPrice: {
    fontSize: 16,
    fontFamily: "Montserrat-Bold",
    letterSpacing: 0.25,
    lineHeight: 24,
  },
  btnPrice: {
    fontSize: 14,
    fontFamily: "Montserrat-Bold",
    letterSpacing: 0.25,
    lineHeight: 24,
  },
  badgeText: {
    fontSize: 11,
    fontFamily: "Montserrat-Bold",
    letterSpacing: 0,
    lineHeight: 14,
  },
  linkText: {
    fontFamily: "Montserrat-Medium",
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: 24,
    color: colours.darkGrey,
  },
  get sectionTitle(): { [T: string]: string | number } {
    return {
      ...this.titleTwo,
      marginBottom: 16,
    };
  },
};

export { Typography };
