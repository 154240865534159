import Fuse from "fuse.js";
import { Store } from "../../redux_store/store/models";
import { StoreTagLabel } from "./models";
import { checkStoreOpenHours } from "./utils";

const FUZZY_SEARCH_MAX_LENGTH = 5;

export const storeFuzzySearch = (inputText: string, list: Store[]): Store[] => {
  const options = {
    isCaseSensitive: false,
    shouldSort: true,
    threshold: 0.3,
    tokenize: true,
    location: 0,
    distance: 0,
    maxPatternLength: 30,
    minMatchCharLength: 3,
    keys: ["name", "state", "city", "formattedStoreName", "postCode"],
  };
  const fuse = new Fuse(list, options);
  return fuse
    .search(inputText)
    .slice(0, FUZZY_SEARCH_MAX_LENGTH)
    .map((n) => n.item);
};

export const sortStringByAlphabetically = (list: string[]) => {
  return list.sort((a, b) => {
    if (a > b) {
      return 1;
    }
    return -1;
  });
};

export const filterStoreByTags = (
  tags: StoreTagLabel[],
  stores: Store[]
): Store[] => {
  if (tags.length <= 0) {
    return stores;
  }
  return stores.filter((s) => {
    for (const i in tags) {
      const tag = tags[i];
      if (tag === StoreTagLabel.OPEN_NOW) {
        const storeOpenTime = checkStoreOpenHours(
          s.tradingHours,
          s.timeZoneInfo.storeTimeZone
        );
        if (!storeOpenTime?.isOpen) {
          return false;
        }
      } else {
        if (s.tags.map((n) => n.tag).lastIndexOf(tag) === -1) {
          return false;
        }
      }
    }
    return true;
  });
};
