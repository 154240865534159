export enum EditMode {
  ADD_CART_ITEM = "ADD_CART_ITEM",
  MODIFY_CART_ITEM = "MODIFY_CART_ITEM",
  UPDATE_BUNDLE_ITEM = "UPDATE_BUNDLE_ITEM",
  UPDATE_TACO_ITEM = "UPDATE_TACO_ITEM",
  UPDATE_MIAM_ITEM = "UPDATE_MIAM_ITEM",
}

export enum ProductRoute {
  ComplexCustomisable = "ComplexCustomisable",
  ComplexCustomisableWithMeal = "ComplexCustomisableWithMeal",
  SimpleCustomisable = "SimpleCustomisable",
  Bundles = "Bundles",
  Tacos = "Tacos",
  NonCustomization = "NonCustomization",
  HotDrinks = "HotDrinks",
  MIAM = "MIAM",
  KidsBundle = "KidsTemplate",
}

export enum TestingId {
  CustomiseToggle = "SIMPLE_CUSTOMISABLE_PRODUCT_TOGGLE",
  OptionNutritionalInfo = "PRODUCT_NUTRITIONAL_INFO",
  SimpleCustomisableOption = "SIMPLE_COSTUMISABLE_RADIO_BUTTON_OPTION",
}

export enum TemplateId {
  HotDrinks = "Hot-Drinks-Template",
}

export enum ControlType {
  InvertedToggle = "InvertedToggle",
}

export enum SectionTitle {
  ExtraFilling = "extraFilling",
  ExtraModifier = "extras",
  RemoveModifier = "removeIngredient",
}

export const STICKY_HEADER_DISPLAY_VALUE = 350; // Y axis value to determine when to display sticky header
