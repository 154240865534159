import { ErrorResponse } from "../error/models";

export interface UserPoints {
  cardNumber: string;
  points: number;
  dollars: number;
}

export interface UserAccountRequest {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobile?: string;
  username?: string;
  postcode?: string;
  isVerifiedEmail?: boolean;
  isVerifiedMobile?: boolean;
  isActive?: boolean;
  createdInBraintree?: boolean;
  dateOfBirth?: string;
}

export interface IApiError {
  message: string;
  code: number;
}

export interface LocationObjectCoords {
  /**
   * The latitude in degrees.
   */
  latitude: number;
  /**
   * The longitude in degrees.
   */
  longitude: number;
}

export interface UserState {
  isLoading: boolean;
  isDeleteUserLoading: boolean;
  dataDogSessionId: string | null;
  profile: UserProfile | null;
  location: LocationObjectCoords | null;
  locationPermissionGranted: boolean;
  isUpdateUserSuccess: boolean;
  userLoyalty: UserLoyalty;
  isLocationPrecise: boolean;
  messageToast: IMessageToast | null;
  updatedPhoneNumber: string;
  postTermsAndConditionsLoading: boolean;
  postTermsAndConditionsError: IApiError | null;
  isMobileNumberUpdated: boolean;
  isEmailUpdated: boolean;
  isAccountDeleted: boolean;
  isTermsAndConditionsUpToDate: boolean;
  isPasswordUpdated: boolean;
  isAccountVerificationCodeSent: boolean;
  errorMessage: ErrorResponse;
  isBrazeInitialised: boolean;
  showMobileVerifyModal: boolean;
  existingUserMobileVerify: boolean;
  isVerifyEmailAddressSuccess: boolean;
  verifyEmailAddressLoading: boolean;
  verifyEmailAddressError: ErrorResponse;
  storeCard: boolean;
  isOffline: boolean;
}

export interface UserProfile {
  id: number;
  firstName: string;
  userName: string;
  lastName: string;
  phoneNumber: string;
  verifiedEmail: boolean;
  verifiedMobileNumber: boolean;
  email: string;
  dateOfBirth: string | null;
  cardNumber: string;
  updatedTCs: boolean;
  loyalty: {
    loyaltyRewardConversionLimit: number;
    loyaltyRewardAmount: number;
    actualPointsBalance: number;
    actualDollarsBalance: number;
  };
  activatedAuthenticationTypes: string[];
  authenticationSourceType: string;
  brazeId: string | null;
  accessToken: string;
  expiry: number;
  refreshToken: string;
  registrationComplete: string;
  referralLink: string;
  branchId?: string;
  storeCard?: boolean;
}

export interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
}

export interface SetPasswordRequest {
  password: string;
}

export interface ChangePasswordResponse {
  message: string;
}

export interface UserLoyalty {
  actualDollarsBalance: number;
  actualPointsBalance: number;
  cardNumber: string;
  loyaltyRewardAmount: number;
  loyaltyRewardConversionLimit: number;
}

export interface IMessageToast {
  title: string;
  showCloseButton: boolean;
  iconType?: "alert" | "heart" | "check";
}

export interface IPostTermsAndConditionsRequest {
  ipAddress?: string;
  tAndCId?: number;
}

export enum UpdatePasswordType {
  change,
  set,
}
