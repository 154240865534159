import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AllActionsOf } from "../../redux_store/utils/Actions";
import { CoffeeLoyalty, IRewards, Loyalty, LoyaltyState } from "./models";

export const initialState: LoyaltyState = {
  isLoading: false,
  loyalty: null,
  errorDesc: null,
  rewards: [],
  rewardsLoading: false,
  rewardsError: null,
  // stored locally and not obtained from an API
  activeReward: null,
  redeemDollars: null,
  //coffee loyalty info
  coffeeLoyaltyLoading: false,
  coffeeLoyalty: null,
};

const loyaltySlice = createSlice({
  name: "loyalty",
  initialState,
  reducers: {
    getUserLoyalty(state) {
      state.isLoading = true;
    },
    setUserLoyaltyToState(state, action: PayloadAction<Loyalty | null>) {
      state.isLoading = false;
      state.loyalty = action.payload;
    },
    error(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.errorDesc = action.payload;
    },
    getUserRewards(state) {
      state.rewardsLoading = true;
    },
    getUserRewardsSuccess(state, action: PayloadAction<IRewards[]>) {
      state.rewardsLoading = false;
      state.rewards = action.payload;
    },
    getUserRewardsError(state, action: PayloadAction<string>) {
      state.rewardsLoading = false;
      state.rewardsError = action.payload;
      state.rewards = [];
    },
    clearUserRewards(state) {
      state.rewards = [];
    },
    updateActiveReward(state, action: PayloadAction<IRewards>) {
      state.activeReward = action.payload;
    },
    clearActiveReward(state) {
      state.activeReward = null;
    },
    updateRedeemDollars(state, action: PayloadAction<number | null>) {
      state.redeemDollars = action.payload;
    },
    getUserCoffeeLoyalty(state, action: PayloadAction<string | undefined>) {
      state.coffeeLoyaltyLoading = true;
    },
    setUserCoffeeLoyalty(state, action: PayloadAction<CoffeeLoyalty>) {
      state.coffeeLoyaltyLoading = false;
      state.coffeeLoyalty = action.payload;
    },
    getUserCoffeeLoyaltyError(state) {
      state.coffeeLoyaltyLoading = false;
    },
  },
});

const { actions, reducer } = loyaltySlice;
export type LoyaltyActionType = AllActionsOf<typeof actions>;
export {
  actions as loyaltyActions,
  initialState as loyaltyInitialState,
  reducer as loyaltyReducer,
};
