import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import config from "../../config";
import translationAU from "../../locales/Error/au.json";
import translationUS from "../../locales/Error/us.json";
import { locales } from "../../locales/locales.enum";
import { AllActionsOf } from "../utils/Actions";
import { ErrorResponse } from "./models";

const initialState: ErrorResponse = {
  statusCode: null,
  message: null,
  title: null,
  heading: null,
  isTimeout: false,
};

const errorSlice = createSlice({
  name: "Error",
  initialState: initialState,
  reducers: {
    setErrorAPIResponse(state, action: PayloadAction<ErrorResponse | null>) {
      if (action.payload) {
        const heading = action.payload.heading;

        state.title = action.payload.title;
        state.message = action.payload.message;
        state.statusCode = action.payload.statusCode;
        state.heading = heading
          ? heading
          : config.version === locales.AU
          ? translationAU.defaultErrorHeading
          : translationUS.defaultErrorHeading;
        state.isTimeout = action.payload.isTimeout;
      } else {
        state.isTimeout = true;
        state.heading = translationAU.noResponseError;
      }
    },
    clearError(state) {
      state.statusCode = null;
      state.message = null;
      state.title = null;
      state.heading = null;
      state.isTimeout = false;
    },
  },
});

const { actions, reducer } = errorSlice;
export type ErrorActionType = AllActionsOf<typeof actions>;
export { actions as errorActions, reducer as errorReducer, initialState };
