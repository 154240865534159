import { AnalyticsType } from "./model";
import { getProductCategory } from "../Products/utils";
import { AddFavouriteRequest } from "../../redux_store/favourite/models";
import { MenuSection } from "../../redux_store/menu/models";

export const favouriteOrderPayload = (
  payload: AddFavouriteRequest,
  source: string,
  storeId: number,
  storeName: string,
  menuSection: MenuSection | undefined
): AnalyticsType => {
  return {
    product_Id: [payload.basketItems[0].id],
    product_name: [payload.label],
    product_category: [
      getProductCategory(payload.basketItems[0].id, menuSection),
    ],
    product_quantity: [1],
    store_name: storeName,
    store_id: storeId,
    source: source,
    custom_name: payload.label,
  };
};
