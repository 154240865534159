import React from "react";
import {
  Image,
  ImageSourcePropType,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";

import * as PlatformUtils from "../../modules/platformUtils";
import colours from "../styles/colours";
import { BorderRadius, Spacing } from "../styles/number";
import { shadowTextstyle } from "../styles/shared";
import { Typography } from "../styles/typography";

export interface TertiaryButtonProps {
  title: string;
  icon?: ImageSourcePropType;
  disabled?: boolean;
  testId?: string;
  bgColor?: "white" | "grey" | "yellow" | "transparent";
  borderColor?: "white" | "grey" | "yellow";
  numberOfLines?: number;
  onClick: () => void;
}

interface StyleProps {
  hovered?: boolean;
  pressed?: boolean;
  bgColour?: string;
  pressedColour?: string;
  borderColor: string | undefined;
  icon: ImageSourcePropType | undefined;
  disabled?: boolean;
}

const style = (props: StyleProps) =>
  StyleSheet.create({
    wrapper: {
      flex: 1,
      borderRadius: BorderRadius.Medium,
      opacity: props?.disabled ? 0.5 : 1,
      backgroundColor: props.pressed
        ? props.pressedColour
        : props.hovered
        ? colours.starburstOpacity
        : props.bgColour,
      ...(props?.borderColor ? { borderColor: props.borderColor } : {}),
      borderWidth: props?.borderColor ? 2 : 0,
    },
    container: {
      paddingVertical: props.icon ? Spacing.Thin : Spacing.ExtraLight,
      paddingHorizontal: Spacing.Light,
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      alignSelf: "center",
      borderRadius: BorderRadius.Medium,
      justifyContent: "space-between",
    },
    title: {
      ...Typography.titleThree,
      textTransform: "uppercase",
      justifyContent: "center",
      textAlign: "center",
    },
    icon: {
      width: 24,
      height: 24,
      marginRight: Spacing.ExtraThin,
    },
  });

const TertiaryButton: React.FC<TertiaryButtonProps> = ({
  title,
  onClick,
  disabled,
  icon,
  bgColor = "grey",
  testId,
  borderColor,
  numberOfLines,
}) => {
  const getColor = (color: string): string => {
    switch (color) {
      case "white":
        return colours.white;
      case "yellow":
        return colours.yellow;
      case "grey":
        return colours.lightestGrey;
      case "transparent":
        return color;
      default:
        return colours.lightestGrey;
    }
  };

  const bgColour = getColor(bgColor);
  const formattedBorderColor = borderColor ? getColor(borderColor) : undefined;

  const pressedColour: string = colours.starburst;

  const styleProps = {
    borderColor: formattedBorderColor,
    bgColour,
    pressedColour,
    icon,
    disabled,
  };

  return (
    <Pressable
      {...PlatformUtils.generateTestID(
        Platform.OS,
        testId ? testId : "tertiary-button"
      )}
      disabled={disabled}
      onPress={() => {
        if (!!disabled) return;
        onClick();
      }}
      style={({ hovered, pressed }) =>
        style({
          hovered,
          pressed,
          ...styleProps,
        }).wrapper
      }>
      <View style={style(styleProps).container}>
        {icon && <Image source={icon} style={style(styleProps).icon} />}
        <Text
          numberOfLines={numberOfLines}
          style={[style(styleProps).title, shadowTextstyle(1).shadow]}>
          {title}
        </Text>
      </View>
    </Pressable>
  );
};

export default TertiaryButton;
