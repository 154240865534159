import React from "react";
import { Platform, StyleSheet, Text, View } from "react-native";

import * as PlatformUtils from "../../modules/platformUtils";
import * as StoreReduxModels from "../../redux_store/store/models";
import StoreInfoButton from "../Stores/StoreMoreInfoButton";
import colours from "../styles/colours";
import { Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: Spacing.Thin,
  },
  distanceText: {
    ...Typography.bodyTwoBold,
    color: colours.midGrey,
    letterSpacing: 0.27,
    lineHeight: 16,
  },
});

export interface StoreHeadProps {
  store: StoreReduxModels.Store;
  showInfoBtn?: boolean;
  showDistanceText?: boolean;
  onPressInfo?: () => void;
}

const StoreHead: React.FC<StoreHeadProps> = ({
  showInfoBtn,
  store,
  onPressInfo,
  showDistanceText,
}) => {
  return (
    <View>
      <Text
        {...PlatformUtils.generateTestID(Platform.OS, "StoreDetailHeaderTitle")}
        style={[Typography.titleTwo, { textTransform: "uppercase" }]}>
        {store.name}
      </Text>
      {(showInfoBtn || (store.distanceText && showDistanceText)) && (
        <View style={styles.row}>
          {showInfoBtn && (
            <StoreInfoButton store={store} onPressAction={onPressInfo} />
          )}
          {store.distanceText != undefined && (
            <Text style={styles.distanceText}>{store.distanceText}</Text>
          )}
        </View>
      )}
    </View>
  );
};

export default StoreHead;
