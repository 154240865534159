import GYGMapPinDefault from "assets/images/brand_map_pin_small.png";
import React, { memo } from "react";

import { StoreModules } from "gyg_common";
import GYGMapPin from "gyg_common/components/assets/images/pin_big_yellow.png";

interface MarkerProps {
  lat: number;
  lng: number;
  id: number;
  selected: boolean;
}

const Marker: React.FC<MarkerProps> = ({ selected, id }) => {
  return (
    <div
      key={id}
      className={`restaurants__map-marker ${
        selected && "restaurants__map-marker--selected"
      }`}
      data-testid={`${StoreModules.StoreModels.StoresTestingId.mapPin}_${id}`}>
      <img src={selected ? GYGMapPin : GYGMapPinDefault} />
    </div>
  );
};

export default memo(Marker);
