import React from "react";
import { Text } from "react-native";

import * as ApiStoreModel from "../../services/api/store/model";
import { Typography } from "../styles/typography";

export interface StoreAddressProps {
  address1: string;
  address2: string;
  city: string;
  postCode: string;
  state: ApiStoreModel.StateAU | ApiStoreModel.StateUS;
}

const StoreAddress: React.FC<StoreAddressProps> = ({
  address1,
  address2,
  city,
  postCode,
  state,
}) => {
  return (
    <Text style={Typography.bodyTwo}>
      {address1}
      {address2 ? ` ${address2}` : null}
      {city || state || postCode ? `, ${city} ${state} ${postCode}` : null}
    </Text>
  );
};

export default StoreAddress;
