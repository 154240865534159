import React from "react";
import { StyleSheet, Text, View } from "react-native";

import * as StoreReduxModels from "../../redux_store/store/models";
import { Typography } from "../styles/typography";
import OpenClosed from "./OpenClosed";

const styles = StyleSheet.create({
  storeStatus: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  text: {
    ...Typography.bodyTwo,
    alignItems: "center",
    lineHeight: 19,
    flexWrap: "wrap",
  },
});

const StoreStatus: React.FC<StoreReduxModels.StoreOpenTime> = (
  props: StoreReduxModels.StoreOpenTime
) => {
  const { isOpen, tradingTimeInfo, closingSoon } = props;
  return (
    <View style={styles.storeStatus}>
      <OpenClosed open={isOpen} closingSoon={closingSoon} />
      <Text style={styles.text}>{tradingTimeInfo}</Text>
    </View>
  );
};

export default StoreStatus;
