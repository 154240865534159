export const formatCardNumber = (cardNumber: string): string => {
  return cardNumber
    .replace(/[^\dA-Za-z]/g, "")
    .replace(/(.{4})/g, "$1 ")
    .trim();
};

export const formatLoyaltyDollars = (dollars: number): string =>
  dollars > 0 ? dollars.toFixed(2).replace(/[.,]00$/, "") : "0";

export interface GomexPointsAndDollars {
  points: number;
  dollars: number;
}
