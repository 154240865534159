import { Platform } from "react-native";
import DeviceInfo from "react-native-device-info";

const getVersion = (): string => {
  try {
    if (Platform.OS === "web") {
      return (process.env.REACT_APP_PACKAGE_VERSION as string) || "0.1.0";
    } else {
      return DeviceInfo.getVersion();
    }
  } catch (e) {
    return "";
  }
};

const getDeviceId = (): string => {
  try {
    return DeviceInfo.getDeviceId();
  } catch (e) {
    return "";
  }
};
const getBundleId = (): string => {
  try {
    return DeviceInfo.getBundleId();
  } catch (e) {
    return "";
  }
};

const getBuildNumber = (): string => {
  try {
    return DeviceInfo.getBuildNumber();
  } catch (e) {
    return "";
  }
};

const getUniqueId = (): Promise<string> => {
  try {
    return DeviceInfo.getUniqueId();
  } catch (e) {
    return Promise.resolve("");
  }
};

const getUserAgent = (): Promise<string> => {
  try {
    return DeviceInfo.getUserAgent();
  } catch (e) {
    return Promise.resolve("");
  }
};

export default {
  getDeviceId,
  getBundleId,
  getBuildNumber,
  getVersion,
  getUniqueId,
  getUserAgent,
};
