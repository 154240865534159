import { SocialProps, CurrentSocialResponse, SocialState } from "./models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AllActionsOf } from "../utils/Actions";
import { ErrorResponse } from "../error/models";

const initialState: SocialState = {
  socialCode: undefined,
  isSocialRegister: false,
  isLoading: false,
  currentSocialUser: undefined,
  navigatesTo: undefined,
  isError: undefined,
};

const socialSlice = createSlice({
  name: "Social",
  initialState: initialState,
  reducers: {
    addSocialCode(state, action: PayloadAction<string | string[]>) {
      state.socialCode = action.payload;
    },
    getSocialSignIn(state, action: PayloadAction<SocialProps>) {
      state.isLoading = true;
      state.isError = undefined;
    },
    getSocialSignInSuccess(
      state,
      action: PayloadAction<CurrentSocialResponse>
    ) {
      state.isLoading = false;
      state.currentSocialUser = action.payload;
      state.isError = undefined;
    },
    setSocialRegister(state, action: PayloadAction<boolean>) {
      state.isSocialRegister = action.payload;
    },
    setNavigatesTo(state, action: PayloadAction<string | undefined>) {
      state.navigatesTo = action.payload;
    },
    error(state, action: PayloadAction<ErrorResponse>) {
      state.isSocialRegister = false;
      state.isLoading = false;
      state.isError = action.payload;
    },
    clearSocialState(state) {
      state.isSocialRegister = false;
      state.isLoading = false;
      state.currentSocialUser = undefined;
      state.navigatesTo = undefined;
      state.isError = undefined;
    },
  },
});

const { actions, reducer } = socialSlice;
export type socialActionsType = AllActionsOf<typeof actions>;
export {
  actions as socialActions,
  reducer as socialReducer,
  initialState as socialInitialState,
};
