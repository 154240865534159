/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Effects from "redux-saga/effects";
import { fork, put, takeLatest } from "redux-saga/effects";
import { BrazeSubscriptionGroup, NotificationsCenterSuccess } from "./models";
import * as ApiService from "../../services/api/notificationsCenter";
import {
  notificationsCenterActions,
  NotificationsCenterActionsType,
} from "./notificationsCenter.slice";
import { errorActions } from "../error/error.slice";
import { ErrorResponse } from "../error/models";

const call: any = Effects.call;

//HANDLING FUNCTION STARTS HERE
export function* handleGetNotificationsCenter(
  action: NotificationsCenterActionsType
): Generator<any, void, BrazeSubscriptionGroup[]> {
  try {
    const result: BrazeSubscriptionGroup[] = yield call(
      ApiService.getNotificationsCenter,
      action.payload
    );
    yield put(notificationsCenterActions.getNotificationsCenterSuccess(result));
  } catch (e) {
    yield put(notificationsCenterActions.getNotificationsCenterError());
    const errorResponse: ErrorResponse = {
      ...e.response?.data,
      statusCode: e.response?.status,
    };
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* handleUpdateNotificationsCenter(
  action: NotificationsCenterActionsType
): Generator<any, void, NotificationsCenterSuccess> {
  try {
    yield call(ApiService.postNotificationsCenter, action.payload);
    yield put(
      notificationsCenterActions.updateNotificationsCenterStatusSuccess()
    );
  } catch (e) {
    const errorResponse: ErrorResponse = {
      ...e.response?.data,
      statusCode: e.response?.status,
    };
    yield put(errorActions.setErrorAPIResponse(errorResponse));
    yield put(
      notificationsCenterActions.updateNotificationsCenterStatusError(
        e.response.data
      )
    );
  }
}

//WATCH FUNCTIONS STARTS HERE
export function* watchGetNotificationsCenter(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    notificationsCenterActions.getNotificationsCenter.type,
    handleGetNotificationsCenter
  );
}

export function* watchUpdateNotificationsCenter(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    notificationsCenterActions.updateNotificationsCenter.type,
    handleUpdateNotificationsCenter
  );
}

const saga = [
  fork(watchGetNotificationsCenter),
  fork(watchUpdateNotificationsCenter),
];

export default saga;
