import { ActionFromReducer, combineReducers, Dispatch } from "redux";

import { analyticsReducer } from "./analytics/analytics.slice";
import { authReducer } from "./auth/auth.slice";
import { braintreeReducer } from "./braintree/braintree.slice";
import { branchReducer } from "./branch/branch.slice";
import { cartReducer } from "./cart/cart.slice";
import { checkoutReducer } from "./checkout/checkout.slice";
import { dashboardReducer } from "./dashboard/dashboard.slice";
import { errorReducer } from "./error/error.slice";
import { favouriteReducer } from "./favourite/favourite.slice";
import { guestReducer } from "./guest/guest.slice";
import { loyaltyReducer } from "./loyalty/loyalty.slice";
import { menuReducer } from "./menu/menu.slice";
import { navigationReducer } from "./navigation/navigation.slice";
import { notificationsCenterReducer } from "./notificationsCenter/notificationsCenter.slice";
import { orderReducer } from "./order/order.slice";
import { promoReducer } from "./promo/promo.slice";
import { registrationReducer } from "./registration/registration.slice";
import { registrationDetailsReducer } from "./registration/registrationDetails.slice";
import { socialReducer } from "./social/social.slice";
import { storeReducer } from "./store/store.slice";
import { storybookReducer } from "./storybook/storybook.slice";
import { upsellReducer } from "./upsell/upsell.slice";
import { userReducer } from "./user/user.slice";

export type RootState = ReturnType<typeof rootReducer>;
export type ReduxAction = ActionFromReducer<typeof rootReducer>;
export type RootDispatch = Dispatch<ReduxAction>;

const rootReducer = combineReducers({
  user: userReducer,
  order: orderReducer,
  store: storeReducer,
  menu: menuReducer,
  guest: guestReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
  registration: registrationReducer,
  registrationDetails: registrationDetailsReducer,
  login: authReducer,
  favourite: favouriteReducer,
  analytics: analyticsReducer,
  loyalty: loyaltyReducer,
  social: socialReducer,
  dashboard: dashboardReducer,
  branch: branchReducer,
  promo: promoReducer,
  upsell: upsellReducer,
  braintree: braintreeReducer,
  notificationsCenter: notificationsCenterReducer,
  error: errorReducer,
  storybook: storybookReducer,
  navigation: navigationReducer,
});

export default rootReducer;
