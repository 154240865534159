import React from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, Text, View } from "react-native";
import { BasketItem } from "services/api/order/model";

import * as PlatformUtils from "../modules/platformUtils";
import { ProductDiscountsApplied } from "../redux_store/cart/models";
import * as CheckoutModels from "../redux_store/checkout/model";
import colours from "./styles/colours";
import { appTableStyle, webTableStyle } from "./styles/shared";
import { Typography } from "./styles/typography";

export interface ReceiptTableProps {
  data: CheckoutModels.CheckoutItems[] | BasketItem[];
}

const tableStyle = Platform.OS === "web" ? webTableStyle : appTableStyle;

const style = StyleSheet.create({
  discountText: {
    ...Typography.titleThree,
    color: colours.midGrey,
  },
});
const TableRow = (
  product: string,
  qty: number,
  subTotal: number,
  id: number,
  index: number,
  productDiscountsApplied?: ProductDiscountsApplied[]
) => {
  return (
    <View style={tableStyle.bodyRow} key={`${id}-${index}`}>
      <View style={tableStyle.colProduct}>
        <Text
          style={tableStyle.bodyCol}
          {...PlatformUtils.generateTestID(Platform.OS, "ItemName")}>
          {product}
        </Text>
        {productDiscountsApplied?.map((discount) => {
          return (
            <Text
              key={discount.receiptDisplayText}
              style={style.discountText}
              {...PlatformUtils.generateTestID(Platform.OS, "ItemDiscount")}>
              {discount.receiptDisplayText}
            </Text>
          );
        })}
      </View>
      <View style={tableStyle.colQty}>
        <Text
          style={tableStyle.bodyCol}
          {...PlatformUtils.generateTestID(Platform.OS, "ItemQuantity")}>
          {qty}
        </Text>
      </View>
      <View style={tableStyle.colPrice}>
        <Text
          style={tableStyle.bodyCol}
          {...PlatformUtils.generateTestID(Platform.OS, "ItemPrice")}>
          ${subTotal?.toFixed(2)}
        </Text>
      </View>
    </View>
  );
};

/**
 * Displays table with products details (name, quantity and price) in the receipt.
 * @param props products
 */
const ReceiptTable: React.FC<ReceiptTableProps> = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  return (
    <View>
      <View style={tableStyle.header}>
        <View style={tableStyle.colProduct}>
          <Text style={tableStyle.headerCol}>
            {t("OrderStatus:receiptHeaderCol1")}
          </Text>
        </View>
        <View style={tableStyle.colQty}>
          <Text style={tableStyle.headerCol}>
            {t("OrderStatus:receiptHeaderCol2")}
          </Text>
        </View>
        <View style={tableStyle.colPrice}>
          <Text style={tableStyle.headerCol}>
            {t("OrderStatus:receiptHeaderCol3")}
          </Text>
        </View>
      </View>
      {data.map((product: CheckoutModels.CheckoutItems, index) => {
        return TableRow(
          product.name,
          product.quantity,
          product.price,
          product.id,
          index,
          product.productDiscountsApplied
        );
      })}
    </View>
  );
};

export default ReceiptTable;
