import { locales } from "../../locales/locales.enum";
import orderManagementAU from "../../locales/OrderManagement/au.json";
import orderManagementUS from "../../locales/OrderManagement/us.json";
import { InternalTagType, TagType } from "../../redux_store/menu/models";
import { SectionTitle } from "./const";

export interface CustomisationMapper {
  [key: string]: {
    title: string;
    displayOrder: number;
    displayImage: boolean;
    displayPrice: boolean;
    displayNutritionalInfo: boolean;
  };
}
export interface CustomisationOptionsMapper {
  [key: string]: {
    key: string;
    title: string;
    subTitle?: string;
    displayOrder: number;
    isMultiple?: boolean;
    footerTitle?: string;
    maximumSelection?: number;
  };
}
export const productCustomizationList: string[] = [
  "addFilling",
  "addModifier",
  "extraModifier",
  "removeModifier",
  "extraFilling",
];
export const HOT_DRINKS_MODIFIERS: string[] = [
  "extraModifier",
  "removeModifier",
];
export const RETRIEVE_MODE = {
  ADD: "ADD", // addFilling and addModifiers
  EXTRAS: "EXTRAS", // extraFilling and extraModifiers
};
export const productCustomizationKeyMapper: { [key: string]: string } = {
  addFilling: SectionTitle.ExtraFilling,
  addModifier: SectionTitle.ExtraModifier,
  extraModifier: SectionTitle.ExtraModifier,
  removeModifier: SectionTitle.RemoveModifier,
  extraFilling: SectionTitle.ExtraFilling,
};

export const addCustomisationPrefix = (key: string, isSub: boolean): string => {
  const isExtra = key.includes("extra");
  const basicPrefix = isExtra ? "Extra" :  isSub ? "Sub" : "Add";
  const prefix = key === "removeModifier" ? "No" : basicPrefix;
  return prefix;
};

export const getCustomisationOptionMapper = (
  locale: locales
): CustomisationOptionsMapper => {
  return {
    extraModifier: {
      key: productCustomizationKeyMapper.extraModifier,
      title:
        locale === locales.AU
          ? orderManagementAU.addExtraSection
          : orderManagementUS.addExtraSection,
      subTitle:
        locale === locales.AU
          ? orderManagementAU.add6ExtraSection
          : orderManagementUS.add6ExtraSection,
      displayOrder: 3,
      isMultiple: true,
      footerTitle: "EXTRAS",
      maximumSelection: 6,
    },
    addModifier: {
      key: productCustomizationKeyMapper.addModifier,
      title:
        locale === locales.AU
          ? orderManagementAU.addExtraSection
          : orderManagementUS.addExtraSection,
      subTitle:
        locale === locales.AU
          ? orderManagementAU.add6ExtraSection
          : orderManagementUS.add6ExtraSection,
      displayOrder: 3,
      isMultiple: true,
      footerTitle: "EXTRAS",
      maximumSelection: 6,
    },
    addFilling: {
      key: productCustomizationKeyMapper.addFilling,
      title:
        locale === locales.AU
          ? orderManagementAU.addExtraFillingSection
          : orderManagementUS.addExtraFillingSection,
      subTitle:
        locale === locales.AU
          ? orderManagementAU.add2ExtraSection
          : orderManagementUS.add2ExtraSection,
      displayOrder: 4,
      isMultiple: true,
      footerTitle: "EXTRA FILLINGS",
      maximumSelection: 2,
    },
    extraFilling: {
      key: productCustomizationKeyMapper.extraFilling,
      title:
        locale === locales.AU
          ? orderManagementAU.addExtraFillingSection
          : orderManagementUS.addExtraFillingSection,
      subTitle:
        locale === locales.AU
          ? orderManagementAU.add2ExtraSection
          : orderManagementUS.add2ExtraSection,
      displayOrder: 4,
      isMultiple: true,
      footerTitle: "EXTRA FILLINGS",
      maximumSelection: 2,
    },
    removeModifier: {
      key: productCustomizationKeyMapper.removeModifier,
      title:
        locale === locales.AU
          ? orderManagementAU.removeIngredientSection
          : orderManagementUS.removeIngredientSection,
      displayOrder: 5,
      isMultiple: true,
      footerTitle: "REMOVE INGREDIENT",
    },
  };
};
export const getCustomisationMapper = (
  locale: locales
): CustomisationMapper => {
  const localeData =
    locale === locales.AU ? orderManagementAU : orderManagementUS;

  return {
    [InternalTagType.MIAM]: {
      title: localeData.selectMiamItem,
      displayOrder: 1,
      displayImage: true,
      displayPrice: true,
      displayNutritionalInfo: true,
    },
    [TagType.Filling]: {
      title: localeData.mainFillingSection,
      displayOrder: 2,
      displayImage: true,
      displayPrice: true,
      displayNutritionalInfo: true,
    },
    [TagType.Size]: {
      title: localeData.sizeSection,
      displayOrder: 3,
      displayImage: false,
      displayPrice: true,
      displayNutritionalInfo: false,
    },
    [TagType.SpiceLevel]: {
      title: localeData.spiceLevelSection,
      displayOrder: 4,
      displayImage: false,
      displayPrice: false,
      displayNutritionalInfo: false,
    },
    [TagType.TacoType]: {
      title: localeData.tacoTypeSection,
      displayOrder: 0,
      displayImage: false,
      displayPrice: false,
      displayNutritionalInfo: false,
    },
    [TagType.ProductQuantity]: {
      title: localeData.productQuantitySection,
      displayOrder: 0,
      displayImage: false,
      displayPrice: true,
      displayNutritionalInfo: false,
    },
  };
};
