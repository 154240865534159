import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";

import { formatUserDataPayload } from "../../modules/User/utils";
import { userActions } from "../../redux_store/user/user.slice";
import {
  getVerificationCode,
  registerSocialUser,
  registerUser,
  verifyCode,
} from "../../services/api/registration";
import {
  RegistrationPayload,
  SocialRegistrationPayload,
  VerificationCodePayload,
  VerifyCodePayload,
  VerifyResponse,
} from "../../services/api/registration/model";
import { authActions } from "../auth/auth.slice";
import { CurrentUser } from "../auth/models";
import { buildErrorResponse } from "../error/utils";
import { socialActions } from "../social/social.slice";
import {
  RegistrationAction,
  RegistrationActionType,
} from "./registration.slice";
import { RegistrationDetailsAction } from "./registrationDetails.slice";

function* handleGetVerificationCode(action: RegistrationActionType) {
  try {
    const result: VerifyResponse = yield call(
      getVerificationCode,
      action.payload as VerificationCodePayload
    );
    yield put(RegistrationAction.getVerificationCodeSuccess(result));
  } catch (e) {
    yield put(
      RegistrationAction.getVerificationCodeError(buildErrorResponse(e))
    );
  }
}

export function* watchGetVerificationCode(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    RegistrationAction.getVerificationCode.type,
    handleGetVerificationCode
  );
}

function* handleVerifyCode(action: RegistrationActionType) {
  try {
    const result: VerifyResponse = yield call(
      verifyCode,
      action.payload as VerifyCodePayload
    );
    yield put(RegistrationAction.verifyCodeSuccess(result));
    yield put(userActions.setShowMobileVerifyModal(false));
  } catch (e) {
    yield put(RegistrationAction.registerError(e.response.data));
    yield put(RegistrationAction.verifyCodeError(e.response.data));
  }
}

export function* watchVerifyCode(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(RegistrationAction.verifyCode.type, handleVerifyCode);
}

function* handleRegisterUser(action: RegistrationActionType) {
  try {
    const payload = formatUserDataPayload(
      action.payload as RegistrationPayload
    );
    const result: CurrentUser = yield call(
      registerUser,
      payload as RegistrationPayload
    );
    yield put(authActions.registerSuccess(result));
    yield put(RegistrationDetailsAction.clearUserDetails());
    yield put(authActions.clearError());
  } catch (e) {
    yield put(RegistrationAction.registerError(buildErrorResponse(e)));
  }
}

export function* watchRegisterUser(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(RegistrationAction.registerUser.type, handleRegisterUser);
}

function* handleRegisterSocialUser(action: RegistrationActionType) {
  try {
    const result: CurrentUser = yield call(
      registerSocialUser,
      action.payload as SocialRegistrationPayload
    );
    yield put(RegistrationAction.registerSocialUserSuccess());
    yield put(authActions.registerSuccess(result));
    yield put(socialActions.setSocialRegister(false));
  } catch (e) {
    yield put(RegistrationAction.registerError(buildErrorResponse(e)));
  }
}

export function* watchRegisterSocialUser(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    RegistrationAction.registerSocialUser.type,
    handleRegisterSocialUser
  );
}

const saga = [
  fork(watchGetVerificationCode),
  fork(watchVerifyCode),
  fork(watchRegisterUser),
  fork(watchRegisterSocialUser),
];

export default saga;
