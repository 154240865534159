import "react-native";
import React from "react";
import { StyleSheet, Text, Image, View, Platform } from "react-native";
import { Typography } from "../styles/typography";
import { Spacing } from "../styles/number";
import * as PlatformUtils from "../../modules/platformUtils";
import * as StoreReduxModels from "../../redux_store/store/models";
import { getServiceIconUrl } from "../../utils";

const styles = StyleSheet.create({
  service: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: Spacing.Narrow,
  },
  serviceIcon: {
    height: 24,
    width: 24,
    marginRight: Spacing.Thin,
  },
  placeholder: {
    marginRight: Spacing.Medium,
  },
  serviceText: {
    ...Typography.bodyBold,
    textTransform: "capitalize",
  },
});

export interface ServicesListProps {
  tags: StoreReduxModels.StoreTag[];
}

const ServicesList: React.FC<ServicesListProps> = ({ tags }) => {
  return (
    <>
      {tags?.map(({ tag }, i: number) => {
        const iconUrl = getServiceIconUrl(tag);
        return (
          <View
            style={styles.service}
            key={i}
            {...PlatformUtils.generateTestID(Platform.OS, "servicesList")}>
            {!!iconUrl && <Image style={styles.serviceIcon} source={iconUrl} />}
            {!iconUrl && <Text style={styles.placeholder} />}
            <Text style={styles.serviceText}>{tag}</Text>
          </View>
        );
      })}
    </>
  );
};

export default ServicesList;
