import { store } from "redux_store/configureReduxStore";

import { UserReduxAction } from "gyg_common";

/** Check if geolocation is availble in the browser */
export const isGeolocationAvailable = (): boolean => {
  if ("geolocation" in navigator) {
    return true;
  } else {
    return false;
  }
};
export const configureLocationProvider: PositionOptions = {
  enableHighAccuracy: true,
  timeout: 10000,
  maximumAge: 5000,
};

/** Get the user's location using the geolocation API.
 */
export const getUserLocation = (): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => {
    if (isGeolocationAvailable()) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          resolve(position);
        },
        (error) => {
          reject(error);
        },
        configureLocationProvider
      );
    }
  });
};

/** Check the user location permission.
 * 'granted' - user has allowed the browser to provide location info
 * 'prompt' - ask the user to allow the lcoation on next call to get location
 * 'denied' - user has denied permission for location services in the browser
 */
export const getCurrentLocationPermission = (): Promise<
  "granted" | "prompt" | "denied"
> => {
  return new Promise((resolve, reject) => {
    if (navigator.permissions) {
      navigator.permissions
        .query({
          name: "geolocation",
        })
        .then((permission: PermissionStatus) => {
          if (permission) {
            store.dispatch(
              UserReduxAction.setLocationPermission(
                permission.state === "granted"
              )
            );
          }
          resolve(permission.state);
        })
        .catch((err) => reject(err));
    } else {
      getUserLocation()
        .then(() => {
          console.log("get location success");
          store.dispatch(UserReduxAction.setLocationPermission(true));
          resolve("granted");
        })
        .catch(() => {
          console.log("get location failed");
          store.dispatch(UserReduxAction.setLocationPermission(false));
          reject("denied");
        });
    }
  });
};

export const requestLocationPermission = async () => {
  try {
    const location = await getUserLocation();

    store.dispatch(UserReduxAction.setLocationPermission(true));
    return {
      permissionGranted: true,
      userLocation: location,
    };
  } catch (err) {
    store.dispatch(UserReduxAction.setLocationPermission(false));
    return {
      permissionGranted: false,
      userLocation: undefined,
    };
  }
};
