import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  loaderStyle: { width: 34, height: 34, position: "absolute" },
  webLoader: {
    width: "32px",
    height: "32px",
    position: "absolute",
  },
});

export default styles;
