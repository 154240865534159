import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { useSelector } from "react-redux";

import config, { BASE_URL } from "config";
import { RootState } from "gyg_common";
import { SearchTextInput } from "gyg_common/components";
import deliveryWithUber from "gyg_common/components/assets/icons/services/delivery_with_uber_icon.png";
import navigateIcon from "gyg_common/components/assets/icons/services/navigate_icon.png";
import questionImg from "gyg_common/components/assets/images/illustration_error_question.png";
import Divider from "gyg_common/components/BaseComponents/Divider";
import ModalWithButton from "gyg_common/components/modals/ModalWithButton";
import RecentAddressView, {
  RecentAddress,
} from "gyg_common/components/OrderSetup/DeliveryRecentAddress";
import { SetupScreenComponent } from "gyg_common/components/OrderSetup/OrderSetupFlowContent";
import { Spacing } from "gyg_common/components/styles/number";
import { DeliveryModalState } from "gyg_common/redux_store/order/models";
import { getRecentAddresses } from "gyg_common/services/api/order";
import {
  getCurrentLocationPermission,
  getUserLocation,
  requestLocationPermission,
} from "modules/location";
import { Typography } from "styles/typography";

import { LocationPermissionModal } from "../Location/LocationPermissionModal";
import { FlyingBurritoLoader } from "../shared/Loading/FlyingBurritoLoader/FlyingBurritoLoader";

import colours from "@/styles/colours";

const GOOGLE_IP_ADDRESS_GEOCODE_URL =
  "https://maps.googleapis.com/maps/api/geocode/json?address=";

const styles = StyleSheet.create({
  container: { paddingHorizontal: Spacing.Regular },
  deliveryWithUberContainer: { width: "100%", alignItems: "center" },
  deliveryWithUberIcon: { height: 60, width: 150 },
  navigateIcon: {
    height: Spacing.Light,
    width: Spacing.Regular,
    marginRight: Spacing.Thin,
  },
  loadingContainer: {
    margin: Spacing.MediumThick,
    display: "flex",
    justifyContent: "center",
  },
  useMyLocationText: { ...Typography.body, color: colours.black },
  recentAddressesText: { ...Typography.bodyBold, color: colours.black },
  recentAddressesContainer: { paddingVertical: Spacing.Regular },
  resultsContainer: {
    position: "absolute",
    left: Spacing.Regular,
    right: Spacing.Regular,
    top: 55,
    backgroundColor: "white",
  },
  useMyLocationButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: Spacing.Regular,
  },
});

const Screen: React.FC<SetupScreenComponent<DeliveryModalState>> = ({
  state,
  onNext,
}) => {
  const { currentUser } = useSelector((s: RootState) => s.login);
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>(
    state.DeliveryAddress?.address ?? ""
  );
  const [isFocused, setFocused] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingMyLocation, setIsLoadingMyLocation] =
    useState<boolean>(false);
  const isShowLoadingAnimation = isLoading || isLoadingMyLocation;
  const [autoCompleteResult, setAutoCompleteResult] = useState<RecentAddress[]>(
    state.AutoCompleteResult ?? []
  );

  const [recentAddresses, setRecentAddresses] = useState<RecentAddress[]>([]);

  const [debounce, setDebounce] = useState<NodeJS.Timeout>();

  const [isLocationPermissionModalVisible, setLocationPermissionModalVisible] =
    useState<boolean>(false);
  const [shareLocationModalVisible, setShareLocationModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setFocused(true);
    }, 100);
  }, []);

  useEffect(() => {
    getCurrentLocationPermission().then((permission) => {
      if (permission === "denied") {
        requestLocationPermission().catch((err) => console.error(err));
      }
    });
  }, []);

  useEffect(() => {
    if (currentUser) {
      getRecentAddresses().then((addresses) => {
        setRecentAddresses(
          addresses.map((x) => ({
            address: x.formattedAddress,
            googlePlaceId: x.googlePlaceId,
            latitude: x.latitude,
            longitude: x.longitude,
          }))
        );
      });
    }
  }, [currentUser]);

  const handleAddressSearch = useCallback(
    (text: string, autoSelectFirst?: boolean) => {
      clearTimeout(debounce);
      setDebounce(
        setTimeout(() => {
          setIsLoading(true);
          fetch(
            `${BASE_URL}/orders/deliveries/addresses/autocomplete?address=${text}`
          )
            .then((data) => data.json())
            .then((results: RecentAddress[]) => {
              setAutoCompleteResult(results);
              if (autoSelectFirst && results.length > 0) {
                onNext({
                  ...state,
                  DeliveryAddress: results[0],
                  AutoCompleteResult: results,
                });
              }
            })
            .finally(() => {
              setIsLoading(false);
              setIsLoadingMyLocation(false);
            });
        }, 500)
      );
    },
    [debounce, onNext, state]
  );

  const handleChangeText = (text: string) => {
    setSearchText(text);
    if (text.length > 2 || text === "") {
      handleAddressSearch(text);
    } else {
      setAutoCompleteResult([]);
    }
  };

  const getAddressFromCoordinates = (latitude: number, longitude: number) => {
    return new Promise((resolve, reject) => {
      fetch(
        GOOGLE_IP_ADDRESS_GEOCODE_URL +
          latitude +
          "," +
          longitude +
          "&key=" +
          config.googleMapsKey
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === "OK") {
            resolve(responseJson?.results?.[0]?.formatted_address);
          } else {
            reject("not found");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const askForLocationPermission = useCallback(async () => {
    const res = await requestLocationPermission();
    if (!res.userLocation) {
      setShareLocationModalVisible(false);
      setLocationPermissionModalVisible(true);
      setIsLoadingMyLocation(false);
    } else {
      setShareLocationModalVisible(false);
      setLocationPermissionModalVisible(false);

      getUserLocation()
        .then((data) => {
          if (data) {
            const coordinates = {
              longitude: data.coords.longitude,
              latitude: data.coords.latitude,
            };
            getAddressFromCoordinates(
              coordinates.latitude,
              coordinates.longitude
            ).then((addressText) => {
              if (addressText) {
                const address = `${addressText}`;
                setSearchText(`${address}`);
                handleAddressSearch(address, true);
              } else {
                setIsLoadingMyLocation(false);
              }
            });
          }
        })
        .catch(() => {
          setIsLoadingMyLocation(false);
        });
    }
  }, [handleAddressSearch]);

  const useMyLocationPress = () => {
    setIsLoadingMyLocation(true);
    askForLocationPermission();
  };

  useEffect(() => {
    getCurrentLocationPermission()
      .then(async (val) => {
        if (val === "denied") {
          setShareLocationModalVisible(true);
        }
      })
      .catch(() => {});
  }, [askForLocationPermission]);

  const onRecentAddressPress = (address: RecentAddress) => {
    onNext({
      ...state,
      DeliveryAddress: address,
      AutoCompleteResult: [address],
    });
  };

  return (
    <View style={styles.container}>
      <SearchTextInput
        placeHolder={t("OrderManagement:searchDeliveryAddressPlaceholder")}
        onClear={() => {
          setSearchText("");
          setAutoCompleteResult([]);
        }}
        onSearch={() => {}}
        isFocused={isFocused}
        setFocused={setFocused}
        onChangeText={handleChangeText}
        value={searchText}
      />

      <Pressable
        onPress={useMyLocationPress}
        style={styles.useMyLocationButtonContainer}>
        <Image
          resizeMode='contain'
          source={navigateIcon as ImageSourcePropType}
          style={styles.navigateIcon}
        />
        <Text style={styles.useMyLocationText}>
          {t("OrderManagement:useMyLocation")}
        </Text>
      </Pressable>

      <Divider />

      {!searchText && recentAddresses.length > 0 && (
        <View style={styles.recentAddressesContainer}>
          <Text style={styles.recentAddressesText}>
            {t("OrderManagement:recentAddresses")}
          </Text>
          {recentAddresses.map((address) => {
            return (
              <RecentAddressView
                onPress={onRecentAddressPress}
                key={address.address}
                recentAddress={address}
              />
            );
          })}
        </View>
      )}

      <View style={styles.deliveryWithUberContainer}>
        <Image
          resizeMode='contain'
          source={deliveryWithUber as ImageSourcePropType}
          style={styles.deliveryWithUberIcon}
        />
      </View>

      <View style={styles.resultsContainer}>
        {isShowLoadingAnimation && (
          <div style={styles.loadingContainer}>
            <FlyingBurritoLoader />
          </div>
        )}
        {!isShowLoadingAnimation && autoCompleteResult.length > 0 && (
          <ul id='autocomplete-results'>
            {autoCompleteResult.map((result) => (
              <li
                key={result.googlePlaceId}
                onClick={() => {
                  onNext({
                    ...state,
                    DeliveryAddress: result,
                    AutoCompleteResult: autoCompleteResult,
                  });
                }}>
                {result.address}
              </li>
            ))}
          </ul>
        )}
      </View>

      <ModalWithButton
        image={questionImg as ImageSourcePropType}
        modalId='LocationPermissionModal'
        isVisible={isLocationPermissionModalVisible}
        primaryButton={{
          name: "CLOSE",
          action: () => setLocationPermissionModalVisible(false),
        }}
        small={true}
        title={t("Dashboard:turnLocationOn")}
        message={t("Dashboard:locationDeniedMessage")}
        onModalClose={() => setLocationPermissionModalVisible(false)}
      />
      <LocationPermissionModal
        isVisible={shareLocationModalVisible}
        onClose={() => setShareLocationModalVisible(false)}
        onPrimaryClick={askForLocationPermission}
      />
    </View>
  );
};

export default Screen;
