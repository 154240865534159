/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AllActionsOf } from "../utils/Actions";
import {
  AddFavouriteRequest,
  DeleteFavResponse,
  FavouriteCartItem,
  FavouriteCartItemsAndMenu,
  FavouriteResponse,
  FavouriteState,
  GetFavouriteResponse,
  UpdateFavouriteRequest,
  UpdateFavouriteResponse,
} from "./models";

const initialState: FavouriteState = {
  isLoading: false,
  isUpdateLoading: false,
  favourites: [],
  favouriteCartItems: [],
  favouriteMenuItems: [],
  isFavouriteMenuAvailable: false,
  isError: false,
  isCRUDLoading: false,
  isCRUDFavouriteError: false,
  crudFavouriteErrorMessage: "",
};

const favouriteSlice = createSlice({
  name: "Favourites",
  initialState: initialState,
  reducers: {
    loading(state) {
      state.isLoading = true;
    },

    getFavourite(state) {
      state.isLoading = true;
      state.isCRUDLoading = false;
    },

    setFavourite(state, action: PayloadAction<GetFavouriteResponse[]>) {
      state.isLoading = false;
      state.isCRUDLoading = false;
      state.isCRUDFavouriteError = false;
      state.favourites = action.payload;
    },

    clearFavourite(state) {
      state.favourites = [];
    },

    setFavouriteCartItemsAndMenu(
      state,
      action: PayloadAction<FavouriteCartItemsAndMenu>
    ) {
      state.favouriteCartItems = action.payload.favouriteCartItems;
      state.favouriteMenuItems = action.payload.favouriteMenuItems;
      state.isFavouriteMenuAvailable = true;
    },
    setFavouriteCartItemsAndMenuError(state) {
      state.isFavouriteMenuAvailable = true;
    },

    setFavouriteCartItems(state, action: PayloadAction<FavouriteCartItem[]>) {
      state.favouriteCartItems = action.payload;
    },

    removeFavouriteCartItems(state) {
      state.favouriteCartItems = [];
    },

    resetStatus(state) {
      state.isUpdateLoading = false;
      state.isLoading = false;
      state.isCRUDLoading = false;
      state.isCRUDFavouriteError = false;
      state.crudFavouriteErrorMessage = "";
    },

    createFavourite(state, action: PayloadAction<AddFavouriteRequest>) {
      state.isUpdateLoading = true;
      state.isLoading = true;
      state.isCRUDLoading = true;
    },

    createFavouriteSuccess(state, action: PayloadAction<FavouriteResponse>) {
      state.isUpdateLoading = false;
      state.isLoading = false;
      state.isCRUDLoading = false;
    },

    deleteFavourite(state, action: PayloadAction<number>) {
      state.isUpdateLoading = true;
      state.isLoading = true;
      state.isCRUDLoading = true;
    },

    deleteFavouriteSuccess(state, action: PayloadAction<DeleteFavResponse>) {
      state.isUpdateLoading = false;
      state.isLoading = false;
      state.isCRUDLoading = false;
    },

    createFavouriteStatus(state, action: PayloadAction<string>) {
      state.isUpdateLoading = false;
      state.isLoading = false;
      state.isCRUDLoading = false;
      state.isCRUDFavouriteError = true;
      state.crudFavouriteErrorMessage = action.payload;
    },

    renameFavourite(state, action: PayloadAction<UpdateFavouriteRequest>) {
      state.isUpdateLoading = true;
      state.isLoading = true;
      state.favourites = state.favourites.map((favourite) => {
        return favourite.favouriteId === action.payload.favouriteId
          ? { ...favourite, label: action.payload.label }
          : favourite;
      });
    },

    renameFavouriteSuccess(
      state,
      action: PayloadAction<UpdateFavouriteResponse>
    ) {
      state.isUpdateLoading = false;
      state.isLoading = false;
    },

    error(state) {
      state.isUpdateLoading = false;
      state.isLoading = false;
      state.isError = true;
    },
  },
});

const { actions, reducer } = favouriteSlice;
export type FavouriteActionsType = AllActionsOf<typeof actions>;
export {
  actions as favouriteActions,
  reducer as favouriteReducer,
  initialState,
};
