import * as PlatformUtils from "../modules/platformUtils";
import React, { useState } from "react";
import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  UIManager,
  View,
} from "react-native";
import colours from "./styles/colours";
import { Typography } from "./styles/typography";
import { BorderRadius, Spacing } from "./styles/number";
if (
  Platform.OS === "android" &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}
export enum ActiveTabType {
  LEFT = "left",
  RIGHT = "right",
}
export interface ToggleSelectProps {
  onUpdateTab: (currentActive: ActiveTabType) => void;
  activeTab: ActiveTabType;
  leftTitle: string;
  rightTitle: string;
}

interface StyleProps {
  toggleWidth: number;
  toggleHeight: number;
}

const sliderStyle = (props: StyleProps) =>
  StyleSheet.create({
    slider: {
      width: props.toggleWidth / 2,
      height: props.toggleHeight - 4,
      backgroundColor: colours.white,
      position: "absolute",
      borderRadius: BorderRadius.Light,
      top: 2,
      borderColor: colours.black,
      borderWidth: 1,
    },
  });
const styles = StyleSheet.create({
  toggle: {
    height: 40,
    backgroundColor: colours.lightGrey,
    justifyContent: "center",
    borderRadius: BorderRadius.Light,
    padding: Spacing.Narrow,
    flexGrow: 1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  toggleButton: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  moveLeft: {
    left: 2,
  },
  moveRight: {
    right: 2,
  },
});

export default function ToggleSelect(props: ToggleSelectProps) {
  const [toggleWidth, setToggleWidth] = useState<number>(0);
  const [toggleHeight, setToggleHeight] = useState<number>(0);
  const { onUpdateTab, activeTab, leftTitle, rightTitle } = props;
  return (
    <Pressable
      {...PlatformUtils.generateTestID(Platform.OS, "ToggleSelect")}
      onLayout={(event) => {
        const { width, height } = event.nativeEvent.layout;
        setToggleWidth(width);
        setToggleHeight(height);
      }}
      onPress={() => {
        //FIX ME: it's fading rest of the view
        //LayoutAnimation.configureNext(LayoutAnimation.Presets.spring);
        onUpdateTab(activeTab);
      }}>
      <View style={styles.toggle}>
        <View
          style={[
            sliderStyle({ toggleWidth, toggleHeight }).slider,
            activeTab === ActiveTabType.LEFT
              ? styles.moveLeft
              : styles.moveRight,
          ]}
        />
        <View style={styles.row}>
          <View style={styles.toggleButton}>
            <Text
              style={
                activeTab === ActiveTabType.LEFT
                  ? Typography.bodyBold
                  : Typography.body
              }>
              {leftTitle}
            </Text>
          </View>
          <View style={styles.toggleButton}>
            <Text
              style={
                activeTab === ActiveTabType.RIGHT
                  ? Typography.bodyBold
                  : Typography.body
              }>
              {rightTitle}
            </Text>
          </View>
        </View>
      </View>
    </Pressable>
  );
}
