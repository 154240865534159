import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AllActionsOf } from "../utils/Actions";
import { BraintreeState, SavePaymentPayload } from "./model";

const initialState: BraintreeState = {
  paymentPayload: null,
  isTimeout: false,
  previousStoreLocation: null,
};

const braintreeSlice = createSlice({
  name: "braintree",
  initialState: initialState,
  reducers: {
    savePaymentPayload(state, action: PayloadAction<SavePaymentPayload>) {
      state.paymentPayload = action.payload.paymentPayload;
      state.previousStoreLocation = action.payload.store;
    },
    clearPaymentPayload(state) {
      state.paymentPayload = null;
      state.isTimeout = false;
    },
    setTimeoutError(state, action: PayloadAction<boolean>) {
      state.isTimeout = action.payload;
    },
  },
});

const { actions, reducer } = braintreeSlice;
export type braintreeActionType = AllActionsOf<typeof actions>;
export {
  actions as braintreeActions,
  reducer as braintreeReducer,
  initialState,
};
