import { flatten } from "lottie-colorify";
import lottie from "lottie-web";
import React, { useEffect, useRef } from "react";

import config from "../../../config";
import resetButtonAnimation from "../../assets/gif/resetButton.json";
import colours from "../../styles/colours";
import styles from "./Spinner.styles";
// web spinner loader
interface SpinnerProps {
  containerStyle?: React.CSSProperties;
  color?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ containerStyle = {}, color }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: wrapperRef.current,
      renderer: "canvas",
      loop: true,
      autoplay: !config.visualTestingMode,
      animationData: flatten(color ?? colours.yellow, resetButtonAnimation),
    });
  }, [color]);

  return (
    <div
      style={{ ...styles.webLoader, ...containerStyle }}
      ref={wrapperRef}
      data-testid='spinner-loader'
      id='spinner-loader'
    />
  );
};

export default Spinner;
