import { configureStore } from "@reduxjs/toolkit";
import {
  createMigrate,
  MigrationManifest,
  PersistedState,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";

import {
  CartInitialState,
  GuestInitialState,
  rootReducer,
  rootSaga,
} from "gyg_common";

const MIGRATION_DEBUG = false;

const sagaMiddleware = createSagaMiddleware();

const migrations: MigrationManifest = {
  1: (state) => {
    return {
      ...state,
      cart: { ...CartInitialState },
      guest: {
        currentOrders: GuestInitialState.currentOrders,
        recentOrders: GuestInitialState.recentOrders,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(state as any)?.guest,
      },
    } as unknown as PersistedState;
  },
  4: (state) => {
    return {
      ...state,
      cart: { ...CartInitialState },
      guest: {
        ...GuestInitialState,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(state as any)?.guest,
      },
    } as unknown as PersistedState;
  },
};

const persistConfig = {
  key: "root",
  version: 4, // this needs to match the migrations key to update datasets
  storage,
  migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG }),
  whitelist: ["guest", "cart", "social", "analytics", "braintree", "device"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      thunk: true,
      immutableCheck: true,
      serializableCheck: false,
    });

    middlewares.push(sagaMiddleware);

    return middlewares;
  },
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { persistor, store };
