export interface UpsellSection {
  sectionId: number;
  menuName: string;
  categories: UpsellItem[];
  products: UpsellItem[];
  multiParts: UpsellItem[];
}

export interface UpsellItem {
  id: number;
  name: string;
  displayOrder: number;
}

export enum UpsellSource {
  PreCart = "pre_cart",
  InCart = "in_cart",
}
