import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, Text, View } from "react-native";

import { SearchTextInput } from "gyg_common/components";
import Divider from "gyg_common/components/BaseComponents/Divider";
import { SetupScreenComponent } from "gyg_common/components/OrderSetup/OrderSetupFlowContent";
import DeliveryStoreDetails, {
  SearchResult,
  SearchResultWithStore,
} from "gyg_common/components/Stores/DeliveryStoreDetails";
import { Spacing } from "gyg_common/components/styles/number";
import { DeliveryModalState } from "gyg_common/redux_store/order/models";
import { Typography } from "styles/typography";

import { FlyingBurritoLoader } from "../shared/Loading/FlyingBurritoLoader/FlyingBurritoLoader";

import { BASE_URL } from "@/config";
import colours from "@/styles/colours";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: { paddingHorizontal: Spacing.Regular },
  restaurantsContainer: { flexGrow: 1 },
  titleText: {
    ...Typography.headline,
    color: colours.black,
    top: Spacing.MediumThick,
    textAlign: "center",
  },
  topTitle: {
    ...Typography.titleTwo,
    fontSize: 22,
    color: colours.black,
  },
  topHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: Spacing.Regular,
    paddingVertical: Spacing.Light,
  },
  loadingContainer: {
    margin: Spacing.MediumThick,
    display: "flex",
    justifyContent: "center",
  },
});

const Screen: React.FC<SetupScreenComponent<DeliveryModalState>> = ({
  state,
  onNext,
  onBack,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState<SearchResultWithStore[]>([]);

  useEffect(() => {
    fetch(
      `${BASE_URL}/orders/deliveries/stores?google_place_id=${
        state.DeliveryAddress!.googlePlaceId
      }&radius=50`
    )
      .then((data) => data.json())
      .then(async (results: SearchResult[]) => {
        const mappedResults = await Promise.all<SearchResultWithStore>(
          results.map(async (result) => {
            const response = await fetch(`
            ${BASE_URL}/store/${result.id}`);
            const data = await response.json();
            return { ...result, store: data };
          })
        );
        setResults(mappedResults);
        setLoading(false);
      });
  }, [state.DeliveryAddress]);

  const chooseStoreAndContinue = (result: SearchResultWithStore) => {
    onNext({
      ...state,
      DeliveryFee: result.deliveryFee > 0 ? result.deliveryFee : 0,
      DeliveryTimeMins: result.deliveryTime,
      StoreDistance: result.distance,
      DeliveryQuoteId: result.quoteId,
      GooglePlaceId: result.googlePlaceId,
      ChosenStore: result.store,
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <div>
          <SearchTextInput
            placeHolder={t("OrderManagement:searchDeliveryAddressPlaceholder")}
            onClear={() => {}}
            onSearch={() => {}}
            isFocused={false}
            setFocused={onBack.bind(this, state)}
            onChangeText={() => {}}
            value={state.DeliveryAddress!.address}
          />
        </div>
        {loading && (
          <div style={styles.loadingContainer}>
            <FlyingBurritoLoader />
          </div>
        )}

        {!loading && results.length == 0 && (
          <Text style={styles.titleText}>
            {t("OrderManagement:noOpenRestaurantFound")}
          </Text>
        )}
      </View>
      <ScrollView
        style={styles.container}
        contentContainerStyle={styles.restaurantsContainer}>
        {!loading && results.length > 0 && (
          <View style={styles.container}>
            <View style={styles.topHeader}>
              <Text style={styles.topTitle}>
                {t("OrderManagement:gygsThatDeliver")}
              </Text>
            </View>
            <Divider />
            {results.map((result, i) => (
              <DeliveryStoreDetails
                key={`store-${i}`}
                searchResult={result}
                isLast={results.length - 1 == i}
                onSelectStore={() => chooseStoreAndContinue(result)}
              />
            ))}
          </View>
        )}
      </ScrollView>
    </View>
  );
};

export default Screen;
