/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ClientTokenReponse,
  GetClientTokenPayload,
} from "../../services/api/checkout/model";
import { GetOrderResponse } from "../../services/api/order/model";
import { ErrorResponse } from "../error/models";
import { AllActionsOf } from "../utils/Actions";
import { CheckoutResponse, CheckoutState, PaymentPayload } from "./model";

const initialState: CheckoutState = {
  error: undefined,
  clientTokenResponse: { clientToken: "", paymentTokenTimestamp: "" },
  loading: false,
  paymentResponse: null,
  paymentSuccess: false,
  getClientTokenLoading: false,
  isSavePayment: true,
  // currently occurs when
  // - retries exhausted
  // - no order ID (404) during checkout
  // - edge case no Order ID when checking out
  hasUnexpectedError: false,
};

const checkoutSlice = createSlice({
  name: "Checkout",
  initialState: initialState,
  reducers: {
    getClientToken(state, action: PayloadAction<GetClientTokenPayload>) {
      state.clientTokenResponse = initialState.clientTokenResponse;
      state.getClientTokenLoading = true;
    },
    getClientTokenSuccess(state, action: PayloadAction<ClientTokenReponse>) {
      state.clientTokenResponse = action.payload;
      state.getClientTokenLoading = false;
    },
    getClientTokenFailure(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
      state.getClientTokenLoading = false;
    },
    clearClientToken(state) {
      state.clientTokenResponse = initialState.clientTokenResponse;
    },
    initPaymentAuthorized(state, action: PayloadAction<PaymentPayload>) {
      state.loading = true;
      state.error = null;
    },
    initPayment(state, action: PayloadAction<PaymentPayload>) {
      state.loading = true;
      state.error = null;
    },
    initPaymentSuccess(state, action: PayloadAction<CheckoutResponse>) {
      state.paymentResponse = action.payload;
      state.loading = false;
      state.paymentSuccess = true;
      state.clientTokenResponse = initialState.clientTokenResponse;
    },
    setPaymentResponse(state, action: PayloadAction<CheckoutResponse | null>) {
      state.paymentResponse = action.payload;
      state.loading = false;
      state.paymentSuccess = false;
    },
    updatePaymentResponse(state, action: PayloadAction<GetOrderResponse>) {
      if (state.paymentResponse) {
        state.paymentResponse.order.orderFinalisationStatus =
          action.payload.orderFinalisationStatus;
      }
    },
    resetPayment(state) {
      state.error = initialState.error;
      state.loading = initialState.loading;
      state.paymentSuccess = initialState.paymentSuccess;
    },
    toggleSavePayment(state) {
      state.isSavePayment = !state.isSavePayment;
    },
    setSavePayment(state, action: PayloadAction<boolean | undefined>) {
      state.isSavePayment = action.payload ?? true;
    },
    setHasUnexpectedError(state, action: PayloadAction<boolean>) {
      state.hasUnexpectedError = action.payload;
    },
    error(state, action: PayloadAction<ErrorResponse | undefined>) {
      state.loading = false;
      state.paymentSuccess = false;
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = checkoutSlice;
export type CheckoutActionType = AllActionsOf<typeof actions>;
export { actions as CheckoutAction, reducer as checkoutReducer, initialState };
