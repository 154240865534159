export enum CoffeeCupState {
  PURCHASED = "purchased",
  SELECTED = "selected",
  EMPTY = "empty",
}
export interface Loyalty {
  actualDollarsBalance: number;
  actualPointsBalance: number;
  cardNumber: string;
  loyaltyRewardAmount: number;
  loyaltyRewardConversionLimit: number;
}

export interface IRewards {
  name: string;
  title: string;
  image?: string;
  webImage?: string;
  link: string | null;
  id: string;
  description: string;
  priceDescription?: string;
  expiryDate?: string;
  offerDescription?: string;
  intraTitle?: string;
  stepText?: string[];
  userCouponCode: string;
  masterCouponCode: string;
  terms: {
    description?: string;
    url?: string;
  };
}

export interface CoffeeLoyaltyCup {
  text: string;
  status: CoffeeCupState;
}

export interface CoffeeLoyaltyFreeCup {
  text: number;
  enable: boolean;
}

export interface CoffeeLoyalty {
  counter: CoffeeLoyaltyCup[];
  freeCup: CoffeeLoyaltyFreeCup;
  message: string;
}

export interface LoyaltyState {
  isLoading: boolean;
  loyalty: Loyalty | null;
  errorDesc: string | null;
  // I am using an empty array below as that is what the API returns if there are no rewards
  rewards: IRewards[] | [];
  rewardsLoading: boolean;
  rewardsError: string | null;
  activeReward: IRewards | null;
  redeemDollars: number | null;
  coffeeLoyaltyLoading: boolean;
  coffeeLoyalty: CoffeeLoyalty | null;
}
