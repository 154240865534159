import "./styles/main.scss";

import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import * as Sentry from "@sentry/react";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from "redux_store/configureReduxStore";
import { PersistGate } from "redux-persist/integration/react";

import config from "config";
import i18next from "gyg_common/modules/i18next";
import { gb } from "gyg_common/utils/growthbook";

import BaseContainer from "./views/containers/Base/BaseContainer";

declare global {
  interface Window {
    store: ToolkitStore;
  }
}

i18next.changeLanguage(config.version);
// Load features from the GrowthBook API and initialize the SDK
gb.loadFeatures();

const App: React.FC = () => {
  useEffect(() => {
    if (
      "Notification" in window &&
      Notification.permission !== "granted" &&
      Notification.permission !== "denied" &&
      Notification.requestPermission &&
      typeof Notification.requestPermission === "function"
    ) {
      Notification.requestPermission();
    }
  }, []);

  return (
    <GrowthBookProvider growthbook={gb}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <BaseContainer />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </GrowthBookProvider>
  );
};

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

export default __DEV__ ? App : Sentry.withProfiler(App);
