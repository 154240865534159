/* eslint-disable @typescript-eslint/no-explicit-any */
import { fork, put, takeLatest } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";
import { upsellActions, UpsellActionType } from "./upsell.slice";
import * as ApiService from "../../services/api/upsell";
import { UpsellType } from "../../services/api/upsell/model";
import { UpsellSection } from "../../modules/Upsell/model";

const call: any = Effects.call;

function* handleGetUpsellFromConfig(
  action: UpsellActionType
): Generator<any, void, UpsellSection[]> {
  try {
    const result: UpsellSection[] = yield call(
      ApiService.getUpsellFromConfig,
      action.payload
    );
    if (action.payload == UpsellType.INCART) {
      yield put(upsellActions.setUpsellItemsInCardFromConfigSuccess(result));
    } else {
      yield put(upsellActions.setUpsellItemsPreCardFromConfigSuccess(result));
    }
  } catch (e) {
    yield put(upsellActions.getUpsellFromConfigFailed());
  }
}

export function* watchGetUpsellFromConfig(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    upsellActions.getUpsellFromConfig.type,
    handleGetUpsellFromConfig
  );
}

const saga = [fork(watchGetUpsellFromConfig)];

export default saga;
