import { AnalyticsState } from "../../redux_store/analytics/models";
import { AuthState } from "../../redux_store/auth/models";
import { CartState } from "../../redux_store/cart/models";
import { CheckoutState } from "../../redux_store/checkout/model";
import { FavouriteState } from "../../redux_store/favourite/models";
import { GuestPersistState } from "../../redux_store/guest/models";
import { LoyaltyState } from "../../redux_store/loyalty/models";
import { MenuState } from "../../redux_store/menu/menu.slice";
import { OrderState } from "../../redux_store/order/models";
import { PromoState } from "../../redux_store/promo/models";
import {
  RegistrationState,
  RegistrationStructure,
} from "../../redux_store/registration/model";
import { SocialState } from "../../redux_store/social/models";
import { StoreState } from "../../redux_store/store/models";
import { UserState } from "../../redux_store/user/models";

export enum StateEnum {
  analytics = "analytics",
  cart = "cart",
  checkout = "checkout",
  favourite = "favourite",
  guest = "guest",
  login = "login",
  loyalty = "loyalty",
  menu = "menu",
  order = "order",
  registration = "registration",
  registrationDetails = "registrationDetails",
  social = "social",
  store = "store",
  user = "user",
  promo = "promo",
}

export interface StateProps {
  analytics: AnalyticsState;
  cart: CartState;
  checkout: CheckoutState;
  favourite: FavouriteState;
  guest: GuestPersistState;
  login: AuthState;
  loyalty: LoyaltyState;
  menu: MenuState;
  order: OrderState;
  registration: RegistrationState;
  registrationDetails: RegistrationStructure;
  social: SocialState;
  store: StoreState;
  user: UserState;
  promo: PromoState;
}

export const stateSelector = (stateName: StateEnum) => {
  return (state: StateProps) => state[stateName];
};
