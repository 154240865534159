import React from "react";
import {
  Image,
  ImageSourcePropType,
  Platform,
  Pressable,
  StyleSheet,
} from "react-native";
import { Spacing } from "../../styles/number";

interface IRatingCircleProps {
  starRatingValue: number;
  image: ImageSourcePropType;
  onHover: (rativeValue: number) => void;
  onHoverEnd: () => void;
  onPress: (ratingValue: number) => void;
}

const styles = StyleSheet.create({
  starIcon: {
    width: 32,
    height: 32,
  },
  starWrapper: {
    marginLeft: Spacing.Thin,
    marginRight: Spacing.Thin,
  },
});

export const RatingCircle: React.FC<IRatingCircleProps> = ({
  starRatingValue,
  image,
  onHover,
  onHoverEnd,
  onPress,
}) => {
  const onTouchOrMouseHover = () => onHover(starRatingValue);
  const onStarOrRatingPress = () => onPress(starRatingValue);

  return (
    <>
      {Platform.OS === "web" ? (
        <button
          style={{ ...styles.starWrapper }}
          onMouseOver={onTouchOrMouseHover}
          onMouseLeave={onHoverEnd}
          onClick={onStarOrRatingPress}>
          <Image
            source={image as ImageSourcePropType}
            style={styles.starIcon}
          />
        </button>
      ) : (
        <Pressable
          onTouchStart={onTouchOrMouseHover}
          onTouchEnd={onHoverEnd}
          style={styles.starWrapper}
          onPress={onStarOrRatingPress}>
          <Image style={styles.starIcon} source={image} />
        </Pressable>
      )}
    </>
  );
};
