import { groupBy } from "lodash";

import config from "config";
import { locales } from "gyg_common";

import {
  Category,
  CombinedCategory,
  MenuListData,
  MenuListItemProps,
  MenuSection,
} from "../../redux_store/menu/models";

/**
 * Creates array of combined categories.
 * @param categories array of categories with categoryType attribute
 * @returns
 */
const groupCombinedCategories = (
  categories: Category[]
): CombinedCategory[] => {
  const groupedCategories = groupBy(categories, "categoryType");
  return Object.entries(groupedCategories).map(([key, value]) => {
    const combinedCategory: CombinedCategory = {
      data: value,
      title: key,
      displayOrder: value[0].displayOrder,
    };
    return combinedCategory;
  });
};

/**
 * Segregates section's data into categories, products, multiparts and combined categories.
 * @param menuSection
 * @returns
 */
const getMenuListData = (menuSection: MenuSection): MenuListData[] => {
  const menuList = menuSection.subSections.map((subsection) => {
    const categoryMenuItems: MenuListItemProps[] = [];
    const categoriesWithType: Category[] = [];

    subsection.categories?.forEach((category) => {
      if (category.categoryType) {
        categoriesWithType.push(category);
      } else {
        categoryMenuItems.push({
          category: category,
          displayOrder: category.displayOrder,
          product: undefined,
          favourite: undefined,
        });
      }
    });

    const combinedCategoryMenuItems: MenuListItemProps[] = [];
    groupCombinedCategories(categoriesWithType)?.forEach((combinedCategory) =>
      combinedCategoryMenuItems.push({
        combinedCategory,
        displayOrder: combinedCategory.displayOrder,
      })
    );

    const multiPartsMenuItems: MenuListItemProps[] = [];
    subsection.multiParts?.forEach((multiPart) => {
      multiPartsMenuItems.push({
        multiPart,
        displayOrder:
          // TODO: revisit this when AU menu is fixed to have unique display order for all items under a category
          config.version === locales.US ? multiPart.displayOrder : undefined,
      });
    });
    const productMenuItems: MenuListItemProps[] = [];
    subsection.products?.forEach((product) => {
      productMenuItems.push({
        category: undefined,
        displayOrder: product.displayOrder,
        product,
        favourite: undefined,
      });
    });

    const sortedMenuItems = [
      ...categoryMenuItems,
      ...productMenuItems,
      ...multiPartsMenuItems,
      ...combinedCategoryMenuItems,
    ].sort((a, b) => a.displayOrder - b.displayOrder);

    return {
      id: subsection.id,
      title: subsection.title,
      data: sortedMenuItems,
    };
  });

  return menuList;
};

export default getMenuListData;
