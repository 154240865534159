import { Platform, StyleSheet } from "react-native";

import { Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

const styles = StyleSheet.create({
  infoBtn: {
    flexDirection: "row",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  infoBtnText: {
    ...Typography.titleFour,
    fontSize: 16,
    marginLeft: Spacing.ExtraThin,
    lineHeight: 20,
    ...(Platform.OS === "ios" && { paddingTop: Spacing.ExtraThin }),
  },
  icon: {
    width: 24,
    height: 24,
  },
});

export default styles;
