/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy } from "react";

import { SentryLoggerInstance } from "gyg_common";

// Reload the page if the load of lazy import chunk failed
// handles undefined as well for react-app-rewired + webpack config
// https://www.bitahoy.com/blog/post/fixing-chunk-load-errors-in-webpack-and-react-js
const lazyRetry = (
  componentImport: () => Promise<{
    default: React.FC<any>;
  }>
) =>
  new Promise<any>((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed${btoa(
      componentImport.toString()
    )}`;

    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(storageKey) || "false"
    );
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(storageKey, "false");
        if (component === undefined) {
          window.sessionStorage.setItem(storageKey, "true");
          return window.location.reload(); // refresh the page
        }
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(storageKey, "true");
          window.location.reload();
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });

const lazyImport = (
  importPromise: Promise<{
    default: React.FC<any>;
  }>
) =>
  lazy(async () => {
    try {
      const lazyModuleExports = await lazyRetry(
        async () => await importPromise
      );
      return lazyModuleExports;
    } catch (e) {
      SentryLoggerInstance.sentryCaptureCustomError(
        "Lazy load chunk - page reload failed!",
        e
      );
    }
  });

export default lazyImport;
