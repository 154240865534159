import { AnalyticsState } from "../../redux_store/analytics/models";
import { AnalyticsType } from "./model";

export const payOrderPayload = (
  state: AnalyticsState,
  source: string
): AnalyticsType => {
  return {
    product_id: state.productId,
    product_name: state.productName,
    product_category: state.productCategory,
    product_quantity: state.productQuantity,
    store_name: state.storeName,
    store_id: state.storeId,
    order_type: state.orderType,
    order_date: state.orderDate,
    order_time: state.orderTime,
    basket_id: state.basketId,
    recent_orders: state.recentOrders,
    table_service_origin: state.tableInit,
    source: source,
    featured_items: state.featuredItems,
    payment_type: state.paymentType,
    //time_since_cart
  };
};
