import colours from "../styles/colours";
import { BorderRadius, Spacing } from "../styles/number";
import { Dimensions, Platform, StyleSheet } from "react-native";

export const stylesWeb = StyleSheet.create({
  container: {
    backgroundColor: colours.starburst,
    width: "100%",
    flex: 1,
    borderRadius: BorderRadius.Medium,
    shadowRadius: 8,
    shadowOffset: {
      width: 0,
      height: -2,
    },
    elevation: 2,
    shadowOpacity: 0.1,
    shadowColor: colours.black,
  },
  wrapper: {
    padding: Spacing.Thin,
    flexDirection: "row",
  },
  icon: {
    width: 32,
    height: 32,
    alignSelf: "center",
  },
  text: {
    marginLeft: Spacing.Light,
    marginRight: Spacing.Thin,
    marginVertical: Spacing.ExtraThin,
    flex: 1,
    alignSelf: "center",
  },

  closeImg: {
    width: 32,
    height: 32,
    alignSelf: "center",
  },
});

export const dynamicStyles = (extraBottomHeight: boolean | undefined) =>
  StyleSheet.create({
    container: {
      backgroundColor: colours.starburst,
      position: "absolute",
      bottom: extraBottomHeight ? 94 : Platform.OS === "ios" ? 30 : 0,
      zIndex: 999,
      flex: 1,
      margin: Spacing.Thin,
      width: Dimensions.get("screen").width - 16,
      borderRadius: BorderRadius.Medium,
      shadowRadius: 8,
      shadowOffset: {
        width: 0,
        height: -2,
      },
      elevation: 2,
      shadowOpacity: 0.1,
      shadowColor: colours.black,
    },
  });

export const stylesApp = StyleSheet.create({
  wrapper: {
    padding: Spacing.Thin,
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    width: 32,
    height: 32,
    marginRight: Spacing.ExtraThin,
  },
  text: {
    marginHorizontal: Spacing.Thin,
    marginVertical: Spacing.ExtraThin,
    width: Dimensions.get("screen").width - 120,
  },

  closeImg: {
    width: 40,
    height: 40,
  },
});
