import appStoreIcon from "assets/images/app_store.png";
import googlePlayIcon from "assets/images/google_play.png";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  Platform as PlatformRN,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { PlatformUtils } from "gyg_common";
import { Typography } from "styles/typography";

const styles = StyleSheet.create({
  tableServiceWrapper: {
    marginTop: 17,
  },
  appDownloadWrapper: {
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 13,
  },
  appStoreIcon: {
    resizeMode: "cover",
    height: 50,
    width: 150,
    marginHorizontal: 5,
  },
  googlePlayIcon: {
    resizeMode: "cover",
    height: 50,
    width: 170,
    marginHorizontal: 5,
  },
});

export enum Platform {
  ANDROID = "android",
  IOS = "ios",
}

export interface OrderSetupTableServiceProps {
  handleLink: (platform: Platform) => void;
}

export const OrderSetupTableService: React.FC<OrderSetupTableServiceProps> = ({
  handleLink,
}) => {
  const { t } = useTranslation();

  return (
    <View
      style={styles.tableServiceWrapper}
      {...PlatformUtils.generateTestID(PlatformRN.OS, "tableServiceArea")}>
      <Text style={Typography.bodyBold}>
        {t("OrderManagement:downloadAppCopy")}
      </Text>
      <View
        style={styles.appDownloadWrapper}
        {...PlatformUtils.generateTestID(
          PlatformRN.OS,
          "tableServiceDownloadButton"
        )}>
        <Pressable onPress={() => handleLink(Platform.IOS)}>
          <Image
            style={styles.appStoreIcon}
            source={appStoreIcon as ImageSourcePropType}
          />
        </Pressable>

        <Pressable onPress={() => handleLink(Platform.ANDROID)}>
          <Image
            style={styles.googlePlayIcon}
            source={googlePlayIcon as ImageSourcePropType}
          />
        </Pressable>
      </View>
    </View>
  );
};
