import { CartItem } from "../cart/models";
import { MenuListItemProps } from "../menu/models";

export interface FavouriteState {
  isLoading: boolean;
  isUpdateLoading: boolean;
  favourites: GetFavouriteResponse[];
  favouriteCartItems: FavouriteCartItem[];
  favouriteMenuItems: MenuListItemProps[];
  isFavouriteMenuAvailable: boolean;
  isError: boolean;
  isCRUDLoading: boolean;
  isCRUDFavouriteError: boolean;
  crudFavouriteErrorMessage: string;
}

export interface FavouriteCartItemsAndMenu {
  favouriteCartItems: FavouriteCartItem[];
  favouriteMenuItems: MenuListItemProps[];
}

export interface FavouriteCartItem {
  favouriteId: number;
  label: string;
  imageTopDownUrl?: string;
  imageAngleUrl?: string;
  cartItem: CartItem;
  miamItem?: CartItem;
  isMiam?: boolean;
  miamGUID?: string;
}

export interface FavouriteBasketItem {
  id: number;
  quantity?: number;
  miamGUID?: string;
  name: string;
  addFillings?: number[];
  addModifiers?: number[];
  removeModifiers?: number[];
  extraModifiers?: number[];
  extraFilling?: number[];
  defaultModifier?: number[];
  price?: number;
  unitPrice?: number;
  parts?: FavouriteBasketItem[];
  miam?: GetFavouriteResponse;
}

export interface GetFavouriteResponse {
  favouriteId: number;
  label: string;
  basketItems: FavouriteBasketItem[];
}

export interface AddFavouriteRequest {
  label: string;
  basketItems: FavouriteBasketItem[];
}

export interface UpdateFavouriteRequest {
  favouriteId: number;
  label: string;
  basketItems: FavouriteBasketItem[];
}

export interface UpdateFavouriteResponse {
  displayLabel: string;
  label: string;
  favouriteId: number;
}

export interface FavouriteResponse {
  favouriteId: number;
}

export interface DeleteFavResponse {
  recordDeleted: number;
}

export enum CRUDType {
  add = "ADD",
  update = "UPDATE",
  delete = "DELETE",
}
