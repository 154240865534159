import * as braze from "@braze/web-sdk";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { AuthReduxAction, RootState, UserReduxAction } from "gyg_common";
import errorImage from "gyg_common/components/assets/images/illustration_error.png";
import ModalWithButton from "gyg_common/components/modals/ModalWithButton";
import { Screens } from "navigation/const";

export const TermsAndConditionsModalContainer: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAuthSuccess } = useSelector(({ login }: RootState) => login);
  const {
    postTermsAndConditionsLoading,
    postTermsAndConditionsError,
    isTermsAndConditionsUpToDate,
    isBrazeInitialised,
  } = useSelector(({ user }: RootState) => user);

  const { t } = useTranslation();
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] =
    useState(true);
  const [termsLink, setTermsLink] = useState("");

  const toggleModal = () =>
    setShowTermsAndConditionsModal(!showTermsAndConditionsModal);

  const acceptTermsAndConditions = () => {
    dispatch(UserReduxAction.postTermsAndConditions({}));
  };

  const declineTermsAndConditions = () => {
    localStorage.removeItem("CurrentUser");
    dispatch(AuthReduxAction.logoutCurrentDevice());
    history.push(Screens.Login);
    toggleModal();
  };

  const reviewTermsAndConditions = () => {
    window.open(termsLink, "_blank");
  };

  useEffect(() => {
    if (isBrazeInitialised) {
      braze.subscribeToInAppMessage((message) => {
        const m = message as braze.InAppMessage;
        if (
          m &&
          m.extras?.feed_type &&
          m.extras?.feed_type === "updated_legal_terms"
        ) {
          const messageExtrasTermsLink = m.extras?.terms_link;
          if (messageExtrasTermsLink) {
            braze.logInAppMessageImpression(m);
            setTermsLink(messageExtrasTermsLink);
            dispatch(UserReduxAction.setTermsAndConditionsNeedsUpdate());
          }
        }
      });
    }
  }, [isBrazeInitialised, dispatch]);

  return (
    <>
      <ModalWithButton
        small
        modalId='termsAndConditionsError'
        primaryButtonLoading={postTermsAndConditionsLoading}
        isVisible={
          postTermsAndConditionsError !== null &&
          isAuthSuccess &&
          !isTermsAndConditionsUpToDate
        }
        title={t("Dashboard:termsAndConditionsErrorTitle")}
        message={t("Dashboard:termsAndConditionsErrorDescription")}
        primaryButton={{
          name: t("Dashboard:termsAndConditionsErrorTryAgain"),
          action: acceptTermsAndConditions,
        }}
        onModalClose={declineTermsAndConditions}
        image={errorImage as ImageSourcePropType}
        showCloseButton={true}
      />
      <ModalWithButton
        small
        modalId='termsAndConditions'
        isVisible={
          postTermsAndConditionsError === null &&
          isAuthSuccess &&
          !isTermsAndConditionsUpToDate
        }
        title={t("Dashboard:termsAndConditionsChangedTitle")}
        message={t("Dashboard:termsAndConditionsChangedDescription")}
        primaryButton={{
          name: t("Dashboard:accept"),
          action: acceptTermsAndConditions,
        }}
        secondaryButton={{
          name: t("Dashboard:decline"),
          action: declineTermsAndConditions,
        }}
        onModalClose={toggleModal}
        image={errorImage as ImageSourcePropType}
        linkText={t("Dashboard:reviewTermsAndConditions")}
        linkPress={reviewTermsAndConditions}
        showCloseButton={false}
        primaryButtonLoading={postTermsAndConditionsLoading}
      />
    </>
  );
};
