import { AnalyticsType } from "./model";

export const registrationPayload = (
  registrationType: string,
  firstName: string,
  lastName: string,
  customerEmail: string,
  mobileNumber: string,
  customerBirthdate: string,
  isSubscribe: boolean
): AnalyticsType => {
  return {
    registration_type: registrationType,
    first_name: firstName,
    last_name: lastName,
    customer_email: customerEmail,
    mobile_number: mobileNumber,
    customer_birthdate: customerBirthdate,
    is_subscribe: isSubscribe,
  };
};
