export enum Events {
  OpenApp = "open_app",
  ViewCart = "view_cart",
  BarcodeScan = "show_loyalty_barcode",
  FavouriteOrder = "favourite_order",
  FavouriteRenamed = "favourite_renamed",
  AbandonedCart = "abandoned_cart",
  ViewLocations = "view_locations",
  SearchLocations = "search_locations",
  FilterLocations = "filter_locations",
  PreCartUpsell = "pre_cart_upsell",
  InCartUpsell = "in_cart_upsell",
  ContinueAsGuest = "continue_as_guest",
  LoginWithEmail = "login_with_email",
  LoginSucess = "login_success",
  LoginThirdParty = "login_with_third_party",
  Registration = "registration",
  ExpiredRefreshToken = "expired_refresh_token",
  LoginOrJoin = "login_or_join",
  ShareRestaurant = "share_restaurant",
  OrderNow = "order_now",
  GetDirections = "get_directions",
  UpdateNotifications = "update_notifications",
  ViewContactUs = "view_contact_us",
  AddToOrder = "add_to_order",
  AddToCart = "add_to_cart",
  Reorder = "reorder",
  ProductBundle = "product_bundles_view",
  SimpleProduct = "simple_product_view",
  ProductCategory = "product_category_view",
  Reset = "reset_customisation",
  Dashboard = "dashboard",
  PaymentError = "payment_error",
  UpdateDetails = "update_details",
  ValidateMobile = "validate_mobile_view",
  TableServiceOrderSetup = "table_service_ordersetup",
  PayOrder = "pay_order",
  SubmitRating = "submit_rating",
  CancelOrderRating = "cancel_order_rating",
  OrderRating = "order_rating",
  LoginKeychainSaveUser = "login_keychain_save_user",
  LoginKeychainError = "login_keychain_error",
  AsyncStorageError = "async_storage_error",
  Rewards = "rewards",
  UpsellSelection = "upsell_selection",
  OnBoardingViewed = "onboarding_viewed",
  ExperimentViewed = "experiment_viewed",
  SessionStart = "session_start",
  SessionEnd = "session_end",
}

export enum CustomAttribute {
  LastClosestStore = "last_closest_store",
  LastStoreOrdered = "last_store_ordered",
}

export enum BarcodeSource {
  Home = "Home",
  MyGYG = "My GYG",
  MyGomex = "My Gomex",
  Header = "Header",
}

export enum FavouriteSource {
  CART = "cart",
  RECENTORDER = "recent_order",
}

export enum LoginType {
  Facebook = "Facebook",
  Apple = "SignInWithApple",
  Google = "Google",
  Email = "Email",
}

export enum LoginOrJoinSource {
  Homepage = "Home",
  MyGYG = "My GYG",
  Cart = "Cart",
  GuestDetails = "Guest Details",
  OrderStatus = "Order Status",
  JoinGYGTab = "Join GYG Tab",
}

export enum RegistrationSource {
  Login = "Login",
  JoinGYG = "Join GYG",
}

export enum AddToOrderSource {
  RECENTORDER = "Recent Order",
  FAVOURITES = "Favourites",
}

export enum DeviceSource {
  APP = "APP",
  WEB = "WEB",
}
