export interface ISentryLogger {
  sentryEndpointError(exception: unknown, additionalErrorInfo?: string): void;
  sentryRefreshTokenError(exception: unknown): void;
  sentryLowMemoryWarning(): void;
  sentryBraintreeError(exception: unknown): void;
  sentryCaptureCustomError(description: string, error: unknown): void;
  sentrySetTag(tagName: string, tagValue: string): void;
  sentryRouteTracing(routeName: string): void;
}

export let SentryLoggerInstance: ISentryLogger;

export function configSentryLogger(newSentryLogger: ISentryLogger) {
  SentryLoggerInstance = newSentryLogger;
}
