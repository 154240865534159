import { ErrorResponse } from "../error/models";

export interface AuthProps {
  password: string;
  username: string;
  deviceId?: string;
}

export interface CurrentUser {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  registrationComplete: string;
  verifiedEmail: boolean;
  verifiedMobileNumber: boolean;
  accessToken: string;
  expiry: number;
  refreshToken: string;
  userName: string;
  cardNumber: string;
  referralLink: string;
  brazeId?: string;
  branchId?: string;
  storeCard?: boolean;
  isExistingAccount?: boolean;
}

export enum LoginOTPChannel {
  SMS = "sms",
  Email = "email",
}

export interface LoginOTPChallenge {
  mfaToken: string;
}

export interface LoginOTPChallengeResponse {
  scope: string;
  method: string;
  channel: LoginOTPChannel;
  source: string;
  expiresIn: number;
}

export interface LoginOTPVerify {
  mfaToken: string;
  code: string;
  scope: string;
  deviceId?: string;
}

export interface AuthError {
  error: string;
  errorDescription: string;
}

export interface AuthState {
  isLoading: boolean;
  currentUser?: CurrentUser;
  isAuthSuccess: boolean;
  loginErrorResponse: ErrorResponse;
  isShowLogin: boolean;
  isLogoutAllError: ErrorResponse;
  isResetPasswordSuccess: boolean;
  resetPasswordLoading: boolean;
  resetPasswordError: ErrorResponse;
  forgotPasswordError: ErrorResponse;
  isRefreshToken: boolean;
  fromRegistration: boolean;
  requiresOTP: boolean;
  mfaToken?: string;
  mfaError?: ErrorResponse;
  otpSubmittedLoading: boolean;
}

export interface Token {
  sub: string;
  username: string;
  client_id: string;
  iat: number;
  jti: string;
  auth_time: number;
  scope: string;
  token_use: string;
  iss: string;
  exp: number;
}

export interface ResetPasswordPayload {
  code: string | string[];
  newPassword: string;
}

export enum GrantType {
  REFRESH = "refresh",
}

export interface RefreshTokenPayload {
  refreshToken: string;
  grantType: GrantType;
}
