import listIcon from "assets/icons/icon_list.png";
import locationIcon from "assets/icons/icon_location_small.png";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { PlatformUtils, StoreModules } from "gyg_common";
import { Typography } from "styles/typography";

interface ToggleStoresViewProps {
  activeView: StoreModules.StoreModels.RestaurantsViewType;
  onViewChange: () => void;
}

const styles = StyleSheet.create({
  container: {
    margin: 8,
  },
  button: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: 32,
    height: 32,
  },
});

const ToggleStoresView: React.FC<ToggleStoresViewProps> = ({
  activeView,
  onViewChange,
}) => {
  const { t } = useTranslation();

  return (
    <TouchableOpacity
      onPress={() => onViewChange()}
      {...PlatformUtils.generateTestID(
        Platform.OS,
        StoreModules.StoreModels.StoresTestingId.toggleViewBtn
      )}>
      <View style={styles.container}>
        {activeView === StoreModules.StoreModels.RestaurantsViewType.LIST ? (
          <View
            style={styles.button}
            {...PlatformUtils.generateTestID(
              Platform.OS,
              StoreModules.StoreModels.StoresTestingId.mapViewIcon
            )}>
            <Image
              style={styles.icon}
              source={locationIcon as ImageSourcePropType}
            />
            <Text style={Typography.titleThree}>
              {t("StoreSearch:toggleBtnLabelMap")}
            </Text>
          </View>
        ) : (
          <View
            style={styles.button}
            {...PlatformUtils.generateTestID(
              Platform.OS,
              StoreModules.StoreModels.StoresTestingId.listViewIcon
            )}>
            <Image
              style={styles.icon}
              source={listIcon as ImageSourcePropType}
            />
            <Text style={Typography.titleThree}>
              {t("StoreSearch:toggleBtnLabelList")}
            </Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default ToggleStoresView;
