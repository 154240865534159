import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AnalyticsConst,
  AnalyticsInstance,
  OrderReduxAction,
  RootState,
  sendRating,
} from "gyg_common";
import { RateExperience } from "gyg_common/components/RateExperience";

interface RateExperienceProps {
  showRating: boolean;
  setShowRating: (showRating: boolean) => void;
}

export const RateExperienceContainer = ({
  showRating,
  setShowRating,
}: RateExperienceProps) => {
  const dispatch = useDispatch();

  const { ratingOrder, ratingValue } = useSelector((s: RootState) => s.order);
  const { currentUser } = useSelector((s: RootState) => s.login);
  const { isBrazeInitialised } = useSelector((s: RootState) => s.user);

  const onTrackSubmitRating = useCallback(
    (ratingValue: number) => {
      const rateOrder = {
        rating: ratingValue,
        cart_size: ratingOrder.cartSize,
        cart_amount: ratingOrder.cartAmount,
        order_id: ratingOrder.orderId,
        store_name: ratingOrder.storeName,
        store_id: ratingOrder.storeId,
        order_type: ratingOrder.orderType,
      };

      AnalyticsInstance.trackEvent(
        AnalyticsConst.Events.SubmitRating,
        rateOrder
      );
    },
    [ratingOrder]
  );

  const onRatingPress = useCallback(
    (ratingValue: number) => {
      if (ratingOrder?.orderId) {
        const brazeId = currentUser?.brazeId;
        if (brazeId) {
          sendRating(ratingValue, brazeId, ratingOrder.orderId, dispatch);
        } else {
          //keep rating in redux
          dispatch(OrderReduxAction.updateRatingValue(ratingValue));
        }
      }

      onTrackSubmitRating(ratingValue);
      setShowRating(false);
    },
    [
      ratingOrder?.orderId,
      onTrackSubmitRating,
      setShowRating,
      currentUser?.brazeId,
      dispatch,
    ]
  );

  const closeModal = useCallback(() => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.CancelOrderRating, {});
    setShowRating(false);
  }, [setShowRating]);

  useEffect(() => {
    if (showRating && isBrazeInitialised) {
      AnalyticsInstance.trackEvent(AnalyticsConst.Events.OrderRating, {});
    }
  }, [showRating, isBrazeInitialised]);

  useEffect(() => {
    if (!!ratingValue && ratingOrder?.orderId) {
      const brazeId = currentUser?.brazeId;
      if (brazeId) {
        sendRating(ratingValue, brazeId, ratingOrder.orderId, dispatch);
        dispatch(OrderReduxAction.updateRatingValue(null));
      }
    }
  }, [ratingValue, ratingOrder?.orderId, currentUser?.brazeId, dispatch]);

  return (
    <RateExperience
      showRating={showRating}
      orderNumber={ratingOrder ? ratingOrder.orderNumber : ""}
      numberOfOtherProducts={
        ratingOrder ? ratingOrder.numberOfOtherProducts : 0
      }
      productName={
        ratingOrder && ratingOrder.highestPriceProduct?.name
          ? ratingOrder.highestPriceProduct?.name
          : ""
      }
      productImage={ratingOrder ? ratingOrder.productImage : ""}
      onRatingPress={onRatingPress}
      closeModal={closeModal}
    />
  );
};

export default React.memo(RateExperienceContainer);
