import "./styles.scss";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState, UserReduxAction } from "gyg_common";
import { MessageToast } from "gyg_common/components/MessageToast";

export const MessageToastContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { messageToast } = useSelector((state: RootState) => state.user);

  const onClose = () => {
    dispatch(UserReduxAction.setMessageToast(null));
  };

  if (!messageToast) return null;

  return (
    <MessageToast
      iconType={messageToast.iconType || "alert"}
      title={messageToast.title}
      isShowClose
      onClose={onClose}
    />
  );
};
