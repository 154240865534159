export type WebConfig = ReturnType<typeof getConfig>;

const getConfig = () => ({
  REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
  REACT_APP_BRAZE_API_KEY: process.env.REACT_APP_BRAZE_API_KEY,
  REACT_APP_BRAZE_ENDPOINT: process.env.REACT_APP_BRAZE_ENDPOINT,
  REACT_APP_BRANCH_KEY: process.env.REACT_APP_BRANCH_KEY,
  REACT_NEAREST_STORE_KM_THRESHOLD:
    process.env.REACT_NEAREST_STORE_KM_THRESHOLD,
  REACT_APP_VERSION: process.env.REACT_APP_VERSION,
  REACT_APP_GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  REACT_APP_GOOGLE_MAPS_API_SDK: process.env.REACT_APP_GOOGLE_MAPS_API_SDK,
  REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY:
    process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
  REACT_APP_BRAND_SITE_URL: process.env.REACT_APP_BRAND_SITE_URL,
  REACT_APP_TERMS_AND_CONDITIONS_URL:
    process.env.REACT_APP_TERMS_AND_CONDITIONS_URL,
  REACT_APP_PRIVACY_POLICY_URL: process.env.REACT_APP_PRIVACY_POLICY_URL,
  REACT_APP_ALLERGEN_STATEMENT_SITE_URL:
    process.env.REACT_APP_ALLERGEN_STATEMENT_SITE_URL,
  REACT_APP_CONTACT_US_URL: process.env.REACT_APP_CONTACT_US_URL,
  REACT_APP_JWT_SECRET: process.env.REACT_APP_JWT_SECRET,
  REACT_ORDER_LIST_LIMIT: process.env.REACT_ORDER_LIST_LIMIT,
  REACT_APP_APP_STORE_LINK: process.env.REACT_APP_APP_STORE_LINK,
  REACT_APP_GOOGLE_PLAY_LINK: process.env.REACT_APP_GOOGLE_PLAY_LINK,
  REACT_APP_DEEP_LINK: process.env.REACT_APP_DEEP_LINK,
  REACT_APP_ANALYTICS_ENV: process.env.REACT_APP_ANALYTICS_ENV,
  REACT_APP_ANALYTICS_ACCOUNT: process.env.REACT_APP_ANALYTICS_ACCOUNT,
  REACT_APP_ANALYTICS_PROFILE: process.env.REACT_APP_ANALYTICS_PROFILE,
  REACT_APP_ANALYTICS_KEY: process.env.REACT_APP_ANALYTICS_KEY,
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENV: process.env.REACT_APP_SENTRY_ENV,
  REACT_APP_GROWTHBOOK_API_HOST: process.env.REACT_APP_GROWTHBOOK_API_HOST,
  REACT_APP_GROWTHBOOK_CLIENT_KEY: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
});

export default getConfig;
