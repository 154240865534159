/* eslint-disable react-hooks/exhaustive-deps */
import "./styles.scss";

import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { AnalyticsInstance } from "gyg_common";

export interface FooterProps {
  termsConditionsUrl: string;
  privacyPolicyUrl: string;
}

const Footer: React.FC<FooterProps> = ({
  termsConditionsUrl,
  privacyPolicyUrl,
}) => {
  const { t } = useTranslation();
  const privacyPolicyRef = useRef<HTMLAnchorElement>(null);
  const termsAndConditionRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const trackTermsAndConditionClick = () => {
      AnalyticsInstance.trackView({
        page_name: "terms_conditions",
        page_type: "terms_conditions_view",
      });
    };
    if (termsAndConditionRef.current) {
      termsAndConditionRef.current.addEventListener(
        "click",
        trackTermsAndConditionClick
      );
    }

    return () => {
      if (termsAndConditionRef.current) {
        termsAndConditionRef.current.removeEventListener(
          "click",
          trackTermsAndConditionClick
        );
      }
    };
  }, [termsAndConditionRef.current]);

  useEffect(() => {
    const trackPrivacyPolicyClick = () => {
      AnalyticsInstance.trackView({
        page_name: "privacy_policy",
        page_type: "privacy_policy_view",
      });
    };
    if (privacyPolicyRef.current) {
      privacyPolicyRef.current.addEventListener(
        "click",
        trackPrivacyPolicyClick
      );
    }

    return () => {
      if (privacyPolicyRef.current) {
        privacyPolicyRef.current.removeEventListener(
          "click",
          trackPrivacyPolicyClick
        );
      }
    };
  }, [privacyPolicyRef.current]);

  return (
    <footer className='footer' id='footer'>
      <div className='footer__container main-wrapper'>
        <ul className='footer__col'>
          <li className='footer__item--white'>
            {t("Dashboard:copyright", {
              year: `${new Date().getFullYear()}`,
            })}
          </li>
          <li className='footer__item'>
            <a
              href={t("Dashboard:GYGsiteLink")}
              rel='noreferrer'
              target='_blank'>
              {t("Dashboard:gygSite")}
            </a>
          </li>
        </ul>
        <ul className='footer__col'>
          <li className='footer__item'>
            <a
              ref={termsAndConditionRef}
              href={termsConditionsUrl}
              rel='noreferrer'
              target='_blank'>
              {t("Dashboard:webTerms")}
            </a>
          </li>
          <li className='footer__item'>
            <a
              ref={privacyPolicyRef}
              href={privacyPolicyUrl}
              rel='noreferrer'
              target='_blank'>
              {t("Dashboard:webPrivacyPolicy")}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
