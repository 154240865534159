import { Platform, StyleSheet } from "react-native";

import colours from "../styles/colours";
import { BorderRadius, Spacing } from "../styles/number";
import { errorStyle } from "../styles/shared";
import { Typography } from "../styles/typography";

export const wrapperStyles = (disablePadding?: boolean) =>
  StyleSheet.create({
    container: {
      marginBottom: !!disablePadding ? 0 : Spacing.Light,
    },
  });

export const styles = (
  error?: boolean,
  withPrefix?: boolean,
  focused?: boolean,
  disabled?: boolean
) =>
  StyleSheet.create({
    inputName: {
      ...Typography.bodyTwo,
      color: error ? colours.red : colours.darkGrey,
      fontSize: 14,
      marginBottom: Spacing.Thin,
    },
    icon: {
      width: 24,
      height: 24,
      tintColor: colours.midGrey,
      marginRight: Spacing.Thin,
    },
    closeIcon: {
      width: 24,
      height: 24,
      tintColor: colours.midGrey,
      ...Platform.select({
        web: {
          cursor: "pointer",
        },
      }),
      position: "absolute",
      right: Spacing.Thin,
    },
    redIcon: {
      width: 32,
      height: 32,
      tintColor: colours.red,
    },
    tickIcon: {
      width: 32,
      height: 32,
      tintColor: colours.green,
      ...Platform.select({
        web: {
          cursor: "pointer",
        },
      }),
      position: "absolute",
      right: Spacing.Thin,
    },
    inputContainer: {
      flexDirection: "row",
      width: "100%",
      height: 44,
      borderWidth: 2,
      borderColor: error
        ? colours.red
        : focused
        ? colours.darkGrey
        : colours.lightGrey,
      borderRadius: BorderRadius.Light,
      backgroundColor: disabled ? colours.borderGrey : colours.white,
      alignItems: "center",
      justifyContent: "space-between",
      textAlignVertical: "center",
      paddingRight: Spacing.Thin,
    },
    inputContainerWithIcon: {
      flexDirection: "row",
      width: "100%",
      height: 44,
      borderBottomWidth: 1,
      borderColor: error
        ? colours.red
        : focused
        ? colours.darkGrey
        : colours.lightGrey,
      borderRadius: BorderRadius.Light,
      alignItems: "center",
      justifyContent: "space-between",
      textAlignVertical: "center",
    },

    inputText: {
      ...Typography.bodyTwoBold,
      lineHeight: Platform.OS === "ios" ? 0 : 24,
      padding: Platform.OS === "ios" ? Spacing.ExtraLight : Spacing.Thin,
      paddingLeft: withPrefix
        ? Spacing.ExtraThin
        : Platform.OS === "ios"
        ? Spacing.ExtraLight
        : Spacing.Thin,
      width: withPrefix && error ? "75%" : "90%",
      fontSize: 14,
      textAlignVertical: "center",
      alignItems: "center",
      justifyContent: "space-between",
    },

    inputTextBorderBottom: {
      ...Typography.bodyTwoBold,
      lineHeight: Platform.OS === "ios" ? 0 : 24,
      padding: Platform.OS === "ios" ? Spacing.ExtraLight : Spacing.Thin,
      paddingLeft: 0,
      width: withPrefix && error ? "75%" : "90%",
      fontSize: 14,
      textAlignVertical: "center",
      alignItems: "center",
      justifyContent: "space-between",
    },

    placeholderText: {
      ...Typography.captionTwo,
      lineHeight: Platform.OS === "ios" ? 0 : 16,
      padding: Platform.OS === "ios" ? Spacing.ExtraLight : Spacing.Thin,
      paddingLeft: withPrefix
        ? Spacing.ExtraThin
        : Platform.OS === "ios"
        ? Spacing.ExtraLight
        : Spacing.Thin,
      width: withPrefix && error ? "75%" : "90%",
      fontSize: 14,
      letterSpacing: 0.25,
      textAlignVertical: "center",
      alignItems: "center",
      justifyContent: "space-between",
    },

    errorMsg: {
      ...errorStyle.caption,
      minHeight: Spacing.Light,
      marginTop: Spacing.ExtraThin,
    },

    editContainer: {
      flexDirection: "row",
      marginRight: Spacing.Thin,
      marginLeft: -Spacing.Thick,
      alignItems: "center",
    },

    editIcon: {
      width: 24,
      height: 24,
      tintColor: colours.darkGrey,
      marginRight: Spacing.ExtraThin,
      marginBottom: Platform.OS == "ios" ? Spacing.ExtraThin : 0,
    },

    editText: {
      ...Typography.titleFour,
      fontSize: 16,
      lineHeight: 20,
      letterSpacing: 0.5,
      textTransform: "uppercase",
      color: colours.darkGrey,
    },
    prefix: {
      ...Typography.bodyBold,
      fontSize: 14,
      justifyContent: "center",
      alignItems: "center",
      marginLeft: Spacing.Thin,
    },
  });
