import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, Platform, StyleSheet, Text, View } from "react-native";

import config from "config";
import { locales, StoreModules, StoreReduxModels } from "gyg_common";
import Accordion from "gyg_common/components/Accordion";
import { Store } from "gyg_common/redux_store/store/models";

import * as PlatformUtils from "../../modules/platformUtils";
import colours from "../styles/colours";
import { Typography } from "../styles/typography";
import BasicStoreDetails from "./BasicStoreDetails";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colours.white,
    flex: 1,
  },
  stayTunedView: {
    marginVertical: 32,
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  },
  listView: {
    borderTopColor: colours.lightGrey,
    borderTopWidth: 1,
  },
  alignCenter: {
    textAlign: "center",
  },
  basicDetailView: {
    flex: 1,
    borderBottomColor: colours.lightGrey,
    borderBottomWidth: 1,
  },
  detail: {
    flexDirection: "row",
  },
  left: {
    flex: 2,
    padding: 16,
  },
  right: {
    flex: 1,
    padding: 16,
  },
});

const keyExtractor = (item: Store, index: number) => item.name + index;

export interface StoresListProps {
  stores: StoreReduxModels.Store[];
  location: StoreModules.StoreMapper.StoreCoordinates | null;
  handleOrder: (store: StoreReduxModels.Store) => void;
  searchResultApply: boolean;
  withDelivery?: boolean;
}
export interface StatesListViewProps {
  item: string;
  index: number;
}
export interface StoresListViewProps {
  item: StoreReduxModels.Store;
  index: number;
}
export interface ExpandListState {
  [key: string]: boolean;
}

const hasOnlyOneExpandableItem = (
  storesGroupedByState: StoreModules.StoreMapper.StoreGroupByState
) => {
  return Object.keys(storesGroupedByState).length === 1;
};

const StoresList: React.FC<StoresListProps> = ({
  stores,
  location,
  handleOrder,
  searchResultApply,
  withDelivery,
}) => {
  const storesGroupByState = StoreModules.StoreMapper.groupByState(stores);
  const isOnlyOneExpandableItem = hasOnlyOneExpandableItem(storesGroupByState);

  const initialExpandList = Object.keys(storesGroupByState).reduce(
    (pre, cur, index) => ({
      ...pre,
      // expand if there is only one state
      // otherwise check if location is available
      // if it is, expand the closest state
      // otherwise don't expand
      [cur]:
        isOnlyOneExpandableItem || location
          ? index === 0
            ? true
            : false
          : false,
    }),
    {}
  );
  const [expandList, updateExpandList] =
    useState<ExpandListState>(initialExpandList);
  const handleUpdateExpandList = (key: string, expand: boolean) => {
    const nextState = { [key]: !expand };
    updateExpandList({ ...expandList, ...nextState });
  };

  useEffect(() => {
    const resetAll = () => {
      updateExpandList((state) => {
        return {
          ...state,
          ...initialExpandList,
        };
      });
    };
    if (searchResultApply && stores[0]) {
      handleUpdateExpandList(stores[0].state as string, false);
    } else {
      resetAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResultApply, stores, updateExpandList]);
  const { t } = useTranslation();

  const renderStoreListItem = ({ item }: StoresListViewProps) => {
    return (
      <View style={styles.basicDetailView}>
        <BasicStoreDetails
          key={item.name}
          store={item}
          buttonVersion={"secondary"}
          showAddress={withDelivery}
          withDelivery={withDelivery}
          handleOrder={() => handleOrder(item)}
        />
      </View>
    );
  };

  const renderStateList = ({ item }: StatesListViewProps) => {
    const state = item;
    return (
      <Accordion
        {...PlatformUtils.generateTestID(Platform.OS, `StoreListState${state}`)}
        key={state}
        onExpandChanged={handleUpdateExpandList}
        expand={expandList[state]}
        title={state}
        id={state}>
        {expandList[state] && (
          <View>
            <FlatList
              renderItem={renderStoreListItem}
              key={state}
              keyExtractor={keyExtractor}
              data={storesGroupByState[state]}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
            />
            {config.version === locales.US &&
              storesGroupByState[state].length <=
                StoreModules.StoreMapper.STAY_TUNE_THRESHOLD && (
                <View key={state + "stayTuned"} style={styles.stayTunedView}>
                  <Text
                    style={{ ...styles.alignCenter, ...Typography.largeTitle }}>
                    {t("StoreSearch:captionTitle")}
                  </Text>
                  <Text
                    style={{ ...styles.alignCenter, ...Typography.bodyBold }}>
                    {t("StoreSearch:captionCopy")}
                  </Text>
                </View>
              )}
          </View>
        )}
      </Accordion>
    );
  };

  return (
    <View style={styles.container}>
      <FlatList
        keyExtractor={(item) => item}
        style={styles.listView}
        bounces={false}
        renderItem={renderStateList}
        data={StoreModules.StoreMapper.sortStates(
          location,
          config.version,
          Object.keys(storesGroupByState),
          stores
        )}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      />
    </View>
  );
};

export default StoresList;
