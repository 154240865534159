import config from "../../../config";
import {
  AddFavouriteRequest,
  GetFavouriteResponse,
  UpdateFavouriteRequest,
} from "../../../redux_store/favourite/models";
import { SentryLoggerInstance } from "../../../sentry";
import { httpClient } from "../../httpClient";

export async function getFavourite(): Promise<GetFavouriteResponse[]> {
  try {
    console.debug("API: GET favourites/products");

    const userToken = config.accessToken;
    const response = await httpClient({
      url: `favourites/products?offset=0&limit=100`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userToken}`,
      },
    });

    return response.data as GetFavouriteResponse[];
  } catch (e) {
    SentryLoggerInstance.sentryEndpointError(e, "GET favourites/products");
    throw e;
  }
}

export async function createFavouriteItem(
  favouriteRequest: AddFavouriteRequest
): Promise<string> {
  try {
    console.debug("API: POST favourites/products");

    const userToken = config.accessToken;
    const response = await httpClient({
      url: `favourites/products`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userToken}`,
      },
      data: favouriteRequest,
    });
    return response.data as string;
  } catch (e) {
    SentryLoggerInstance.sentryEndpointError(e, "POST favourites/products");
    throw e;
  }
}

export async function deleteFavouriteItem(
  favouriteId: number
): Promise<string> {
  try {
    console.debug(`API: DELETE favourites/${favouriteId}`);

    const userToken = config.accessToken;
    const response = await httpClient({
      url: `favourites/${favouriteId}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userToken}`,
      },
    });
    return response.data as string;
  } catch (e) {
    SentryLoggerInstance.sentrySetTag("favourite.id", favouriteId.toString());
    SentryLoggerInstance.sentryEndpointError(
      e,
      `DELETE favourites/<favouriteId>`
    );
    throw e;
  }
}

export async function renameFavourite(body: UpdateFavouriteRequest) {
  try {
    console.debug(`API: PATCH favourites/products/${body.favouriteId}`);

    const userToken = config.accessToken;

    const response = await httpClient({
      url: `favourites/products/${body.favouriteId}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userToken}`,
      },
      data: body,
    });

    return response.data;
  } catch (e) {
    SentryLoggerInstance.sentrySetTag(
      "favourite.id",
      body.favouriteId.toString()
    );
    SentryLoggerInstance.sentryEndpointError(
      e,
      `PATCH favourites/products/<favouriteId>`
    );
    throw e;
  }
}
