import axios from "axios";

import { locales } from "../../../locales/locales.enum";
import { GetStoreOfferResponse } from "../../../redux_store/promo/models";
import { SentryLoggerInstance } from "../../../sentry";
import { httpClient } from "../../httpClient";
import {
  GooglePlaceDetailResponse,
  GooglePlaceResponse,
  OrderTimeSlotsResponse,
  StoreResponse,
} from "./model";

export async function getStores(): Promise<StoreResponse[]> {
  try {
    const response = await httpClient({
      url: "store",
      method: "GET",
    });
    return response.data as StoreResponse[];
  } catch (e) {
    SentryLoggerInstance.sentryEndpointError(e, "GET store");
    throw e;
  }
}

export async function getOrderTimeSlots(
  storeId: number,
  orderValue: number
): Promise<OrderTimeSlotsResponse> {
  try {
    const response = await httpClient({
      url: `store/${storeId}/ordertimeslots/${orderValue}`,
      method: "GET",
    });
    return response.data as OrderTimeSlotsResponse;
  } catch (e) {
    SentryLoggerInstance.sentrySetTag("order.value", orderValue.toString());
    SentryLoggerInstance.sentryEndpointError(
      e,
      `GET store/${storeId}/ordertimeslots/<orderValue>`
    );
    throw e;
  }
}

export async function googlePlaceSearch(
  input: string,
  apiKey: string,
  locationString: string,
  locale: locales
): Promise<GooglePlaceResponse> {
  try {
    console.debug(
      "API: GET https://maps.googleapis.com/maps/api/place/autocomplete/json"
    );

    const response = await axios({
      url: `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&types=(regions)&components=country:${locale}&origin=${locationString}&key=${apiKey}`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (e) {
    SentryLoggerInstance.sentryEndpointError(
      e,
      "GET https://maps.googleapis.com/maps/api/place/autocomplete/json"
    );
    throw e;
  }
}

export async function googlePlaceDetailSearch(
  placeId: string,
  apiKey: string
): Promise<GooglePlaceDetailResponse> {
  try {
    console.debug(
      "API: GET https://maps.googleapis.com/maps/api/place/details/json"
    );

    const response = await axios({
      url: `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=geometry&key=${apiKey}`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (e) {
    SentryLoggerInstance.sentryEndpointError(
      e,
      "GET https://maps.googleapis.com/maps/api/place/details/json"
    );
    throw e;
  }
}

export async function getStoreOffers(): Promise<GetStoreOfferResponse[]> {
  try {
    const response = await httpClient({
      url: "store/offers",
      method: "GET",
    });
    return response.data as GetStoreOfferResponse[];
  } catch (e) {
    SentryLoggerInstance.sentryEndpointError(e, "GET store/offers");
    throw e;
  }
}
