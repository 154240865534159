export enum BranchPaths {
  Promo = "Promo",
  Restaurant = "Restaurant",
  Restaurants = "Restaurants",
  QRscanner = "QRscanner",
  Menu = "Menu",
  OrderSetup = "OrderSetup",
  Cart = "Cart",
  OrderStatus = "OrderStatus",
  Registration = "Registration",
  ChangePassword = "ChangePassword",
  Login = "Login",
  MyGomex = "MyGomex",
  Barcode = "Barcode",
  FindMyPoints = "FindMyPoints",
  MyGyg = "MyGyg",
  Notifications = "Notifications",
  NotificationPermission = "NotificationPermission",
  LocationPermissionModal = "LocationPermissionModal",
  RecentOrders = "RecentOrders",
  PersonalDetails = "PersonalDetails",
  ForgotPassword = "ForgotPassword",
  ReferAFriend = "ReferAFriend",
  ContactUs = "ContactUs",
  TermsConditions = "TermsConditions",
  PrivacyPolicy = "PrivacyPolicy",
}

export enum BranchEvents {
  Purchase = "PURCHASE",
  CompleteRegistration = "COMPLETE_REGISTRATION",
}
