import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";

import { MenuStructure } from "../../redux_store/menu/models";
import { SentryLoggerInstance } from "../../sentry/";
import {
  getClientToken,
  initPayment,
  initPaymentAuthorized,
} from "../../services/api/checkout";
import {
  ClientTokenReponse,
  GetClientTokenPayload,
} from "../../services/api/checkout/model";
import { timeoutCode } from "../../services/httpClient";
import { braintreeActions } from "../braintree/braintree.slice";
import { cartActions } from "../cart/cart.slice";
import { errorActions } from "../error/error.slice";
import { ErrorResponse } from "../error/models";
import { buildErrorResponse } from "../error/utils";
import { guestActions } from "../guest/guest.slice";
import { loyaltyActions } from "../loyalty/loyalty.slice";
import { orderActions } from "../order/order.slice";
import { CheckoutAction, CheckoutActionType } from "./checkout.slice";
import { CheckoutResponse, PaymentPayload } from "./model";

function* handleGetClientToken(action: CheckoutActionType) {
  try {
    const result: ClientTokenReponse = yield call(
      getClientToken,
      action.payload as GetClientTokenPayload
    );
    yield put(CheckoutAction.getClientTokenSuccess(result));
  } catch (e) {
    yield put(CheckoutAction.getClientTokenFailure(buildErrorResponse(e)));
  }
}

export function* watchGetClientToken(): Generator<
  Effects.ForkEffect<never>,
  void,
  MenuStructure
> {
  yield takeLatest(CheckoutAction.getClientToken.type, handleGetClientToken);
}

function* handleInitPayment(action: CheckoutActionType) {
  try {
    const result: CheckoutResponse = yield call(
      initPayment,
      action.payload as PaymentPayload
    );
    yield put(CheckoutAction.initPaymentSuccess(result));

    //handle error to sentry, as it shouldn't cause error handling logic to run as it is not API error
    try {
      yield put(guestActions.storeGuestOrder(result));
      yield put(orderActions.updateCurrentOrderId(result?.order?.orderId));
      yield put(cartActions.clearCart());
      yield put(orderActions.clearOrderResponse());
      yield put(orderActions.setOrderResponse(null));
      yield put(braintreeActions.clearPaymentPayload());
      yield put(orderActions.clearOrderSetup());
    } catch (e) {
      SentryLoggerInstance.sentryCaptureCustomError(
        "handleInitPayment: clear redux states error:",
        e
      );
    }
  } catch (e) {
    if (e.code === timeoutCode) {
      yield put(CheckoutAction.error(undefined));
      yield put(braintreeActions.setTimeoutError(true));
    } else {
      const errorResponse: ErrorResponse = {
        ...e.response?.data,
        statusCode: e.response?.status ?? e.code,
      };
      yield put(CheckoutAction.error(errorResponse));
      yield put(errorActions.setErrorAPIResponse(errorResponse));
    }
  }
}

function* handleInitPaymentAuthorized(action: CheckoutActionType) {
  try {
    const result: CheckoutResponse = yield call(
      initPaymentAuthorized,
      action.payload as PaymentPayload
    );
    yield put(CheckoutAction.initPaymentSuccess(result));
    //handle error to sentry, as it shouldn't cause error handling logic to run as it is not API error
    try {
      yield put(cartActions.clearCart());
      yield put(orderActions.clearOrderResponse());
      yield put(orderActions.setOrderResponse(null));
      yield put(braintreeActions.clearPaymentPayload());
      yield put(loyaltyActions.clearActiveReward());
      yield put(orderActions.clearOrderSetup());
    } catch (e) {
      SentryLoggerInstance.sentryCaptureCustomError(
        "handleInitPaymentAuthorized: clear redux states error:",
        e
      );
    }
  } catch (e) {
    if (e.code === timeoutCode) {
      yield put(braintreeActions.setTimeoutError(true));
    } else {
      const errorResponse: ErrorResponse = {
        ...e.response?.data,
        statusCode: e.response?.status ?? e.code,
      };
      yield put(CheckoutAction.error(errorResponse));
      yield put(errorActions.setErrorAPIResponse(errorResponse));
    }
  }
}

export function* watchInitPayment(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(CheckoutAction.initPayment.type, handleInitPayment);
}

export function* watchInitPaymentAuthorized(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    CheckoutAction.initPaymentAuthorized.type,
    handleInitPaymentAuthorized
  );
}

const saga = [
  fork(watchGetClientToken),
  fork(watchInitPayment),
  fork(watchInitPaymentAuthorized),
];

export default saga;
