import React, { useState } from "react";
import {
  Text,
  View,
  Pressable,
  Image,
  Platform,
  ImageSourcePropType,
  StyleSheet,
} from "react-native";
import { useTranslation } from "react-i18next";
import MiddleModal from "../modals/MiddleModal";
import { Typography } from "../styles/typography";
import closeIcon from "../assets/icons/icon_close.png";
import * as PlatformUtils from "../../modules/platformUtils";
import { RatingCircle } from "./RatingCircle";
import colours from "../styles/colours";
import CardSticker from "../CardSticker";
import defaultImage from "../assets/images/logo_primary_gyg.png";
import hollowStar from "../assets/icons/icon_star_default.png";
import hollowStarHover from "../assets/icons/icon-star-hover.png";
import { BorderRadius, Spacing } from "../styles/number";

export interface IRatingExperienceProps {
  showRating: boolean;
  numberOfOtherProducts: number | undefined;
  productName: string;
  orderNumber: string | undefined;
  productImage: string | undefined;
  closeModal: () => void;
  onRatingPress: (ratingValue: number) => void;
}

const styles = StyleSheet.create({
  title: {
    ...Typography.titleOne,
    marginBottom: Spacing.Regular,
    marginTop: Spacing.Medium,
    textAlign: "center",
  },
  container: {
    padding: Spacing.Light,
    paddingBottom: Spacing.MediumThick,
  },
  bodyText: {
    ...Typography.bodyBold,
    marginBottom: Spacing.Thin,
    color: colours.darkGrey,
    textAlign: "center",
  },
  rateText: {
    ...Typography.titleThree,
    textAlign: "center",
    marginBottom: Spacing.Light,
    marginTop: Spacing.Medium,
  },
  otherProducts: {
    ...Typography.body,
    textAlign: "center",
    marginTop: Spacing.Thin,
  },
  iconContainer: {
    width: 32,
    height: 32,
    position: "absolute",
    right: Spacing.Light,
    top: Spacing.Light,
  },
  icon: {
    width: 32,
    height: 32,
  },
  ratingStarWrapper: {
    flexDirection: "row",
    justifyContent: "center",
  },
  productName: {
    ...Typography.smallProductTitle,
    paddingRight: Spacing.ExtraLight,
    flexShrink: 1,
    alignSelf: "center",
  },
  productImage: {
    width: 110,
    height: 88,
    borderRadius: BorderRadius.Medium,
  },
  defaultImage: {
    borderRadius: BorderRadius.Medium,
    width: 72,
    height: 72,
    marginRight: Spacing.Light,
  },
  productContainer: {
    flexDirection: "row",
  },
  productContainerWithPadding: {
    flexDirection: "row",
    padding: Spacing.Light,
  },
});

export const RateExperience: React.FC<IRatingExperienceProps> = ({
  showRating,
  numberOfOtherProducts,
  productName,
  orderNumber,
  productImage,
  closeModal,
  onRatingPress,
}) => {
  const { t } = useTranslation();

  const [indexOnHover, setIndexOnHover] = useState<number>(-1);

  const onHover = (ratingValue: number) => setIndexOnHover(ratingValue - 1);
  const onHoverEnd = () => setIndexOnHover(-1);

  const renderRatingCircles = () => {
    return Array(5)
      .fill(0)
      .map((_, index) => (
        <RatingCircle
          key={index}
          image={index <= indexOnHover ? hollowStarHover : hollowStar}
          onHover={onHover}
          onHoverEnd={onHoverEnd}
          starRatingValue={index + 1}
          onPress={onRatingPress}
        />
      ));
  };

  return (
    <MiddleModal isVisible={showRating} onAndroidBackPress={closeModal}>
      <View style={styles.container}>
        <Pressable
          {...PlatformUtils.generateTestID(
            Platform.OS,
            "close-icon-rating-modal"
          )}
          style={styles.iconContainer}
          onPress={closeModal}>
          <Image
            style={styles.icon}
            source={closeIcon as ImageSourcePropType}
          />
        </Pressable>
        <Text style={styles.title}>{t("UserManagement:ratingTitle")}</Text>
        <Text style={styles.bodyText}>
          {t("UserManagement:orderNumber", {
            orderNo: orderNumber,
          })}
        </Text>
        <CardSticker>
          <View
            style={
              productImage
                ? styles.productContainer
                : styles.productContainerWithPadding
            }>
            <Image
              style={productImage ? styles.productImage : styles.defaultImage}
              source={productImage ? { uri: productImage } : defaultImage}
              defaultSource={defaultImage}
            />
            <Text style={styles.productName}>{productName}</Text>
          </View>
        </CardSticker>
        {!!numberOfOtherProducts && (
          <Text
            {...PlatformUtils.generateTestID(
              Platform.OS,
              "other-rating-products"
            )}
            style={styles.otherProducts}>
            {t("UserManagement:otherProducts", {
              numberOfProducts: numberOfOtherProducts,
            })}
          </Text>
        )}

        <Text style={styles.rateText}>{t("UserManagement:pleaseRate")}</Text>
        <View style={styles.ratingStarWrapper}>{renderRatingCircles()}</View>
      </View>
    </MiddleModal>
  );
};
