import locationIcon from "assets/icons/generic_location_icon.png";
import locationIconGYG from "assets/icons/gyg_icon.png";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";

import { PlatformUtils, StoreModules, StoreReduxModels } from "gyg_common";
import CardSticker from "gyg_common/components/CardSticker";
import SearchTextInput from "gyg_common/components/SearchTextInput";
import { StoreStatus } from "gyg_common/components/StoreDetails";
import colours from "styles/colours";
import { Typography } from "styles/typography";

const styles = StyleSheet.create({
  card: {
    padding: 16,
    borderBottomColor: colours.lightGrey,
    borderBottomWidth: 1,
  },
  result: {
    flexDirection: "row",
  },
  icon: {
    width: 44,
    height: 44,
  },
  detail: {
    flex: 1,
    flexDirection: "column",
    marginLeft: 16,
  },
  storeDetail: {
    flex: 1,
    flexDirection: "column",
  },
});

export interface StoreSearchProps {
  searchResult: ResultListItem[];
  isFocused: boolean;
  setFocused: (focus: boolean) => void;
  handleStoreSearch: (searchText: string) => void;
  onSelectedStore: (store: StoreReduxModels.Store) => void;
  onSelectedRegion: (store: StoreReduxModels.GooglePlaceSearchResult) => void;
  onClearSearch: () => void;
  headerHeight: number;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}
export interface SearchResultListProps {
  item: ResultListItem;
  index: number;
}
export interface ResultListItem {
  data: StoreReduxModels.Store | StoreReduxModels.GooglePlaceSearchResult;
  type: StoreModules.StoreModels.ResultListItemType;
}

const StoreSearch: React.FC<StoreSearchProps> = ({
  isFocused,
  setFocused,
  searchResult,
  handleStoreSearch,
  onSelectedRegion,
  onSelectedStore,
  onClearSearch,
  searchText,
  setSearchText,
}) => {
  const { t } = useTranslation();
  const { isTabletScreen } = useMediaQuery();

  const handleSelectedRegion = (
    data: StoreReduxModels.GooglePlaceSearchResult
  ) => {
    setSearchText(data.name);
    onSelectedRegion(data);
    setFocused(false);
  };

  const handleSelectedStore = (data: StoreReduxModels.Store) => {
    setSearchText(data.name);
    onSelectedStore(data);
    setFocused(false);
  };

  const renderStoreResult = (data: StoreReduxModels.Store) => {
    const storeOpenStatus: StoreReduxModels.StoreOpenTime =
      StoreModules.StoreUtils.checkStoreOpenHours(
        data.tradingHours,
        data.timeZoneInfo.storeTimeZone
      );
    return (
      <TouchableWithoutFeedback
        {...PlatformUtils.generateTestID(
          Platform.OS,
          `StoreResult${data.name}`
        )}
        onPress={() => {
          handleSelectedStore(data);
        }}>
        <View style={styles.result}>
          <View>
            <Image
              style={styles.icon}
              source={locationIconGYG as ImageSourcePropType}
            />
          </View>
          <div className={"store-search__store-result__detail"}>
            <div className={"store-search__store-result__detail--left"}>
              <View style={styles.storeDetail}>
                <Text style={Typography.bodyBold}>{data.name}</Text>
                {data.distanceText && (
                  <Text style={Typography.captionOne}>{data.distanceText}</Text>
                )}
              </View>
            </div>

            <div className={"store-search__store-result__detail--right"}>
              <Text style={Typography.bodyTwo}>
                {StoreModules.StoreUtils.getAddress(data)}
              </Text>
              <StoreStatus {...storeOpenStatus} />
            </div>
          </div>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  const renderPlaceResult = (
    data: StoreReduxModels.GooglePlaceSearchResult
  ) => {
    return (
      <TouchableWithoutFeedback
        {...PlatformUtils.generateTestID(
          Platform.OS,
          `PlaceResult${data.name}`
        )}
        onPress={() => {
          handleSelectedRegion(data);
        }}>
        <View style={styles.result}>
          <View>
            <Image
              style={styles.icon}
              source={locationIcon as ImageSourcePropType}
            />
          </View>
          <View style={styles.detail}>
            <Text style={Typography.bodyBold}>{data.name}</Text>
            <Text style={Typography.captionOne}>{data.address}</Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  const renderResultList = ({ item, index }: SearchResultListProps) => {
    return (
      <div
        key={item.data.name + item.type + index}
        className={"store-search__store-result"}>
        <View style={styles.card}>
          {item.type ===
          StoreModules.StoreModels.ResultListItemType.FUZZY_SEARCH
            ? renderStoreResult(item.data as StoreReduxModels.Store)
            : renderPlaceResult(
                item.data as StoreReduxModels.GooglePlaceSearchResult
              )}
        </View>
      </div>
    );
  };

  const handleChangeText = (text: string) => {
    setSearchText(text);
    if (text.length > 2 || text === "") {
      handleStoreSearch(text);
    }
  };
  const handleClearSearch = () => {
    handleChangeText("");
    onClearSearch();
  };

  useEffect(() => {
    const onClickListener = (event: MouseEvent) => {
      const searchResultView = document.getElementById("store-search-result");
      if (!searchResultView?.contains(event.target as Node)) {
        setFocused(false);
      }
      event.stopPropagation();
    };
    window.addEventListener("click", onClickListener);
    return () => {
      window.removeEventListener("click", onClickListener);
    };
  }, [setFocused]);

  return (
    <div className={"store-search__container"} id={"store-search-result"}>
      <SearchTextInput
        placeHolder={t("StoreSearch:searchPlaceholder")}
        onClear={handleClearSearch}
        onSearch={() => handleStoreSearch(searchText)}
        isFocused={isFocused}
        setFocused={setFocused}
        onChangeText={(text) => {
          handleChangeText(text);
        }}
        value={searchText}
      />
      {isFocused &&
        (isTabletScreen && searchResult.length === 0 ? null : (
          <div
            className={"store-search__search-result"}
            id={"SearchResultListView"}>
            <CardSticker>
              {searchResult.map((n, index) => {
                return renderResultList({ item: n, index: index });
              })}
            </CardSticker>
          </div>
        ))}
    </div>
  );
};

export default StoreSearch;
