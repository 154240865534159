export interface RegistrationPayload {
  emailAddress: string;
  mobileNumber: string;
  firstName: string;
  lastName: string;
  password: string;
  code: number;
  receiveEmails?: boolean;
  deviceId: string;
  oldDeviceId?: string | null;
  dateOfBirth: string;
}

export interface SocialSignInPayload {
  token: string;
  deviceId: string;
  redirectUri: string;
  oldDeviceId?: string | null;
}

export interface SocialRegistrationPayload {
  verificationId: string;
  mobileNumber: string;
  firstName: string;
  lastName: string;
  code: string;
  deviceId: string;
  oldDeviceId?: string | null;
  signupProgressToken: string;
}

export interface SocialRegistrationResponse {
  userId: string;
  emailAddress: string;
  mobileNumber: string;
  firstName: string;
  lastName: string;
  branchId: string;
}

export enum VerificationCodeChannel {
  MOBILE = "mobile",
  EMAIL = "email",
}

export interface VerificationCodePayload {
  channel: VerificationCodeChannel;
  mobile: string;
}

export interface VerifyResponse {
  message: string;
}

export interface VerifyCodePayload {
  code: number;
  mobileNumber: string;
  isLoggedIn: boolean;
}

export interface VerifyCodeResponse {
  message: string;
}
