import React from "react";
import { useTranslation } from "react-i18next";
import { Image, ImageSourcePropType, Platform, Text, View } from "react-native";

import illustrationError from "../../../components/assets/images/illustration_error.png";
import PrimaryBlackButton from "../../../components/Buttons/PrimaryBlackButton";
import styles from "./ErrorView.styles";

export interface ErrorProps {
  heading?: string;
  message: string;
  btnTitle?: string;
  img?: ImageSourcePropType;
  label?: string;
  onTryAgain?: () => void;
}

const ErrorView: React.FC<ErrorProps> = ({
  heading,
  message,
  onTryAgain,
  btnTitle,
  img,
  label,
}) => {
  const { t } = useTranslation();
  const errorHeading = heading
    ? heading
    : t("OrderManagement:defaultErrorHeading");
  return (
    <View style={styles().container}>
      <View style={styles().errorImage}>
        <Image
          style={Platform.OS == "web" ? styles().errorImageWeb : null}
          source={img ? img : (illustrationError as ImageSourcePropType)}
        />
      </View>
      <View
        style={
          Platform.OS === "ios"
            ? styles().errorHeadingWrapperIOS
            : styles().errorHeadingWrapperAndroid
        }>
        <Text style={styles().errorHeading}>{errorHeading}</Text>
      </View>
      <View style={styles().errorMessageWrapper}>
        {label && <Text style={styles().errorMessage}>{label}</Text>}
        <Text
          style={[
            styles().errorMessage,
            label ? styles().errorMessageWithLabel : null,
          ]}>
          {message}
        </Text>

        {onTryAgain && (
          <View style={styles().tryAgain}>
            <PrimaryBlackButton
              buttonName={btnTitle ? btnTitle : t("UserManagement:tryAgain")}
              onClick={onTryAgain}
            />
          </View>
        )}
      </View>
    </View>
  );
};

export default ErrorView;
