import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Screens } from "navigation/const";

import { AllActionsOf } from "../utils/Actions";
import { NavigationState } from "./models";

const initialState: NavigationState = {
  showOrderSetup: false,
  orderSetupNavigation: Screens.Menu,
};

const menuSlice = createSlice({
  name: "navigation",
  initialState: initialState,
  reducers: {
    setNavigationState(state, action: PayloadAction<NavigationState>) {
      state.showOrderSetup = action.payload.showOrderSetup;
      state.orderSetupNavigation = action.payload.orderSetupNavigation;
    },
    resetNavigationState(state) {
      state.orderSetupNavigation = initialState.orderSetupNavigation;
      state.showOrderSetup = initialState.showOrderSetup;
    },
    setOpenOrderSetup(state, action: PayloadAction<boolean>) {
      state.showOrderSetup = action.payload;
    },
    setOrderSetupNavigation(state, action: PayloadAction<string>) {
      state.orderSetupNavigation = action.payload;
    },
  },
});

const { actions, reducer } = menuSlice;
export type NavigationActionsType = AllActionsOf<typeof actions>;
export {
  initialState,
  actions as navigationActions,
  reducer as navigationReducer,
};
