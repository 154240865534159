import moment from "moment";
import { Store, StoreMenu, StoreOpenTime } from "redux_store/store/models";

import { CartItem } from "../../redux_store/cart/models";
import { MenuStructure } from "../../redux_store/menu/models";
import {
  GetOrderResponse,
  OrderResponse,
} from "../../services/api/order/model";
import { OrderTimeSlotsResponse } from "../../services/api/store/model";
import { CheckoutResponse } from "../checkout/model";
import { ErrorResponse } from "../error/models";

export enum OrderChannel {
  WEB = 2,
  APP = 1,
}

export enum OrderType {
  STANDARD = 1,
  GROUP_ORDER = 2,
}

export enum CollectionType {
  PICK_UP = "PICKUP",
  TABLE_SERVICE = "TABLE",
  DRIVE_THRU = "DRIVETHRU",
  DELIVERY = "DELIVERY",
}

export enum DeliveryCollectionType {
  MEET_AT_DOOR = "MEET_AT_DOOR",
  LEAVE_AT_DOOR = "LEAVE_AT_DOOR",
}

export enum TableServiceType {
  MANUAL = "MANUAL",
  QR = "QR",
}

export enum GroupOrderType {
  SINLGE = "SINGLE",
  SPLIT = "SPLIT",
}

export interface OrderTimeSetup {
  pickupDay: moment.Moment;
  pickupHour: number;
  pickupMinute: number;
  pickupAmPm: boolean;
  pickupASAP: boolean;
}

export interface OrderCollectionTypeProps {
  orderCollectionType: CollectionType;
}

export interface DeliveryOrder {
  quoteId?: string;
  fee?: number;
  pickupGooglePlaceId: string;
  dropoffGooglePlaceId: string | number;
  dropoffSuiteAndFloor?: string;
  dropoffBusinessName?: string;
  dropoffOption: DeliveryCollectionType;
  dropoffNotes?: string;
  deliveryTimeMins: number | string | undefined;
  trackingUrl?: string;
  trackingUrlExpiresAt?: string;
}

export interface SetupOrderProps {
  orderASAP: boolean;
  orderTime: number | null;
  orderCollectionType: CollectionType;
  collectionStore?: Store;
  tableNumber?: string;
  tableInit?: TableServiceType;
  delivery?: DeliveryOrder;
}
export interface GetOrderProps {
  sessionId: string;
}

export interface OrderState {
  sessionId?: string;
  //TimeStamp
  orderInitialTime: number;
  //TimeStamp
  orderTime: number | null;
  orderCollectionType: CollectionType;
  orderASAP: boolean;
  email: string;
  includeCutlery: boolean;
  collectionStore?: Store;
  deliveryAddress?: string;
  deliveryTimeMins?: number;
  tableNumber: string;
  driveThruOptionAvailable: boolean;
  getOrderLoading: boolean;
  getOrderByIdSuccess: boolean;
  getOrderError: ErrorResponse;
  isDisplayGenericOrderError: boolean;
  updateOrderLoading: boolean;
  orderTimeUpdated: boolean;
  currentOrderId: string | null;
  guestOrderId: string | null;
  getOrderResponse: null | OrderResponse;
  userConfirmedOrderSetup: boolean;
  hasOrderPending: boolean;
  orderStatus: GetOrderResponse;
  currentOrders: CheckoutResponse[];
  recentOrders: CheckoutResponse[];
  tableInit: TableServiceType;
  showMoreAvailable: boolean;
  addToAccountLoading: boolean;
  addToAccountSuccess: boolean;
  generalError: string;
  getOrderByIdResponse: CheckoutResponse;
  getOrderByIdError: boolean;
  getAuthenticatedOrdersLoading: boolean;
  ratingOrder: RatedOrderProps;
  ignoreTimeChange: boolean;
  ratingValue: number | null;
  delivery: DeliveryOrder | null;
}

export interface AddToAccountError {
  message: string;
  code: number;
}

export interface SetCurrentOrRecentOrders {
  currentOrRecentOrders: CheckoutResponse[];
  clearCurrentAndRecentOrders: boolean;
}

export interface Product {
  productId: number;
  name: string;
  quantity: number;
  price: number;
}

export interface StoreAddress {
  streetAddress: string;
  city: string;
  state: string;
  postcode: string;
  latitude: number;
  longitude: number;
}

export interface GetAuthenticatedOrdersPayload {
  offset?: number;
}

export interface RatedOrderProps {
  cartSize: number | undefined;
  cartAmount: number | undefined;
  storeName: string | undefined;
  storeId: string | undefined;
  orderNumber?: string;
  orderId: string | undefined;
  orderType: string | undefined;
  productImage?: string;
  numberOfOtherProducts?: number;
  highestPriceProduct?: CartItem;
}

export interface RecentAddress {
  address: string;
  googlePlaceId: string | number;
  latitude: number;
  longitude: number;
}

export type DeliveryModalState = {
  OrderCollectionType?: CollectionType;
  AutoCompleteResult?: RecentAddress[];
  DeliveryAddress?: RecentAddress;
  Apartment?: string;
  Business?: string;
  DropOffOption?: DeliveryCollectionType;
  DropOffOptionTitle?: string;
  Instructions?: string;
  DeliveryFee?: number;
  DeliveryTimeMins?: number | string;
  DeliveryQuoteId?: string;
  GooglePlaceId?: string;
  StoreDistance?: string;
  ChosenStore?: Store;
};

export interface PickupModalState {
  OrderCollectionType?: CollectionType;
  PickupDetails?: {
    orderASAP: boolean;
    orderTime: number | null;
    orderCollectionType: CollectionType;
    tableNumber?: string;
    tableInit?: TableServiceType;
    menuStructure?: MenuStructure;
    menuOpenTime?: StoreMenu;
    timeSlots?: OrderTimeSlotsResponse;
    storeOpenHours?: StoreOpenTime;
  };
  ChosenStore?: Store;
}

export type ModalState = PickupModalState | DeliveryModalState;
