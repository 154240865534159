import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";

import deliveryIcon from "../../components/assets/icons/services/icon_delivery_no_bg.png";
import locationIcon from "../../components/assets/icons/services/location_small.png";
import { SearchResult } from "../Stores/DeliveryStoreDetails";
import { Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

const styles = StyleSheet.create({
  storeStatus: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    paddingTop: Spacing.Thin,
    flex: 1,
  },
  text: {
    ...Typography.bodyTwo,
    alignItems: "center",
    lineHeight: 19,
    flexWrap: "wrap",
  },
  textBold: {
    ...Typography.bodyTwoBold,
    alignItems: "center",
    lineHeight: 19,
    flexWrap: "wrap",
  },
  dot: {
    marginHorizontal: Spacing.Thin,
  },
  icon: {
    width: 15,
    height: 15,
  },
  iconContainer: {
    marginRight: Spacing.Thin,
    alignItems: "center",
    justifyContent: "center",
    width: 20,
  },
});

export interface StoreDeliveryInfoProps {
  searchResult: SearchResult;
  basicInfo?: boolean;
}

const StoreDeliveryInfo: React.FC<StoreDeliveryInfoProps> = (
  props: StoreDeliveryInfoProps
) => {
  const { searchResult, basicInfo } = props;

  const deliveryFeeDollars = searchResult.deliveryFee ?? 0;
  return !basicInfo ? (
    <View>
      <View style={styles.storeStatus}>
        <View style={styles.iconContainer}>
          <Image
            resizeMode='contain'
            source={deliveryIcon}
            style={styles.icon}
          />
        </View>
        <Text
          style={styles.textBold}>{`${searchResult.deliveryTime} mins`}</Text>
        <Text style={[styles.text, styles.dot]}>{"•"}</Text>
        <Text style={styles.text}>{`$${deliveryFeeDollars.toFixed(
          2
        )} Delivery fee`}</Text>
      </View>
      {searchResult.distance && (
        <View style={styles.storeStatus}>
          <View style={styles.iconContainer}>
            <Image
              resizeMode='contain'
              source={locationIcon}
              style={styles.icon}
            />
          </View>
          <Text style={styles.text}>{searchResult.distance}</Text>
        </View>
      )}
    </View>
  ) : (
    <View>
      <View style={styles.storeStatus}>
        <Image resizeMode='contain' source={deliveryIcon} style={styles.icon} />
        <Text style={styles.text}>{`${searchResult.deliveryTime} mins`}</Text>
        <Text style={[styles.text, styles.dot]}>{" • "}</Text>
        <Text style={styles.text}>{`$${deliveryFeeDollars} Delivery fee`}</Text>
      </View>
      {searchResult.distance && (
        <View style={styles.storeStatus}>
          <View style={styles.iconContainer}>
            <Image
              resizeMode='contain'
              source={locationIcon}
              style={styles.icon}
            />
          </View>
          <Text style={styles.text}>{searchResult.distance}</Text>
        </View>
      )}
    </View>
  );
};

export default StoreDeliveryInfo;
