import React from "react";
import { Image, Platform, Pressable, StyleSheet, Text } from "react-native";

import { PlatformUtils, StoreModules } from "gyg_common";
import { getServiceIconUrl } from "gyg_common/utils";
import colours from "styles/colours";
import { Typography } from "styles/typography";

const styles = (selected: boolean, hovered: boolean | undefined) =>
  StyleSheet.create({
    container: {
      borderRadius: 16,
      paddingHorizontal: 8,
      paddingVertical: 4,
      backgroundColor: hovered
        ? colours.starburstOpacity
        : selected
        ? colours.darkGrey
        : colours.white,
      shadowRadius: 4,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      elevation: 4,
      shadowOpacity: 0.1,
      shadowColor: colours.black,
      marginLeft: 8,
      marginVertical: 6,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      width: 24,
      height: 24,
      marginRight: 8,
      tintColor: selected ? colours.yellow : colours.black,
    },
    title: {
      ...Typography.bodyTwoBold,
      color: selected ? colours.yellow : colours.black,
      paddingRight: 8,
    },
  });

export interface StoreFilterTagProps {
  selected: boolean;
  onPress: (selected: boolean) => void;
  title: StoreModules.StoreModels.StoreTagLabel;
}
export default function StoreFilterTag(props: StoreFilterTagProps) {
  const { title, onPress, selected } = props;

  const iconUrl = getServiceIconUrl(title);
  return (
    <Pressable
      {...PlatformUtils.generateTestID(Platform.OS, "StoreTagButton")}
      onPress={() => {
        onPress(selected);
      }}
      style={({ hovered }) => styles(selected, hovered).container}>
      {iconUrl && (
        <Image style={styles(selected, false).icon} source={iconUrl} />
      )}
      <Text style={styles(selected, false).title}>{title}</Text>
    </Pressable>
  );
}
