import React from "react";
import { ScrollView, View } from "react-native";

import { StoreModules, StoreReduxModels } from "gyg_common";
import StoreFilterTag from "views/components/Stores/StoreFilterTag";

export interface StoreFilterProps {
  storeTags: StoreReduxModels.StoreTag[];
  selectedTags: StoreModules.StoreModels.StoreTagLabel[];
  onSelectedUpdate: (
    selected: boolean,
    tag: StoreModules.StoreModels.StoreTagLabel
  ) => void;
}
export default function StoreFilter(props: StoreFilterProps) {
  const { selectedTags, storeTags, onSelectedUpdate } = props;

  return (
    <View style={{ flex: 1, width: "100%", height: 60 }}>
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={true}
        scrollEnabled={true}
        style={{ height: 60 }}>
        {storeTags.map((storeTag, index) => {
          const isSelected =
            selectedTags.findIndex((n) => n === storeTag.tag) != -1;
          return (
            <StoreFilterTag
              key={`${storeTag.tag} ${index} ${JSON.stringify(isSelected)}`}
              onPress={(selected) => {
                onSelectedUpdate(selected, storeTag.tag);
              }}
              selected={isSelected}
              title={storeTag.tag}
            />
          );
        })}
      </ScrollView>
    </View>
  );
}
