import type { AnimationObject } from "lottie-react-native";
import { Platform, StyleSheet, ViewStyle } from "react-native";

import colours from "../../components/styles/colours";
import { Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

export const DESCRIPTION_TEXT_DISPLAY_DELAY =
  Platform.OS === "web" ? 2000 : 10000;
export const ANIMATION_TEXT_DURATION = 400;

export interface LoadingScreenProps {
  loading: boolean;

  // App Props
  containerStyle?: ViewStyle;
  normalText?: string;
  loaderStyle?: ViewStyle;
  fullscreen?: boolean;
  textAnimation?:
    | string
    | AnimationObject
    | {
        uri: string;
      };

  // Web Props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json?: any;
  description?: string;
  disableDestroy?: boolean;
  disableAutoPlay?: boolean;
}

export const styles = StyleSheet.create({
  container: {
    backgroundColor: colours.blackOpacity,
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    height: "100%",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1000,
    elevation: 1000,
  },
  animationText: {
    ...Typography.titleOne,
    color: colours.yellow,
    position: "relative",
    alignSelf: "center",
    top: "8%",
    left: Spacing.Light,
    right: Spacing.Light,
    textAlign: "center",
  },
  description: {
    ...Typography.titleFour,
    color: colours.white,
    position: "absolute",
    alignSelf: "center",
    bottom: "30%",
    left: Spacing.Light,
    right: Spacing.Light,
    textAlign: "center",
    zIndex: 100,
  },
});
