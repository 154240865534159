import { pickBy } from "lodash";

import { UpdateUserAccountAnalytics } from "../../redux_store/analytics/models";
import { AnalyticsType } from "./model";

export const updateUserAccountPayload = (
  state?: UpdateUserAccountAnalytics
): AnalyticsType => {
  const updateUserAccountObj: AnalyticsType = {
    first_name: state?.firstName,
    last_name: state?.lastName,
    date_of_birth: state?.dateOfBirth,
    email: state?.email,
    mobile: state?.mobile,
  };

  return pickBy(updateUserAccountObj, (v) => v !== undefined);
};
