import { ErrorResponse } from "../error/models";

export interface BrazeSubscriptionGroup {
  type: BrazeSubscriptionGroupType;
  status: BrazeSubscriptionGroupStatus;
}

export enum BrazeSubscriptionGroupType {
  SMS = "sms",
  EMAIL = "email",
  PUSH_NOTIFICATIONS = "push-notification",
}

export enum BrazeSubscriptionGroupStatus {
  SUBSCRIBED = "Subscribed",
  UNSUBSCRIBED = "Unsubscribed",
}

export interface NotificationsCenterState {
  subscriptionGroups: BrazeSubscriptionGroup[];
  isLoading: boolean;
  errorResponse?: ErrorResponse;
  isUpdateSuccess: boolean;
}

export interface NotificationsCenterSuccess {
  message: string;
}
