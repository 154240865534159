import jwt_decode from "jwt-decode";
import sign from "jwt-encode";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import config from "config";
import {
  AnalyticsAction,
  AnalyticsConst,
  AnalyticsInstance,
  AuthReduxAction,
  AuthStateModels,
  registerUnauthorizedInterceptor,
  RootState,
  UserReduxAction,
} from "gyg_common";

export const getDeviceId = (): string | undefined => {
  try {
    let deviceId = localStorage.getItem("DeviceId");
    if (deviceId) {
      return jwt_decode(deviceId);
    } else {
      deviceId = uuidv4();
      const secret = config.jwtSecret;
      const jwt = sign(deviceId, secret);
      localStorage.setItem("DeviceId", jwt);
      return deviceId;
    }
  } catch (error) {
    console.log("Unable to get deviceId", error);
  }
};

const WebAuthObsever: React.FC = () => {
  const secret = config.jwtSecret;

  const { isAuthSuccess, currentUser } = useSelector((s: RootState) => s.login);
  const { isAuthTokenExpired } = useSelector((s: RootState) => s.analytics);

  const dispatch = useDispatch();

  /**
   * Gets tokens from async storage and verifies it.
   */
  const getTokenFromLocalStorage = () => {
    const currentUserFromStorage = localStorage.getItem("CurrentUser");

    if (currentUserFromStorage) {
      dispatch(
        AuthReduxAction.setCurrentUserToState(
          jwt_decode(currentUserFromStorage) as AuthStateModels.CurrentUser
        )
      );
      dispatch(AuthReduxAction.setTokenStatus(true));
      return currentUserFromStorage;
    } else {
      dispatch(AuthReduxAction.unsetToken());
    }
  };

  /**
   * Gets current data for token and user's details.
   */
  useEffect(() => {
    registerUnauthorizedInterceptor(dispatch);
    const currentUserFromStorage = getTokenFromLocalStorage();
    if (currentUserFromStorage) {
      dispatch(UserReduxAction.getUserProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // listens to access token changes, may change because refresh token
  useEffect(() => {
    if (currentUser?.accessToken) {
      dispatch(AuthReduxAction.setCurrentUserToState(currentUser));
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (isAuthSuccess) {
      localStorage.removeItem("CurrentUser");
      const jwt = sign(currentUser, secret);
      localStorage.setItem("CurrentUser", jwt);
    } else {
      localStorage.removeItem("CurrentUser");
    }
  }, [isAuthSuccess, currentUser, secret]);

  useEffect(() => {
    if (isAuthTokenExpired) {
      AnalyticsInstance.trackEvent(
        AnalyticsConst.Events.ExpiredRefreshToken,
        {}
      );
      dispatch(AnalyticsAction.setAuthTokenExpired(false));
      localStorage.removeItem("CurrentUser");
      dispatch(UserReduxAction.resetError());
    }
  }, [isAuthTokenExpired, dispatch]);

  return <></>;
};

export default WebAuthObsever;
