import moment from "moment";

import { CheckoutResponse } from "../../redux_store/checkout/model";
import { convertTimeToTimezone } from "../Order/utils";
import { OrderCardViewProps } from "./model";

export const buildPickupDate = (
  date: string,
  timeZone: string | undefined
): string => {
  const orderFullDateFormat = moment(date).format("DD MMMM YYYY");
  const nowFullDateFormat = moment(new Date()).format("DD MMMM YYYY");

  if (orderFullDateFormat === nowFullDateFormat) {
    return (
      "Today " +
      convertTimeToTimezone(new Date(date), timeZone).format("DD MMMM")
    );
  } else {
    return convertTimeToTimezone(new Date(date), timeZone).format(
      "DD MMMM YYYY"
    );
  }
};

export const mapCheckoutResponseToOrderCardProps = (
  checkoutResponse: CheckoutResponse[]
): OrderCardViewProps[] => {
  const props = [];
  if (checkoutResponse.length > 0) {
    for (const response of checkoutResponse) {
      const order = response.order;

      const data: OrderCardViewProps = {
        orderNo: order.orderNumber,
        pickupTime: convertTimeToTimezone(
          new Date(order.pickupTime),
          response.store.timezoneInfo.storeTimeZone
        ).format("h:mma"),
        pickupDate: buildPickupDate(
          order.pickupTime,
          response.store.timezoneInfo.storeTimeZone
        ),
        date: new Date(order.pickupTime),
        pickupLocation: response.store.name,
        orderStatus: order.orderFulfilmentStatus,
        orderFinalisationStatus: order.orderFinalisationStatus,
        price: order.basket.total,
        isTableService: !!order.tableNumber,
        productName: order.basket.basketItems[0].name,
        numOfOtherItems: order.basket.basketItems.length - 1,
        checkoutResponse: response,
        orderCollectionType: response.orderCollectionType,
        delivery: order.delivery,
      };

      props.push(data);
    }
    props.sort((a, b) => a.date - b.date).reverse();
  }
  return props;
};
