import { CartItem } from "../../../redux_store/cart/models";
import { compareAllModifiers } from "./compareAllModifiers";

export const isItemPartsEqual = (
  partsA: CartItem[] | undefined,
  partsB: CartItem[] | undefined
): boolean => {
  const safePartsA: CartItem[] = partsA || [];
  const safePartsB: CartItem[] = partsB || [];

  if (safePartsA.length !== safePartsB.length) {
    return false;
  }

  let numberOfMatchingParts = 0;
  // O(mn)
  safePartsA.forEach((item1) => {
    safePartsB.forEach((item2) => {
      const isPartsProductIdEqual = item1.productId === item2.productId;
      if (!isPartsProductIdEqual) {
        return;
      }

      // if selected attributes of objects are equal, compare modifiers
      const isAllSameModifiers = compareAllModifiers(item1, item2);
      if (isAllSameModifiers) {
        numberOfMatchingParts++;
      }
    });
  });

  // already asserted that safePartsA.length === safePartsB.length
  const isAllMatchingParts = safePartsA.length === numberOfMatchingParts;
  if (isAllMatchingParts) {
    return true;
  }

  return false;
};
