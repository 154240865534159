/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";
import { SafeAreaView } from "react-native";

import styles from "./PrimaryBlackButton.styles";
import { PrimaryBlackButtonProps } from "./";

const SafeArea: React.FC<PrimaryBlackButtonProps> = (props) => {
  return <SafeAreaView style={styles(props).bottomNodge} />;
};

export default SafeArea;
