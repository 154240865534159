import { StyleSheet } from "react-native";

import colours from "../../styles/colours";
import { Typography } from "../../styles/typography";
import { Spacing } from "../../styles/number";

const styles = () =>
  StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    errorImage: {
      marginTop: Spacing.Thick,
      marginBottom: Spacing.Medium,
    },
    errorHeadingWrapperIOS: {
      width: 244,
      marginBottom: Spacing.Thin,
    },
    errorImageWeb: {
      height: 212,
      width: 264,
    },
    errorHeadingWrapperAndroid: {
      width: 300,
      marginBottom: Spacing.Thin,
    },
    errorHeading: {
      ...Typography.largeTitle,
      color: colours.darkGrey,
      textAlign: "center",
    },
    errorMessageWrapper: {
      width: 294,
    },
    errorMessage: {
      ...Typography.bodyBold,
      color: colours.darkGrey,
      textAlign: "center",
    },
    errorMessageWithLabel: {
      marginTop: Spacing.Light,
    },
    tryAgain: {
      marginTop: Spacing.Light,
    },
  });

export default styles;
