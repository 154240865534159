import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  BraintreeReduxActions,
  CheckoutAction,
  OrderModules,
  OrderReduxAction,
  RootState,
  SentryLoggerInstance,
} from "../..";
import errorImage from "../../components/assets/images/illustration_error.png";
import ModalWithButton from "../../components/modals/ModalWithButton";
import {
  isBraintreeError,
  isUnexpectedError,
} from "../../hooks/useHandleOrderError";

export interface CheckoutErrorModalProps {
  onModalActionButtonPress: () => void;
}

export const CHECKOUT_MODAL_ERROR_DISPLAYED = "CheckoutErrorModal was shown:";
const MAX_CHECKOUT_TIMEOUT_RETRIES = 1;

export const CheckoutErrorModal = ({
  onModalActionButtonPress,
}: CheckoutErrorModalProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isTimeout: checkoutTimeout } = useSelector(
    (state: RootState) => state.braintree
  );
  const { error: checkoutError, hasUnexpectedError } = useSelector(
    (s: RootState) => s.checkout
  );
  const { getOrderResponse, getOrderError } = useSelector(
    (s: RootState) => s.order
  );
  const [checkoutRetries, setCheckoutRetries] = useState(
    MAX_CHECKOUT_TIMEOUT_RETRIES
  );

  const isCheckoutInProgressError =
    OrderModules.OrderUtils.isCheckoutInProgressError(getOrderError);

  const isCheckoutModalVisible = useMemo(() => {
    return !!checkoutError || checkoutTimeout || isCheckoutInProgressError;
  }, [checkoutError, checkoutTimeout, isCheckoutInProgressError]);
  const isTimeoutRetryPossible = checkoutTimeout && checkoutRetries > 0;

  const clearOrderErrorStates = useCallback(() => {
    dispatch(OrderReduxAction.clearOrderError());
    dispatch(CheckoutAction.resetPayment());
    dispatch(BraintreeReduxActions.setTimeoutError(false));
  }, [dispatch]);

  useEffect(() => {
    if (checkoutError) {
      dispatch(CheckoutAction.resetPayment());
    }

    if (checkoutTimeout) {
      dispatch(BraintreeReduxActions.setTimeoutError(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const title = useCallback(() => {
    if (isUnexpectedError(hasUnexpectedError, checkoutError)) {
      return t("CheckoutPayment:unExpectedStatusTitle");
    } else if (checkoutTimeout) {
      return t("CheckoutPayment:timeoutTitle");
    } else if (isCheckoutInProgressError) {
      return getOrderError?.heading
        ? getOrderError.heading
        : t("CheckoutPayment:errorTitle");
    } else {
      return checkoutError?.heading
        ? checkoutError.heading
        : t("CheckoutPayment:errorTitle");
    }
  }, [
    checkoutError,
    checkoutTimeout,
    getOrderError?.heading,
    hasUnexpectedError,
    isCheckoutInProgressError,
    t,
  ]);

  const message = useCallback(() => {
    if (isUnexpectedError(hasUnexpectedError, checkoutError)) {
      return t("CheckoutPayment:unExpectedStatusMessage");
    } else if (checkoutTimeout) {
      return t("CheckoutPayment:timeoutDesc");
    } else if (isCheckoutInProgressError) {
      return getOrderError?.message;
    } else {
      return checkoutError?.message;
    }
  }, [
    checkoutError,
    checkoutTimeout,
    getOrderError?.message,
    hasUnexpectedError,
    isCheckoutInProgressError,
    t,
  ]);

  const onCloseButtonTap = () => {
    //Order should be recreated on braintree error so close action should be same as action button press
    //If there was timeout and close button tap, make retry /checkout with same payment payload to get order details and prevent creating duplicate order by user
    if (isBraintreeError(checkoutError) || isTimeoutRetryPossible) {
      onModalActionButtonPress();

      if (checkoutTimeout) {
        setCheckoutRetries(checkoutRetries - 1);
      }
    } else {
      //Clearing order error states will hide error modal without any error handling logics to run
      clearOrderErrorStates();
    }
  };

  /**
   * Sent checkout error to Sentry
   */
  useEffect(() => {
    if (isCheckoutModalVisible) {
      SentryLoggerInstance.sentryCaptureCustomError(
        CHECKOUT_MODAL_ERROR_DISPLAYED,
        `ORDER_ID: ${getOrderResponse?.orderId} ` +
          title() +
          ` ${message()}` +
          ` statusCode: ${checkoutError?.statusCode}`
      );
    }
  }, [
    checkoutError,
    checkoutTimeout,
    isCheckoutModalVisible,
    getOrderResponse,
    title,
    message,
  ]);

  return (
    <ModalWithButton
      isVisible={isCheckoutModalVisible}
      image={errorImage}
      small
      title={title()}
      messageBold={message()}
      primaryButton={{
        name:
          checkoutTimeout || isCheckoutInProgressError
            ? t("CheckoutPayment:tryAgainButton")
            : t("CheckoutPayment:okGotItButton"),
        action: onModalActionButtonPress,
      }}
      onModalClose={onCloseButtonTap}
    />
  );
};
