/*
 * Sets bottom value to align Sini font extra space.
 * @param fontSize
 */
export const setSiniBottom = (fontSize: number): number => {
  interface SizeBottom {
    size: number;
    bottom: number;
  }

  const sizeTable: SizeBottom[] = [
    {
      size: 88,
      bottom: -12,
    },
    {
      size: 40,
      bottom: -4,
    },
    {
      size: 32,
      bottom: -4,
    },
    {
      size: 28,
      bottom: -3,
    },
    {
      size: 24,
      bottom: -3,
    },
    {
      size: 20,
      bottom: -3,
    },
    {
      size: 18,
      bottom: -2,
    },
    {
      size: 16,
      bottom: -2,
    },
  ];
  const el: SizeBottom = sizeTable.filter(
    (el: SizeBottom) => el.size === fontSize
  )[0];
  const bottomValue = el.bottom | 0;

  return bottomValue;
};
