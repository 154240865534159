/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ErrorResponse } from "../../redux_store/error/models";
import {
  ForgotPasswordPayload,
  RefreshTokenResposne,
} from "../../services/api/auth/models";
import { AllActionsOf } from "../utils/Actions";
import {
  AuthProps,
  AuthState,
  CurrentUser,
  LoginOTPVerify,
  RefreshTokenPayload,
  ResetPasswordPayload,
} from "./models";

const initialState: AuthState = {
  isLoading: false,
  isAuthSuccess: false,
  loginErrorResponse: undefined,
  currentUser: undefined,
  isShowLogin: false,
  isLogoutAllError: undefined,
  resetPasswordLoading: false,
  isResetPasswordSuccess: false,
  resetPasswordError: undefined,
  forgotPasswordError: undefined,
  isRefreshToken: false,
  fromRegistration: false,
  requiresOTP: false,
  mfaToken: null,
  mfaError: undefined,
  otpSubmittedLoading: false,
};

const authSlice = createSlice({
  name: "Auth",
  initialState: initialState,

  reducers: {
    loading(state) {
      state.isLoading = true;
    },

    // User already registered and loggedIn, but token is expired, Dispatch to login action
    loggedUserToLogin(state) {
      state.isLoading = false;
      state.isAuthSuccess = false;
      state.isShowLogin = true;
    },

    // If RefreshToken expired, logout first before login
    unsetToken(state) {
      state.isLoading = false;
      state.isAuthSuccess = false;
      state.isShowLogin = false;
      state.currentUser = undefined;
    },

    refreshTokenSuccess(state, action: PayloadAction<RefreshTokenResposne>) {
      state.isLoading = false;
      if (state.currentUser) {
        state.currentUser.accessToken = action.payload.accessToken;
        state.currentUser.refreshToken = action.payload.refreshToken;
        state.isAuthSuccess = true;
      }
      state.isRefreshToken = true;
    },

    //Valiadating Token success or Failure status
    setTokenStatus(state, action: PayloadAction<boolean>) {
      state.isAuthSuccess = action.payload;
      state.isLoading = false;
      state.isShowLogin = false;
    },

    // Everytime App launches, take currentUser from Asynstorage and set data to state and token in the config file
    setCurrentUserToState(state, action: PayloadAction<CurrentUser>) {
      state.currentUser = action.payload;
    },

    //Login Action
    login(state, action: PayloadAction<AuthProps>) {
      state.isLoading = true;
      state.isAuthSuccess = false;
    },

    loginSuccess(state, action: PayloadAction<CurrentUser>) {
      state.isLoading = false;
      state.currentUser = action.payload;
      state.isAuthSuccess = true;
      state.fromRegistration = false;
    },

    registerSuccess(state, action: PayloadAction<CurrentUser>) {
      state.isLoading = false;
      state.currentUser = action.payload;
      state.isAuthSuccess = true;
      state.fromRegistration = true;
    },

    loginError(state, action: PayloadAction<ErrorResponse>) {
      state.isLoading = false;
      state.loginErrorResponse = action.payload;
      state.isAuthSuccess = false;
    },

    logoutCurrentDevice: () => initialState,

    logoutAllDevices(state) {
      state.isLoading = true;
      state.currentUser = undefined;
      state.isAuthSuccess = false;
    },

    logoutAllDevicesError(state, action: PayloadAction<ErrorResponse>) {
      state.isLoading = false;
      state.isLogoutAllError = action.payload;
    },

    logoutAllDevicesSuccess: () => initialState,

    forgotPassword(state, action: PayloadAction<ForgotPasswordPayload>) {
      state.isLoading = true;
    },

    forgotPasswordSuccess(state) {
      state.isLoading = false;
    },

    forgotPasswordError(state, action: PayloadAction<ErrorResponse>) {
      state.forgotPasswordError = action.payload;
      state.isLoading = false;
    },

    resetPassword(state, action: PayloadAction<ResetPasswordPayload>) {
      state.resetPasswordLoading = true;
    },

    resetPasswordSuccess(state) {
      state.isResetPasswordSuccess = true;
      state.resetPasswordError = null;
      state.resetPasswordLoading = false;
    },

    resetPasswordError(state, action: PayloadAction<ErrorResponse>) {
      state.resetPasswordError = action.payload;
      state.isResetPasswordSuccess = false;
      state.resetPasswordLoading = false;
    },

    clearError(state) {
      state.isLogoutAllError = undefined;
      state.loginErrorResponse = undefined;
      state.forgotPasswordError = undefined;
      state.isShowLogin = false;
    },

    setRequiresOTP(state, action: PayloadAction<{ mfaToken: string }>) {
      state.requiresOTP = true;
      state.mfaToken = action.payload.mfaToken;
      state.isLoading = false;
    },

    setDoesNotRequireOTP(state) {
      state.requiresOTP = false;
      state.mfaToken = null;
      state.isLoading = false;
    },

    verifyLoginOTP: (state, action: PayloadAction<LoginOTPVerify>) => state,

    setOTPError: (state, action: PayloadAction<ErrorResponse | null>) => {
      state.mfaError = action.payload;
    },
    setOTPSubmittedLoading: (state, action: PayloadAction<boolean>) => {
      state.otpSubmittedLoading = action.payload;
    },
  },
});

const { actions, reducer } = authSlice;
export type AuthActionsType = AllActionsOf<typeof actions>;
export { actions as authActions, reducer as authReducer, initialState };
