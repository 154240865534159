const colours = {
  white: "#ffffff",
  black: "#000000",
  yellow: "#ffd204",
  starburst: "#ffeca9",
  starburstOpacity: "#fff9e5",
  darkGrey: "#171717",
  midGrey: "#646464",
  semiDarkGrey: "#333333",
  uiGrey: "#7e7e7e",
  switchGrey: "#999999",
  lightGrey: "#e0e0e0",
  lightestGrey: "#f8f8f8",
  borderGrey: "#dedede",
  red: "#d70015",
  green: "#208925",
  lightGreen: "#34c759",
  blackOpacity: "rgba(000, 000, 000, 0.7)",
  yellowOpacity: "rgba(255, 210, 4, 0.7)",
  transparent: "rgba(000, 000, 000, 0)",
};

export default colours;
