export interface ErrorResponse {
  title: string;
  statusCode: number | string;
  message: string;
  heading?: string;
  isTimeout?: boolean;
}

export enum HTTPStatusCode {
  UNAUTHORISED = 401,
  UNEXPECTEDERROR = 500,
  BADREQUEST = 400,
  MISDIRECTED = 421,
}
