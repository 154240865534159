import config from "../../../config";
import {
  CoffeeLoyalty,
  IRewards,
  Loyalty,
} from "../../../redux_store/loyalty/models";
import { SentryLoggerInstance } from "../../../sentry";
import { httpClient } from "../../httpClient";

export async function getUserLoyalty(): Promise<Loyalty> {
  try {
    console.debug("API: GET user/loyalty");

    const response = await httpClient({
      url: `user/loyalty`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${config.accessToken}`,
      },
    });

    return response.data as Loyalty;
  } catch (e) {
    SentryLoggerInstance.sentryEndpointError(e, "GET user/loyalty");
    throw e;
  }
}

export async function getUserRewards(): Promise<IRewards[]> {
  try {
    console.debug("API: GET user/coupons");

    const response = await httpClient({
      url: `user/coupons`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${config.accessToken}`,
      },
    });

    return response.data as IRewards[];
  } catch (e) {
    // this error casued 130k records in around 15 days
    // We filter out 404 errors for user coupons api
    if ((e.status || e.response?.status) !== 404) {
      SentryLoggerInstance.sentryEndpointError(e, "GET user/coupons");
    }
    throw e;
  }
}

export async function getUserCoffeeLoylalty(
  orderId: string | undefined
): Promise<CoffeeLoyalty> {
  try {
    console.debug("API: GET user/loyaltyscheme/free-coffee");

    const response = await httpClient({
      url: !!orderId
        ? `user/loyalty-scheme/free-coffee/${orderId}`
        : `user/loyalty-scheme/free-coffee`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${config.accessToken}`,
      },
    });

    return response.data as CoffeeLoyalty;
  } catch (e) {
    SentryLoggerInstance.sentryEndpointError(
      e,
      "GET user/loyalty-scheme/free-coffee"
    );
    throw e;
  }
}
