import React from "react";
import { Platform, Pressable, Text, View } from "react-native";

import * as PlatformUtils from "../../../modules/platformUtils";
import Spinner from "../Spinner";
import styles from "./PrimaryBlackButton.styles";
import SafeArea from "./SafeArea";

export enum PrimaryBlackButtonBackgroundColor {
  WHITE = "white",
}

export interface PrimaryBlackButtonProps {
  buttonName: string;
  testId?: string;
  disable?: boolean;
  disguisedDisable?: boolean;
  price?: number;
  border?: boolean;
  backgroundColor?: PrimaryBlackButtonBackgroundColor;
  shadow?: boolean;
  onClick: () => void;
  applySafeArea?: boolean;
  loading?: boolean;
  useOnResponderGrant?: boolean;
  /* Using onResponderGrant instead of Touchable components fixes issue when 
     TextInput is not responded for touches on web, when Modal is shown on top of another Modal. */
}

export const PrimaryBlackButton: React.FC<PrimaryBlackButtonProps> = (
  props
) => {
  const actualDisable = props.disable || props.loading;
  const disabled = props.disguisedDisable ? false : actualDisable;

  const Content = () => {
    return (
      <View style={styles(props).buttonContainer}>
        {props.loading ? (
          <Spinner />
        ) : (
          <>
            {!!props.price && <View style={styles(props).leftView} />}
            <Text numberOfLines={1} style={styles(props).centerText}>
              {props.buttonName}
            </Text>
            {!!props.price && (
              <Text style={styles(props).rightText}>
                ${props?.price?.toFixed(2)}
              </Text>
            )}
          </>
        )}
      </View>
    );
  };

  return (
    <>
      <View style={styles(props).borderContainer}>
        {props.useOnResponderGrant ? (
          <View
            {...PlatformUtils.generateTestID(
              Platform.OS,
              props.testId ?? "primary-black-button"
            )}
            onStartShouldSetResponder={() => !disabled}
            onResponderGrant={props.onClick}
            style={
              styles({ ...props, hovered: false, pressed: false }).container
            }>
            <Content />
          </View>
        ) : (
          <Pressable
            {...PlatformUtils.generateTestID(
              Platform.OS,
              props.testId ?? "primary-black-button"
            )}
            disabled={disabled}
            onPress={props.onClick}
            style={({ hovered, pressed }) =>
              styles({ ...props, hovered, pressed }).container
            }>
            <Content />
          </Pressable>
        )}
      </View>

      {props.applySafeArea && <SafeArea {...props} />}
    </>
  );
};

export default PrimaryBlackButton;
