import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState, UserReduxAction } from "gyg_common";
import { SetupScreenComponent } from "gyg_common/components/OrderSetup/OrderSetupFlowContent";
import { PickupModalState } from "gyg_common/redux_store/order/models";

import StoreDetails from "../Stores/StoreDetails";

const Screen: React.FC<SetupScreenComponent<PickupModalState>> = ({
  state,
  onNext,
  onBack,
}) => {
  const dispatch = useDispatch();
  const store = useSelector((s: RootState) => s.store);

  const setMessageToast = useCallback(
    (toastMessage: string | null) => {
      if (!store.error) {
        dispatch(
          UserReduxAction.setMessageToast(
            toastMessage === null
              ? null
              : {
                  showCloseButton: true,
                  title: toastMessage,
                }
          )
        );
      }
    },
    [dispatch, store.error]
  );

  useEffect(() => {
    const onComponentUnmount = () => {
      setMessageToast(null);
    };

    return onComponentUnmount;
  }, [setMessageToast]);

  const handleOrder = () => {
    onNext(state);
  };

  const onChangeRestaurantClick = () => {
    onBack(state);
  };

  const onShareLinkClick = () => {};

  return (
    <StoreDetails
      store={state.ChosenStore!}
      handleOrderFromRestaurant={handleOrder}
      onShareLinkClick={onShareLinkClick}
      onChangeRestaurantClick={onChangeRestaurantClick}
    />
  );
};

export default Screen;
