export const generateTmpPwd = (): string => {
  let result = "";
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const number = "0123456789";
  for (let i = 0; i < 8; i++) {
    result +=
      uppercase.charAt(Math.floor(Math.random() * 8)) +
      lowercase.charAt(Math.floor(Math.random() * 8)) +
      number.charAt(Math.floor(Math.random() * 8));
  }
  return result;
};
