import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Shadow } from "react-native-shadow-2";

import colours from "./styles/colours";
import { BorderRadius } from "./styles/number";

export interface CardStickerProps {
  bgColor?: string;
  tripleShadow?: boolean;
  children?: React.ReactNode;
  roundedBottomBorder?: boolean;
}

const style = StyleSheet.create({
  card: {
    width: "100%",
    borderRadius: BorderRadius.Medium,
    overflow: "hidden",
  },
  parentShadow: {
    width: "100%",
    ...Platform.select({
      web: {
        flex: 1,
      },
    }),
  },
  oneShadow: {
    ...Platform.select({
      web: {
        height: "100%",
      },
    }),
    shadowOpacity: 0.1,
    shadowRadius: 3,
    shadowColor: colours.black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    zIndex: 2,
    elevation: 2,
  },
});

const card = (bgColor: string, roundedBottomBorder?: boolean) =>
  StyleSheet.create({
    shadow: {
      ...style.card,
      ...style.oneShadow,
      backgroundColor: bgColor,
      borderBottomLeftRadius:
        roundedBottomBorder == false ? 0 : BorderRadius.Medium,
      borderBottomRightRadius:
        roundedBottomBorder == false ? 0 : BorderRadius.Medium,
    },
  });

/**
 * Displays basic card container or yellow sticker.
 */
const CardSticker: React.FC<CardStickerProps> = ({
  bgColor = colours.white,
  children,
  roundedBottomBorder,
}) => {
  return (
    <Shadow
      distance={9}
      startColor={colours.shadow}
      offset={[4, 0]}
      containerStyle={style.parentShadow}
      style={style.parentShadow}>
      <View style={card(bgColor, roundedBottomBorder).shadow}>{children}</View>
    </Shadow>
  );
};

export default CardSticker;
