import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import colours from "./styles/colours";

const lineStyle = StyleSheet.create({
  gradientWrapper: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
  },
  gradient: {
    flex: 1,
    height: 0.5,
  },
});

const GradientLine: React.FC = () => {
  return Platform.OS === "web" ? (
    <div className={"gradient_line"} />
  ) : (
    <View style={lineStyle.gradientWrapper}>
      <LinearGradient
        start={{ x: 0, y: 0.5 }}
        end={{ x: 1, y: 0.5 }}
        colors={[colours.white, colours.lightGrey]}
        style={lineStyle.gradient}
      />
    </View>
  );
};

export default GradientLine;
