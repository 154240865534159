import { fork, put, takeLatest } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";
import { cartActions, CartActionsType } from "./cart.slice";
import { AddCartItemPayload, CartItem, UpdateCartItemPayload } from "./models";
import { AnalyticsAction } from "../analytics/analytics.slice";

export function* handleAddItemToCart(
  action: CartActionsType
): Generator<unknown, void, AddCartItemPayload> {
  yield put(AnalyticsAction.addCartItem(action.payload as AddCartItemPayload));
}

export function* watchAddItemToCart(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(cartActions.addItemToCart.type, handleAddItemToCart);
}

export function* handleDeleteCartItem(
  action: CartActionsType
): Generator<unknown, void, CartItem[]> {
  yield put(AnalyticsAction.deleteCartItem(action.payload as number));
}

export function* watchDeleteCartItem(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(cartActions.deleteCartItem.type, handleDeleteCartItem);
}

export function* handleUpdateCartItem(
  action: CartActionsType
): Generator<unknown, void, CartItem[]> {
  yield put(
    AnalyticsAction.updateCartItem(action.payload as UpdateCartItemPayload)
  );
}

export function* watchUpdateCartItem(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(cartActions.updateCartItem.type, handleUpdateCartItem);
}

export function* handleClearCart(): Generator<unknown, void, CartItem> {
  yield put(AnalyticsAction.clearCart());
}

export function* watchClearCart(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(cartActions.clearCart.type, handleClearCart);
}

const saga = [
  fork(watchAddItemToCart),
  fork(watchClearCart),
  fork(watchDeleteCartItem),
  fork(watchUpdateCartItem),
];

export default saga;
