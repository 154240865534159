import { IMobileAppConfig } from "../../redux_store/dashboard/models";
import { Platform } from "react-native";

export const sortDashboardByDisplayOrder = ({
  dashboard,
  ...rest
}: IMobileAppConfig): IMobileAppConfig => {
  const sortedDashboardData = [...dashboard].sort(
    (a, b) => a.displayOrder - b.displayOrder
  );

  return {
    dashboard: sortedDashboardData,
    ...rest,
  };
};

export const checkIfChannelIdSupportsTheCurrentPlatform = (
  channeltype: number
) => {
  //  0 = All platforms, 1 = App only, 2 = Web only
  if (
    ((Platform.OS === "android" || Platform.OS === "ios") &&
      channeltype === 0) ||
    channeltype === 1
  ) {
    return true;
  } else if (
    (Platform.OS === "web" && channeltype === 0) ||
    channeltype === 2
  ) {
    return true;
  } else {
    return false;
  }
};
