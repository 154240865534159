import "./style.scss";

import flyingBurrito from "assets/gif/FlyingBurrito_001.json";
import lottie from "lottie-web";
import React, { useEffect, useRef } from "react";

export const FlyingBurritoLoader: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrapperRef.current) {
      lottie.loadAnimation({
        container: wrapperRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: flyingBurrito,
      });
    }
  }, []);

  return (
    <div
      ref={wrapperRef}
      data-testid='flyingBurrito'
      className='loader__show-more-burrito'
      id={`flying-burrito-${
        wrapperRef.current?.id || Math.floor(Math.random() * 99999)
      }`}
    />
  );
};
