import { ConvertMenuStructure } from "../../../modules/Menu/menuStructureMapper";
import { MenuStructure } from "../../../redux_store/menu/models";
import { SentryLoggerInstance } from "../../../sentry";
import { httpClient } from "../../httpClient";
import { MenuStructureResponse } from "./model";

export async function getMenu(
  storeId: number,
  channelId: number = 1
): Promise<MenuStructure> {
  try {
    console.debug(`API: GET menu/${channelId}/${storeId}`);

    const response = await httpClient({
      url: `menu/${channelId}/${storeId}`,
      method: "GET",
    });
    return ConvertMenuStructure(response.data as MenuStructureResponse);
  } catch (e) {
    SentryLoggerInstance.sentrySetTag("store.id", storeId.toString());
    SentryLoggerInstance.sentryEndpointError(
      e,
      `GET menu/${channelId}/<storeId>`
    );
    throw e;
  }
}
