import "./styles.scss";

import React from "react";
import {
  Image,
  ImageSourcePropType,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { PlatformUtils } from "gyg_common";
import colours from "styles/colours";
import { Typography } from "styles/typography";

interface StyleProps extends AnimatedButtonProps {
  hovered?: boolean;
  pressed?: boolean;
  bgColour?: string;
}

const style = (props: StyleProps) =>
  StyleSheet.create({
    wrapper: {
      borderRadius: 8,
      opacity: props.disabled ? 0.5 : 1,
      backgroundColor: props.pressed
        ? colours.starburst
        : props.animate
        ? "rgba(0,0,0,0)"
        : props.hovered
        ? colours.starburstOpacity
        : props.bgColour,
    },
    container: {
      paddingTop: props.icon ? 10 : 12,
      paddingBottom: props.icon ? 10 : 12,
      paddingLeft: 16,
      paddingRight: 16,
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      alignSelf: "center",
      borderRadius: 8,
      justifyContent: "center",
    },
    title: {
      ...Typography.titleThree,
      textTransform: "uppercase",
      textAlign: "center",
      justifyContent: "center",
    },
    icon: {
      width: 24,
      height: 24,
      marginRight: 4,
    },
  });

export interface AnimatedButtonProps {
  defaultTitle: string;
  animatedTitle: string;
  animate: boolean;
  icon?: ImageSourcePropType;
  disabled?: boolean;
  bgColor?: "white" | "grey" | "yellow";
  testId?: string;
  onClick: () => void;
}
/**
 * Tertiary button with fading out state after click.
 * @param props
 * @returns
 */
const AnimatedButton: React.FC<AnimatedButtonProps> = (
  props: AnimatedButtonProps
) => {
  const {
    defaultTitle,
    animatedTitle,
    onClick,
    disabled,
    icon,
    bgColor = "grey",
    testId,
    animate,
  } = props;

  let bgColour: string;
  switch (bgColor) {
    case "white":
      bgColour = colours.white;
      break;
    case "yellow":
      bgColour = colours.yellow;
      break;
    case "grey":
      bgColour = colours.lightestGrey;
      break;
    default:
      bgColour = colours.lightestGrey;
      break;
  }

  return (
    <>
      <div
        className={`animated-button ${
          animate ? "animated-button--animated" : ""
        }`}>
        <Pressable
          {...PlatformUtils.generateTestID(
            Platform.OS,
            testId ? testId : "animated-button"
          )}
          disabled={disabled}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onPress={disabled ? () => {} : onClick}
          style={({ hovered, pressed }) =>
            style({ ...props, hovered, pressed, bgColour }).wrapper
          }>
          <View style={style(props).container}>
            {icon && <Image source={icon} style={style(props).icon} />}
            <div className='animated-button__text animated-button__text--animated'>
              <Text style={style(props).title}>{animatedTitle}</Text>
            </div>
            <div className={`animated-button__text ${!!animate && "hide"}`}>
              <Text style={style(props).title}>{defaultTitle}</Text>
            </div>
          </View>
        </Pressable>
      </div>
    </>
  );
};

export default AnimatedButton;
