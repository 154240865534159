import React from "react";
import { View, Text, StyleSheet, Platform } from "react-native";

import * as PlatformUtils from "../modules/platformUtils";
import colours from "./styles/colours";
import { shadowTextstyle } from "./styles/shared";
import { RealtiveStyle } from "./styles/typography";
import { Spacing } from "./styles/number";

export interface FeaturedTagProps {
  label: string;
}

const tagStyle = StyleSheet.create({
  container: {
    flexDirection: "row",
    transform: [{ rotate: "-4deg" }],
    marginRight: Spacing.Thin,
  },
  textContainer: {
    backgroundColor: colours.yellow,
    padding: Spacing.ExtraThin,
  },
  text: {
    fontFamily: "Sini-BoldItalic",
    letterSpacing: 0,
    textTransform: "uppercase",
    color: colours.darkGrey,
    ...shadowTextstyle(1).shadow,
    ...RealtiveStyle(18).sini,
    fontSize: 18,
    lineHeight: 18,
    paddingHorizontal: Spacing.ExtraThin,
  },
});

const FeaturedTag: React.FC<FeaturedTagProps> = (props: FeaturedTagProps) => {
  const { label } = props;

  return (
    <View
      style={tagStyle.container}
      {...PlatformUtils.generateTestID(Platform.OS, "FeaturedTag")}>
      <View style={tagStyle.textContainer}>
        <Text style={tagStyle.text}>{label} </Text>
      </View>
    </View>
  );
};

export default FeaturedTag;
