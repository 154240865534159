import { Moment } from "moment";
import { CartItem } from "redux_store/cart/models";
import { Surcharge } from "services/api/order/model";

export interface OrderSetupDay {
  label: string;
  value: Moment;
}

export interface CombinedCartItem {
  item: CartItem;
  amount: number;
}

export interface OrderCalculatedValues {
  orderValue: number;
  tax: number;
  taxLevel: string;
  surcharges: Surcharge[];
}

export interface OrderReceiptInfo {
  redeemDollars: number | null;
  redeemDollarsBalance: number | null;
  redeemDollarsErrorMessage?: string;
  basketValue: number;
  total: number;
  totalBeforeDiscount: number;
  subtotal: number;
  surcharges: Surcharge[];
  tax: number;
  displayTax: boolean;
  discountAmount: number | undefined;
}

export enum ModifyOrderType {
  ADD = "add",
  UPDATE = "update",
}

export type HandleUpdateOrder =
  | {
      index: number;
      cartItems: CartItem[];
      type: ModifyOrderType.UPDATE;
    }
  | {
      index?: number;
      cartItems: CartItem[];
      type: ModifyOrderType.ADD;
    };
