import _ from "lodash";

import { CartItem } from "../../../redux_store/cart/models";
import { FavouriteCartItem } from "../../../redux_store/favourite/models";
import { xor } from "../../../utils/xor";
import { compareAllModifiers } from "./compareAllModifiers";
import { isItemPartsEqual } from "./isItemPartsEqual";

interface CompareCartPredicateArgs {
  cartItem: CartItem;
  favouriteCartItem: FavouriteCartItem;
}

type CompareCartPredicate = (args: CompareCartPredicateArgs) => boolean;

const checkProductIdEqual: CompareCartPredicate = (args) => {
  const { cartItem, favouriteCartItem } = args;
  const isSameProductId =
    cartItem.productId === favouriteCartItem.cartItem.productId;
  return isSameProductId;
};

const checkItemModifiersEqual: CompareCartPredicate = (args) => {
  const { cartItem, favouriteCartItem } = args;
  const isAllModifiersSame = compareAllModifiers(
    cartItem,
    favouriteCartItem.cartItem
  );
  if (!isAllModifiersSame) {
    return false;
  }

  return true;
};

const checkItemMiamEqual: CompareCartPredicate = (args) => {
  const { cartItem, favouriteCartItem } = args;

  const isBothNonMiam =
    _.isNil(cartItem.miamItem) && _.isNil(favouriteCartItem.miamItem);
  // if both are not miam, then nothing to compare
  if (isBothNonMiam) {
    return true;
  }

  const isEitherMiamButNotBoth = xor(
    _.isNil(cartItem.miamItem),
    _.isNil(favouriteCartItem.miamItem)
  );
  // if one is miam and other is not miam, then obviously not equal
  if (isEitherMiamButNotBoth) {
    return false;
  }

  // both are miam
  const cartMiamItem = cartItem.miamItem as CartItem;
  const favouriteMiamItem = favouriteCartItem.miamItem as CartItem;

  const isMiamItemProductIdEqual =
    cartMiamItem.productId === favouriteMiamItem.productId;
  if (!isMiamItemProductIdEqual) {
    return false;
  }

  const isMiamPartsSame = isItemPartsEqual(
    cartMiamItem.parts,
    favouriteMiamItem.parts
  );
  if (!isMiamPartsSame) {
    return false;
  }

  return true;
};

const checkItemPartsEqual: CompareCartPredicate = (args) => {
  const { cartItem, favouriteCartItem } = args;
  const isItemPartsExist =
    !!cartItem.parts || !!favouriteCartItem.cartItem.parts;
  if (isItemPartsExist) {
    const isAllPartsSame = isItemPartsEqual(
      favouriteCartItem.cartItem.parts,
      cartItem.parts
    );
    if (!isAllPartsSame) {
      return false;
    }
  }

  return true;
};

export const compareCartPredicates: CompareCartPredicate[] = [
  // ordered for fail-fast
  checkProductIdEqual,
  checkItemModifiersEqual,
  checkItemMiamEqual,
  checkItemPartsEqual,
];
