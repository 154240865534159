import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { Dispatch } from "redux";

import config from "../../../config";
import { AnalyticsAction } from "../../../redux_store/analytics/analytics.slice";
import { authActions as AuthReduxAction } from "../../../redux_store/auth/auth.slice";
import * as AuthStateModels from "../../../redux_store/auth/models";
import { CheckoutAction } from "../../../redux_store/checkout/checkout.slice";
import { errorActions } from "../../../redux_store/error/error.slice";
import { favouriteActions } from "../../../redux_store/favourite/favourite.slice";
import { orderActions } from "../../../redux_store/order/order.slice";
import { socialActions as SocialActions } from "../../../redux_store/social/social.slice";
import { userActions as UserReduxAction } from "../../../redux_store/user/user.slice";
import { SentryLoggerInstance } from "../../../sentry";
import * as ApiService from "../auth/";

export const registerUnauthorizedInterceptor = (dispatch: Dispatch) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refreshAuthLogic = async (failedRequest: any) => {
    if (!config.refreshToken) {
      //exist if there is no refresh token (user is not logged in), fixes issue on fresh app launch which automatically moves user to login screen
      return;
    }
    const payload: AuthStateModels.RefreshTokenPayload = {
      refreshToken: config.refreshToken,
      grantType: AuthStateModels.GrantType.REFRESH,
    };
    try {
      dispatch(AuthReduxAction.loading());
      const tokenRefreshResponse = await ApiService.getNewToken(payload);
      failedRequest.config.headers.Authorization =
        tokenRefreshResponse.accessToken;

      config.accessToken = tokenRefreshResponse.accessToken;
      config.refreshToken = tokenRefreshResponse.refreshToken;

      //TODO: refactor the dispatches below to avoid doing this here
      dispatch(AuthReduxAction.refreshTokenSuccess(tokenRefreshResponse));
      dispatch(UserReduxAction.getUserProfile());

      //TODO: move mobile number checks to login to simplify this interceptor
      if (!tokenRefreshResponse.verifiedMobileNumber) {
        dispatch(SocialActions.setSocialRegister(true));
        dispatch(UserReduxAction.setShowMobileVerifyModal(true));
      }
    } catch (error) {
      if (
        [400, 401].includes(error?.code) ||
        (typeof error === "object" &&
          !!error.response &&
          typeof error.response?.statusCode === "number" &&
          [400, 401].includes(error.response?.statusCode))
      ) {
        SentryLoggerInstance.sentryRefreshTokenError(error);

        dispatch(AnalyticsAction.setAuthTokenExpired(true));
        dispatch(AuthReduxAction.logoutCurrentDevice());
        dispatch(AuthReduxAction.loggedUserToLogin());
        dispatch(errorActions.clearError());
        dispatch(CheckoutAction.resetPayment());
        dispatch(orderActions.clearOrderError());
        dispatch(favouriteActions.resetStatus());
      }

      throw error;
    }

    return Promise.resolve();
  };

  createAuthRefreshInterceptor(axios, refreshAuthLogic);

  axios.interceptors.request.use((request) => {
    if (request.headers.Authorization !== undefined) {
      request.headers.Authorization = config.accessToken;
    }
    return request;
  });
};
