import * as Effects from "redux-saga/effects";
import { fork, put, takeLatest } from "redux-saga/effects";
import { IMobileAppConfig } from "./models";
import * as ApiService from "../../services/api/dashboard";
import { DashboardActionsType, dashboardActions } from "./dashboard.slice";
import { orderActions } from "../order/order.slice";
import { OrderState } from "../order/models";

const call: any = Effects.call;

export function* handleGetAppConfig(
  action: DashboardActionsType
): Generator<any, void, IMobileAppConfig | OrderState> {
  try {
    const result = (yield call(
      ApiService.getMobileAppConfig,
      action.payload
    )) as IMobileAppConfig;
    yield put(dashboardActions.getMobileAppConfigSuccess(result));

    //if there is flag/cutlery is true, set default to true
    //otherwise false
    yield put(orderActions.setIncludeCutlery(!!result.enableRequestCutlery));
  } catch (e) {
    yield put(dashboardActions.getMobileAppConfigError());
  }
}

export function* watchGetAppConfig(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    dashboardActions.getMobileAppConfig.type,
    handleGetAppConfig
  );
}

const saga = [fork(watchGetAppConfig)];

export default saga;
