import { PlatformOSType } from "react-native";

import config from "../config";
import { VersionAndPlatformData } from "../services/httpClient";

interface TestID {
  [name: string]: string;
}

/**
 * Sets attribute with test id for platform in use for REACT NATIVE components.
 *
 * DO NOT use it with React DOM.
 * For classic tags eg.`<div>` add an id manually with `data-testid` attribute.
 * @param platform any platform returned from react-native
 * @param text test id string
 * @returns
 */
export const generateTestID = (
  platform: PlatformOSType,
  text: string
): TestID => {
  let testObject: TestID;
  if (platform === "ios") {
    testObject = { testID: text };
  } else if (platform === "android") {
    testObject = { accessibilityLabel: text };
  } else if (platform === "web") {
    // NOTE: We need to use testID too because
    // we're using react-native-web to compile RN components!
    testObject = { "data-testid": text, testID: text };
  } else {
    testObject = {};
  }

  return testObject;
};

export const generateWebID = (
  platform: PlatformOSType,
  text: string
): TestID => {
  let testObject: TestID;
  if (platform === "web") {
    testObject = { nativeID: text };
  } else {
    testObject = {};
  }

  return testObject;
};

/**
 * Checks if accuracy meets set threshold.
 * @param accuracy location parameter in meters
 * @param threshold accuracy threshold
 * @returns
 */
export const checkIfLocationPrecise = (
  accuracy: number | null,
  threshold: number
): boolean => {
  return accuracy ? accuracy < threshold : false;
};

export const getVersionAndPlatformData = (): VersionAndPlatformData => {
  const data: VersionAndPlatformData = {
    clientPlatformType: config.platform,
    clientVersion: config.appVersion,
  };

  return data;
};
