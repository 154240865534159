import React from "react";
import { useTranslation } from "react-i18next";
import { Image, ImageSourcePropType, StyleSheet, View } from "react-native";

import { IconButton, PrimaryBlackButton } from "gyg_common/components";
import editIcon from "gyg_common/components/assets/icons/icon_edit.png";
import deliveryIcon from "gyg_common/components/assets/icons/services/icon_delivery.png";
import TertiaryButton from "gyg_common/components/Buttons/TertiaryButton";
import { SetupScreenComponent } from "gyg_common/components/OrderSetup/OrderSetupFlowContent";
import { Spacing } from "gyg_common/components/styles/number";
import {
  CollectionType,
  DeliveryModalState,
} from "gyg_common/redux_store/order/models";

import colours from "@/styles/colours";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    paddingHorizontal: Spacing.Regular,
    paddingBottom: Spacing.Regular,
  },
  contentContainer: {
    flex: 1,
    paddingBottom: Spacing.Regular,
  },
  orderTypeContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowCentered: { flexDirection: "row", alignItems: "center" },
  iconContainer: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colours.yellow,
    borderRadius: 100,
    width: 40,
    height: 40,
    marginRight: 10,
  },
  icon: { width: 30, height: 30 },
  orderTypeText: {
    fontFamily: "Sini-Bold",
    fontSize: 28,
    lineHeight: 1,
  },
  infoContainer: {
    marginTop: Spacing.Regular,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  infoBoxContainer: { display: "flex", flexDirection: "column", rowGap: 10 },
  infoTitleText: {
    fontSize: 18,
    fontFamily: "Sini-Bold",
  },
  infoDescriptionText: {
    fontSize: 14,
    fontFamily: "Montserrat-Regular",
  },
});

const Screen: React.FC<SetupScreenComponent<DeliveryModalState>> = ({
  state,
  onBack,
  onBackToScreen,
  onChangeCollectionType,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const addressText =
    state.ChosenStore?.address1?.length > 0
      ? state.ChosenStore?.address1
      : state.ChosenStore?.address2;

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <div style={styles.orderTypeContainer}>
          <View style={styles.rowCentered}>
            <View style={styles.iconContainer}>
              <Image
                source={deliveryIcon as ImageSourcePropType}
                style={styles.icon}
                resizeMode='contain'
              />
            </View>
            <div style={styles.orderTypeText}>
              {t("OrderManagement:delivery").toUpperCase()}
            </div>
          </View>
          <div>
            <TertiaryButton
              onClick={onChangeCollectionType.bind(
                this,
                CollectionType.PICK_UP
              )}
              title={t("OrderManagement:changeToPickup")}
            />
          </div>
        </div>
        <div className='grey-line divider' />
        <div style={styles.infoContainer}>
          <div style={styles.infoBoxContainer}>
            <div style={styles.infoTitleText}>
              {t("OrderManagement:deliveryDetails")}
            </div>
            <div style={styles.infoDescriptionText}>{addressText}</div>
            <div style={styles.infoDescriptionText}>
              {state.DropOffOptionTitle}
            </div>
          </div>
          <IconButton
            withShadow={false}
            onPress={() => {
              onBackToScreen("DeliveryAddress");
            }}
            iconImage={editIcon}
          />
        </div>
        <div className='grey-line divider' />
        <div style={styles.infoContainer}>
          <div style={styles.infoBoxContainer}>
            <div style={styles.infoTitleText}>
              {t("OrderManagement:gygRestaurant")}
            </div>
            <div style={styles.infoDescriptionText}>
              {state.ChosenStore?.address1}
            </div>
          </div>
          <div>
            <IconButton
              withShadow={false}
              onPress={() => {
                onBack(state);
              }}
              iconImage={editIcon}
            />
          </div>
        </div>
      </View>
      <PrimaryBlackButton
        onClick={onSubmit.bind(this, state)}
        buttonName={t("OrderManagement:confirm")}
      />
    </View>
  );
};

export default Screen;
