export enum Spacing {
  Narrow = 2,
  ExtraThin = 4,
  Thin = 8,
  ExtraLight = 12,
  Light = 16,
  Regular = 24,
  Medium = 32,
  MediumThick = 40,
  Thick = 48,
  Wide = 64,
}

export enum BorderRadius {
  Lightest = 3.5,
  Light = 4,
  Medium = 8,
  High = 22,
  Highest = 50,
}

export enum IconSize {
  Small = 20,
  Regular = 24,
  Medium = 32,
}
