import React from "react";
import {
  Pressable,
  PressableProps,
  PressableStateCallbackType,
  StyleProp,
  ViewStyle,
} from "react-native";

export const PressableOpacity: React.FC<PressableProps> = ({
  children,
  style,
  disabled,
  ...rest
}) => {
  const onPressStyle = ({ pressed }: PressableStateCallbackType) => [
    {
      opacity: pressed && !disabled ? 0.6 : 1,
    },
  ];

  return (
    <Pressable style={[style, onPressStyle] as StyleProp<ViewStyle>} {...rest}>
      {children && children}
    </Pressable>
  );
};
