import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AllActionsOf } from "../utils/Actions";
import { BranchState } from "./models";

const initialState: BranchState = {
  section: undefined,
  storeId: undefined,
  categoryId: undefined,
  tableServiceError: false,
  requestedOrderData: false,
  hasPendingNavigation: false,
  navigationAction: undefined,
};

const menuSlice = createSlice({
  name: "branch",
  initialState: initialState,
  reducers: {
    setBranchState(state, action: PayloadAction<BranchState>) {
      state.categoryId = action.payload.categoryId;
      state.section = action.payload.section;
      state.storeId = action.payload.storeId;
      state.tableServiceError = action.payload.tableServiceError;
    },
    clearBranchState() {
      return initialState;
    },
    setRequestedOrderData(state) {
      state.requestedOrderData = true;
    },
    clearRequestedOrderData(state) {
      state.requestedOrderData = false;
    },
    setPendingAction(state, action: PayloadAction<() => void>) {
      state.hasPendingNavigation = true;
      state.navigationAction = action.payload;
    },
    clearPendingAction(state) {
      state.hasPendingNavigation = false;
      state.navigationAction = undefined;
    },
  },
});

const { actions, reducer } = menuSlice;
export type BranchActionsType = AllActionsOf<typeof actions>;
export { actions as branchActions, reducer as branchReducer, initialState };
