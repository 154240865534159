import React from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, Text, View } from "react-native";

import * as OrderReduxModels from "../redux_store/order/models";
import * as PlatformUtils from "../modules/platformUtils";
import { OrderUtils } from "../modules/Order";
import { Typography } from "./styles/typography";
import { Spacing } from "./styles/number";

export interface OrderStatusTimeProps {
  isOrderASAP: boolean;
  orderDate: string;
  orderCollectionType: OrderReduxModels.CollectionType;
  tableNumber: string;
  tableServiceCopy: string;
  pickupASAPcopy: string;
  pickupScheduledCopy: string;
  timeZone: string;
  spacious?: boolean;
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: "column",
    marginBottom: Spacing.Light,
    alignItems: "center",
  },
  timeLabel: {
    flexDirection: "row",
  },
  copy: {
    ...Typography.headline,
    textAlign: "center",
  },
  text: {
    ...Typography.titleOne,
    marginTop: Spacing.Narrow,
    textAlign: "center",
    textTransform: "uppercase",
  },
  semicolon: {
    ...Typography.titleOne,
    letterSpacing: Platform.OS === "ios" ? -8 : 0.25,
    alignItems: "center",
    position: "relative",
    marginRight: -Spacing.Thin,
  },
});

const OrderStatusTime: React.FC<OrderStatusTimeProps> = ({
  isOrderASAP,
  orderCollectionType,
  orderDate,
  tableNumber,
  tableServiceCopy,
  pickupASAPcopy,
  pickupScheduledCopy,
  timeZone,
  spacious,
}) => {
  const { t } = useTranslation();

  const orderTime = OrderUtils.convertTimeToTimezone(
    new Date(orderDate),
    timeZone
  );

  const getCopy = () => {
    if (orderCollectionType === OrderReduxModels.CollectionType.TABLE_SERVICE) {
      return tableServiceCopy;
    } else if (isOrderASAP) {
      return pickupASAPcopy;
    } else {
      return pickupScheduledCopy;
    }
  };

  return (
    <View>
      <Text style={styles.copy}>{getCopy()}</Text>
      <View style={spacious && styles.wrapper}>
        {orderCollectionType ===
        OrderReduxModels.CollectionType.TABLE_SERVICE ? (
          <Text style={styles.text}>
            {t("OrderStatus:tableNumberCopy", { tableNumber })}
          </Text>
        ) : (
          <View
            style={styles.timeLabel}
            {...PlatformUtils.generateTestID(
              Platform.OS,
              "order-status-time-pickup"
            )}>
            <Text style={styles.text}>
              {`${
                !isOrderASAP ? `${orderTime.format("DD MMM")} AT ` : ``
              }${orderTime.format("h")}`}
            </Text>
            <Text style={styles.semicolon}>:</Text>
            <Text style={styles.text}>{orderTime.format("mma")}</Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default OrderStatusTime;
