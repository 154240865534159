import LocationIcon from "assets/icons/icon_near_me.png";
import GoogleMapReact from "google-map-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";

import config, { VERSION } from "config";
import { locales, StoreModules, StoreReduxModels } from "gyg_common";
import BasicStoreDetails from "gyg_common/components/Stores/BasicStoreDetails";

import Marker from "./Marker";
import MyLocationButton from "./MyLocationButton";

export interface StoresMapProps {
  stores: StoreReduxModels.Store[];
  isUserLocationKnown: boolean;
  latLong?: StoreModules.StoreUtils.Coordinate;
  storeSearchLatLong?: StoreModules.StoreUtils.Coordinate;
  selectedStore: StoreReduxModels.Store | null;
  handleOrder: (store: StoreReduxModels.Store) => void;
  showToast: (message: string | null) => void;
  searchResultApply: boolean;
  clearStoreSearch: () => void;
  withDelivery?: boolean;
}

const {
  auDefault,
  getInitialCoordinates,
  DISTANCETHRESHOLD_AU,
  DISTANCETHRESHOLD_US,
} = StoreModules.StoreUtils;

const StoresMap: React.FC<StoresMapProps> = ({
  stores,
  latLong,
  selectedStore,
  storeSearchLatLong,
  handleOrder,
  isUserLocationKnown,
  showToast,
  searchResultApply,
  clearStoreSearch,
  withDelivery,
}): JSX.Element => {
  // TODO: Fix showToast dep, root cause is interdependent useEffects in Restaurant component
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleShowToast = useCallback(showToast, []);
  const [coordinates, setCoordinates] =
    useState<StoreModules.StoreUtils.MapCoordinates>({
      lat: auDefault.center[0],
      long: auDefault.center[1],
      latDelta: auDefault.latDelta,
      longDelta: auDefault.longDelta,
      zoom: auDefault.zoom,
    });
  const [previewStore, setPreviewStore] =
    useState<StoreReduxModels.Store | null>();
  const [showModal, setShowModal] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [mapLoaded, setMapLoaded] = useState<{ map: any; maps: any }>();
  const firstUpdate = useRef<boolean>(true);
  const { t } = useTranslation();

  const onShowMarkerDetails = (markerId: string) => {
    const store = stores.find(
      (currentStore) => currentStore.id === parseInt(markerId)
    );
    setPreviewStore(store);
    setShowModal(true);
  };

  const onMapClick = () => {
    setShowModal(false);
    // hides toast when map pressed
    handleShowToast(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnLoad = (map: any, maps: any) => {
    setMapLoaded({ map, maps });
  };

  /**
   * Sets new center for map.
   * @param map google map instance
   * @param currentLatLong latitude and longtitude to center the map
   */
  const setMapCenter = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map: any,
    currentLatLong: StoreModules.StoreUtils.Coordinate,
    zoom?: number
  ) => {
    map.setCenter({ lat: currentLatLong[0], lng: currentLatLong[1] });
    map.setZoom(zoom || StoreModules.StoreUtils.DEFAULT_USER_ZOOM);
  };

  /**
   *  Creates custom 'my location' button and centers on user location when known.
   */
  useEffect(() => {
    const controlButtonDiv = document.createElement("div");
    controlButtonDiv.id =
      StoreModules.StoreModels.StoresTestingId.myLocationButtonWrapper;
    controlButtonDiv.dataset.testid =
      StoreModules.StoreModels.StoresTestingId.myLocationButtonWrapper;

    if (mapLoaded && latLong) {
      const getBtn = document.getElementById(
        StoreModules.StoreModels.StoresTestingId.myLocationButtonWrapper
      );

      if (!getBtn) {
        // eslint-disable-next-line react/no-deprecated
        ReactDOM.render(
          <MyLocationButton icon={LocationIcon} />,
          controlButtonDiv
        );
      }
      controlButtonDiv.addEventListener("click", () =>
        setMapCenter(mapLoaded.map, latLong)
      );
      mapLoaded.map.controls[mapLoaded.maps.ControlPosition.RIGHT_CENTER].push(
        controlButtonDiv
      );

      // removing event listener from custom control
      return () => {
        controlButtonDiv.removeEventListener("click", () =>
          setMapCenter(mapLoaded.map, latLong)
        );
      };
    }
  }, [mapLoaded, latLong]);

  useEffect(() => {
    const newCoords = getInitialCoordinates(
      config.version,
      isUserLocationKnown,
      latLong
    );
    setCoordinates(newCoords);
  }, [latLong, isUserLocationKnown, searchResultApply, storeSearchLatLong]);

  /**
   * Controls zooming on search result or selected store and displaying toast message when needed.
   */
  useEffect(() => {
    if (storeSearchLatLong) {
      let newZoom = StoreModules.StoreUtils.DEFAULT_REGION_ZOOM;
      let newCenter: StoreModules.StoreUtils.Coordinate | undefined =
        storeSearchLatLong;
      // display toast when there is no restaurant in N km , and the message depending on searchResultApply
      if (stores[0] && stores[0].distance) {
        if (VERSION === locales.AU) {
          if (stores[0].distance > DISTANCETHRESHOLD_AU) {
            handleShowToast(
              t(
                searchResultApply
                  ? "StoreSearch:alertNoResultsNearby"
                  : "StoreSearch:alertNoRestaurantsNearby",
                { distanceThreshold: DISTANCETHRESHOLD_AU / 1000 }
              )
            );
          } else {
            if (stores[0].latitude && stores[0].longitude) {
              newCenter = [
                parseFloat(stores[0].latitude),
                parseFloat(stores[0].longitude),
              ];
              newZoom = StoreModules.StoreUtils.DEFAULT_USER_ZOOM;
            }
          }
        } else {
          if (
            StoreModules.DistanceCalculator.getMiles(stores[0].distance) >
            DISTANCETHRESHOLD_US
          ) {
            handleShowToast(
              t(
                searchResultApply
                  ? "StoreSearch:alertNoResultsNearby"
                  : "StoreSearch:alertNoRestaurantsNearby",
                { distanceThreshold: DISTANCETHRESHOLD_US }
              )
            );
          } else {
            if (stores[0].latitude && stores[0].longitude) {
              newCenter = [
                parseFloat(stores[0].latitude),
                parseFloat(stores[0].longitude),
              ];
              newZoom = StoreModules.StoreUtils.DEFAULT_USER_ZOOM;
            }
          }
        }
      }
      if (mapLoaded?.map && newCenter) {
        setShowModal(false);
        setMapCenter(mapLoaded.map, newCenter, newZoom);
      }
    } else if (selectedStore && firstUpdate.current) {
      const newZoom = StoreModules.StoreUtils.DEFAULT_USER_ZOOM;
      const newCenter: StoreModules.StoreUtils.Coordinate = [
        parseFloat(selectedStore.latitude),
        parseFloat(selectedStore.longitude),
      ];
      if (mapLoaded?.map) {
        setMapCenter(mapLoaded.map, newCenter, newZoom);
        firstUpdate.current = false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    storeSearchLatLong?.[0],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    storeSearchLatLong?.[1],
    firstUpdate.current,
    selectedStore,
    mapLoaded,
    stores,
    t,
    searchResultApply,
    handleShowToast,
  ]);

  return (
    <div
      className='restaurants__map'
      data-testid={StoreModules.StoreModels.StoresTestingId.storesMapContainer}>
      <GoogleMapReact
        defaultCenter={{ lat: auDefault.center[0], lng: auDefault.center[1] }}
        defaultZoom={auDefault.zoom}
        bootstrapURLKeys={{
          key: config.googleMapsKey,
          libraries: ["places"],
          id: config.googleMapsScriptId,
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleOnLoad(map, maps)}
        center={{ lat: coordinates.lat, lng: coordinates.long }}
        zoom={coordinates.zoom}
        onChildClick={(e) => onShowMarkerDetails(e)}
        onChange={clearStoreSearch}
        onClick={() => onMapClick()}>
        {stores.map((store: StoreReduxModels.Store) => {
          return (
            <Marker
              id={store.id}
              key={store.id}
              lat={parseFloat(store.latitude)}
              lng={parseFloat(store.longitude)}
              selected={store.id === previewStore?.id && showModal}
            />
          );
        })}
      </GoogleMapReact>
      <div
        data-testid={StoreModules.StoreModels.StoresTestingId.detailsPopup}
        className={`restaurants__map-popup ${
          previewStore && showModal ? "show" : "hide"
        }`}>
        <div className='card-sticker'>
          {previewStore && showModal && (
            <BasicStoreDetails
              store={previewStore}
              buttonVersion={"primary"}
              showAddress
              withDelivery={withDelivery}
              handleOrder={() => handleOrder(previewStore)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StoresMap;
