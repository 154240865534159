import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AllActionsOf } from "../utils/Actions";
import { RegistrationStructure } from "./model";

const initialState: RegistrationStructure = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  password: "",
  confirmPassword: "",
  mobileNumber: "",
  birthday: "",
  isAccepted: false,
  isSubscribe: false,
};

const registrationDetailsSlice = createSlice({
  name: "Registration Details",
  initialState: initialState,
  reducers: {
    updateUserDetails(state, action: PayloadAction<RegistrationStructure>) {
      return (state = action.payload);
    },
    clearUserDetails() {
      return initialState;
    },
    onToggleTerms(state, action: PayloadAction<boolean>) {
      state.isAccepted = action.payload;
    },
    onToggleSubscribe(state, action: PayloadAction<boolean>) {
      state.isSubscribe = action.payload;
    },
  },
});

const { actions, reducer } = registrationDetailsSlice;
export type RegistrationDetailsActionType = AllActionsOf<typeof actions>;
export {
  actions as RegistrationDetailsAction,
  reducer as registrationDetailsReducer,
  initialState,
};
