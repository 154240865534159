import React from "react";

interface MyLocationButtonProps {
  icon: string;
}

const MyLocationButton: React.FC<MyLocationButtonProps> = ({
  icon,
}): JSX.Element => {
  return (
    <div className='myLocationButton'>
      <img src={icon} alt={"my location button"} />
    </div>
  );
};

export default MyLocationButton;
