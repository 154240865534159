import { isEqual, sortBy, uniqWith } from "lodash";

import { AnalyticsInstance } from "../../analytics";
import {
  Category,
  MenuListItemProps,
  MenuSection,
  MenuSubsection,
  MultiPart,
  Product,
} from "../../redux_store/menu/models";
import { Events } from "../Analytics/const";
import { UpsellItem, UpsellSection, UpsellSource } from "./model";

export const findUpsellItemInMenu = (
  arrays: Category[] | Product[] | MultiPart[],
  upsellCategories: UpsellItem[]
): Category[] | Product[] | MultiPart[] => {
  const result = [];
  for (const i of arrays) {
    for (const upsellCategory of upsellCategories) {
      if (i.id === upsellCategory.id) {
        const item = JSON.parse(JSON.stringify(i));
        item.displayOrder = upsellCategory.displayOrder;
        result.push(item);
      }
    }
  }
  return result as Category[] | Product[] | MultiPart[];
};

export const findCategoryById = (
  upsellCategories: UpsellItem[],
  subSections: MenuSubsection[]
): Category[] => {
  const categories: Category[] = [];
  for (const subSection of subSections) {
    if (subSection.categories) {
      const items = findUpsellItemInMenu(
        subSection.categories,
        upsellCategories
      ) as Category[];
      if (items.length > 0) {
        items.forEach((item) => categories.push(item as Category));
      }
    }
  }

  return categories;
};

export const findProductById = (
  upsellProducts: UpsellItem[],
  subSections: MenuSubsection[]
): Product[] => {
  const products: Product[] = [];
  for (const subSection of subSections) {
    if (subSection.products) {
      const items = findUpsellItemInMenu(
        subSection.products,
        upsellProducts
      ) as Product[];
      if (items.length > 0) {
        items.forEach((item) => products.push(item as Product));
      }
    }
  }
  return products;
};

export const findMultipartById = (
  upsellMultiparts: UpsellItem[],
  subSections: MenuSubsection[]
): MultiPart[] => {
  const multiparts: MultiPart[] = [];
  for (const subSection of subSections) {
    if (subSection.multiParts) {
      const items = findUpsellItemInMenu(
        subSection.multiParts,
        upsellMultiparts
      ) as MultiPart[];
      if (items.length > 0) {
        items.forEach((item) => multiparts.push(item as MultiPart));
      }
    }
  }
  return multiparts;
};

const pushItemToUpsell = (
  upsellSection: UpsellSection,
  menuSection: MenuSection,
  menuListItem: MenuListItemProps[]
) => {
  if (upsellSection.categories.length > 0) {
    const categories = findCategoryById(
      upsellSection.categories,
      menuSection.subSections
    );

    if (categories.length > 0) {
      categories.forEach((category) => {
        menuListItem.push({
          category: category,
        });
      });
    }
  }
  if (upsellSection.products.length > 0) {
    const products = findProductById(
      upsellSection.products,
      menuSection.subSections
    );

    if (products.length > 0) {
      products.forEach((product) => {
        menuListItem.push({
          product: product,
        });
      });
    }
  }
  if (upsellSection.multiParts.length > 0) {
    const multiparts = findMultipartById(
      upsellSection.multiParts,
      menuSection.subSections
    );

    if (multiparts) {
      multiparts.forEach((multipart) => {
        menuListItem.push({
          multiPart: multipart,
        });
      });
    }
  }
};

export const convertToMenuSection = (
  items: UpsellSection[],
  menuSection: MenuSection,
  menuSectionEnabled: number[]
): MenuListItemProps[] => {
  const menuListItem: MenuListItemProps[] = [];

  for (const upsellSection of items) {
    //Loops through all upsell section if menuSectionEnabled is more than 1
    if (menuSectionEnabled?.length > 1) {
      pushItemToUpsell(upsellSection, menuSection, menuListItem);

      //Only use certain upsell section if menuSectionEnabled is one
    } else if (menuSection?.id === upsellSection.sectionId) {
      pushItemToUpsell(upsellSection, menuSection, menuListItem);
    }
  }

  const sortMenuList = sortBy(
    menuListItem,
    (item) => [
      item.multiPart?.displayOrder,
      item.product?.displayOrder,
      item.category?.displayOrder,
    ],
    ["asc"]
  );

  const uniqueMenuList = uniqWith(sortMenuList, isEqual);

  return uniqueMenuList;
};

const mapUpsellForTracking = (upsellItems: MenuListItemProps[]) => {
  return upsellItems.map((upsellItem) => {
    const data = upsellItem.category || upsellItem.product;

    return { id: data?.id, name: data?.name };
  });
};

interface UpsellTrackParams {
  userId: string | null;
  basketId: string | null;
  upsellItems: MenuListItemProps[];
  source: UpsellSource;
}
export const trackUpsellItem = (params: UpsellTrackParams) => {
  const eventPayload = {
    userId: params.userId,
    basketId: params.basketId,
    products: mapUpsellForTracking(params.upsellItems),
    source: params.source,
  };

  AnalyticsInstance.trackEvent(Events.UpsellSelection, eventPayload);
};
