import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ErrorResponse } from "../error/models";
import { StoreMenu } from "../store/models";
import { AllActionsOf } from "../utils/Actions";
import {
  GetMenuStructureProps,
  GetMenuStructureSuccess,
  MenuSection,
  MenuStructure,
} from "./models";

export interface MenuState {
  loading: boolean;
  menuStructure?: MenuStructure;
  menuSectionEnabled: number[];
  activeTab: string;
  error: ErrorResponse;
  posMenuId: number;
  validMenuSection?: MenuSection;
  menuOpenTime: StoreMenu;
  needScrollToHotDrinks: boolean;
}

const initialState: MenuState = {
  loading: false,
  menuStructure: undefined,
  menuSectionEnabled: undefined,
  activeTab: undefined,
  error: undefined,
  posMenuId: undefined,
  validMenuSection: undefined,
  needScrollToHotDrinks: false,
  menuOpenTime: {
    isMenuOpen: false,
    willMenuOpenForToday: true,
    menuTimePeriods: [],
  },
};

const menuSlice = createSlice({
  name: "Menu",
  initialState: initialState,
  reducers: {
    loading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getMenuStructure(state, action: PayloadAction<GetMenuStructureProps>) {
      state.loading = true;
      state.error = undefined;
    },
    getMenuStructureSuccess(
      state,
      action: PayloadAction<GetMenuStructureSuccess>
    ) {
      state.menuStructure = action.payload.menuStructure;
      state.menuSectionEnabled = action.payload.menuSectionEnabled;
      state.activeTab = action.payload.activeTab;
      state.posMenuId = action.payload.posMenuId;
      state.loading = false;
      state.error = undefined;
    },
    setMenuSectionEnabled(state, action: PayloadAction<number[]>) {
      state.menuSectionEnabled = action.payload;
    },
    setActiveTab(state, action: PayloadAction<string>) {
      state.activeTab = action.payload;
    },
    setValidMenuSection(state, action: PayloadAction<MenuSection>) {
      state.validMenuSection = action.payload;
    },
    setMenuOpenTime(state, action: PayloadAction<StoreMenu>) {
      state.menuOpenTime = action.payload;
    },
    needScrollToHotDrinks(state, action: PayloadAction<boolean>) {
      state.needScrollToHotDrinks = action.payload;
    },
    error(state, action: PayloadAction<ErrorResponse>) {
      state.error = action.payload;
      state.loading = false;
      state.menuSectionEnabled = undefined;
      state.menuStructure = undefined;
      state.validMenuSection = undefined;
      state.menuOpenTime = initialState.menuOpenTime;
    },
    resetMenuStructure(state) {
      state.error = null;
      state.loading = false;
      state.menuSectionEnabled = undefined;
      state.menuStructure = undefined;
      state.validMenuSection = undefined;
      state.menuOpenTime = initialState.menuOpenTime;
    },
  },
});

const { actions, reducer } = menuSlice;
export type MenuActionsType = AllActionsOf<typeof actions>;
export { initialState, actions as MenuActions, reducer as menuReducer };
