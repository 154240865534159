/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Effects from "redux-saga/effects";
import { fork, put, takeLatest } from "redux-saga/effects";

import { formatUserDataPayload } from "../../modules/User/utils";
import * as ApiService from "../../services/api/user";
import { PatchUserEmailResponse } from "../../services/api/user/model";
import { HttpStatus } from "../../services/httpClient";
import { AnalyticsAction } from "../analytics/analytics.slice";
import { authActions } from "../auth/auth.slice";
import { CheckoutAction } from "../checkout/checkout.slice";
import { errorActions } from "../error/error.slice";
import { ErrorResponse } from "../error/models";
import { buildErrorResponse } from "../error/utils";
import {
  ChangePasswordResponse,
  UserAccountRequest,
  UserProfile,
} from "./models";
import { userActions, UserActionsType } from "./user.slice";

const call: any = Effects.call;

//HANDLING FUNCTION STARTS HERE
//MARK:- Get user account detail/user profile
export function* handleUserProfile(
  action: UserActionsType
): Generator<any, void, UserProfile> {
  try {
    const result: UserProfile = yield call(
      ApiService.getUserData,
      action.payload
    );
    yield put(CheckoutAction.setSavePayment(result.storeCard));
    yield put(userActions.setUserProfile(result));
  } catch (e) {
    const errorResponse: ErrorResponse = buildErrorResponse(e);
    yield put(userActions.setUserProfileError(errorResponse));
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* handleUpdateUserProfile(
  action: UserActionsType
): Generator<any, void, number> {
  try {
    const payload = formatUserDataPayload(action.payload as UserAccountRequest);
    const result: number = yield call(ApiService.updateUserData, payload);
    if (result === HttpStatus.Ok) {
      yield put(AnalyticsAction.setUpdateUserAccountToState(payload));
      yield put(userActions.updateUserProfileSuccess());
      yield put(userActions.getUserProfile());
    } else {
      yield put(userActions.updateUserProfileError());
    }
  } catch (e) {
    const errorResponse: ErrorResponse = buildErrorResponse(e);

    yield put(userActions.updateUserProfileError(errorResponse));
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* handlePostTermsAndConditions(
  action: UserActionsType
): Generator<unknown, void, boolean> {
  try {
    const result = yield call(
      ApiService.postTermsAndConditions,
      action.payload
    );
    yield put(userActions.postTermsAndConditionsSuccess(result));
  } catch (e) {
    yield put(userActions.postTermsAndConditionsError(e));
  }
}

export function* watchPostTermsAndConditions(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    userActions.postTermsAndConditions.type,
    handlePostTermsAndConditions
  );
}

//MARK:- Update user password
export function* handleUpdateUserPassword(
  action: UserActionsType
): Generator<any, void, ChangePasswordResponse> {
  try {
    const result: ChangePasswordResponse = yield call(
      ApiService.updateUserPassword,
      action.payload
    );
    yield put(userActions.changeUserPasswordSuccess(result));
  } catch (e) {
    const errorResponse: ErrorResponse = buildErrorResponse(e);

    yield put(userActions.changeUserPasswordFailure(errorResponse));
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* handleSetUserPassword(
  action: UserActionsType
): Generator<any, void, ChangePasswordResponse> {
  try {
    const result: ChangePasswordResponse = yield call(
      ApiService.setUserPassword,
      action.payload
    );

    yield put(userActions.changeUserPasswordSuccess(result));
  } catch (e) {
    const errorResponse: ErrorResponse = buildErrorResponse(e);

    yield put(userActions.changeUserPasswordFailure(errorResponse));
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* handleGetMobileVerificationCode(
  action: UserActionsType
): Generator<any, void> {
  try {
    yield call(ApiService.getMobileVerificationCode, action.payload);
    yield put(userActions.getMobileVerificationCodeSuccess());
  } catch (e) {
    const errorResponse: ErrorResponse = buildErrorResponse(e);

    yield put(userActions.getMobileVerificationFailed(errorResponse));
  }
}

export function* handleUpdateUserEmail(
  action: UserActionsType
): Generator<any, void, PatchUserEmailResponse> {
  try {
    const result = yield call(ApiService.updateUserEmail, action.payload);
    yield put(
      AnalyticsAction.setUpdateUserAccountToState({
        email: result.email,
      })
    );
    yield put(userActions.updateUserEmailSuccess(result));
  } catch (e) {
    const errorResponse: ErrorResponse = buildErrorResponse(e);

    yield put(userActions.updateUserEmailFailed(errorResponse));
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* handleDeleteAccountVerificationCode(): Generator<any, void> {
  try {
    yield call(ApiService.requestDeleteAccountCode);
    yield put(userActions.deleteAccountVerificationCodeSuccess());
  } catch (e) {
    const errorResponse: ErrorResponse = buildErrorResponse(e);

    yield put(userActions.deleteAccountVerificationCodeFailed(errorResponse));
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* handleVerifyDeleteAccountCode(
  action: UserActionsType
): Generator<any, void> {
  try {
    const result = (yield call(
      ApiService.verifyDeleteAccountCode,
      action.payload
    )) as boolean;
    yield put(userActions.verifyDeleteAccountCodeSuccess(result));
    yield put(authActions.logoutCurrentDevice());
    yield put(authActions.unsetToken());
  } catch (e) {
    const errorResponse: ErrorResponse = buildErrorResponse(e);

    yield put(userActions.verifyDeleteAccountFailed(errorResponse));
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

//WATCH FUNCTIONS STARTS HERE
export function* watchUserProfile(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(userActions.getUserProfile.type, handleUserProfile);
}

export function* watchUpdateUserProfile(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(userActions.updateUserProfile.type, handleUpdateUserProfile);
}

export function* watchSetUserPassword(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(userActions.setUserPassword.type, handleSetUserPassword);
}

export function* watchUpdateUserPassword(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    userActions.changeUserPassword.type,
    handleUpdateUserPassword
  );
}

export function* watchGetMobileVerificationCode(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    userActions.getMobileVerificationCode.type,
    handleGetMobileVerificationCode
  );
}

export function* watchUpdateUserEmail(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(userActions.updateUserEmail.type, handleUpdateUserEmail);
}

export function* watchDeleteAccountVerificationCode(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    userActions.deleteAccountVerificationCode.type,
    handleDeleteAccountVerificationCode
  );
}

export function* watchVerifyDeleteAccountCode(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    userActions.verifyDeleteAccountCode.type,
    handleVerifyDeleteAccountCode
  );
}

export function* handleVerifyEmailAddress(
  action: UserActionsType
): Generator<unknown, void> {
  try {
    yield call(ApiService.verifyEmailAddress, action.payload);
    yield put(userActions.verifyEmailAddressSuccess());
  } catch (e) {
    const errorResponse: ErrorResponse = buildErrorResponse(e);
    yield put(userActions.verifyEmailAddressError(errorResponse));
  }
}

export function* watchVerifyEmailAddress() {
  yield takeLatest(
    userActions.verifyEmailAddress.type,
    handleVerifyEmailAddress
  );
}

const saga = [
  fork(watchUserProfile),
  fork(watchUpdateUserProfile),
  fork(watchSetUserPassword),
  fork(watchUpdateUserPassword),
  fork(watchPostTermsAndConditions),
  fork(watchGetMobileVerificationCode),
  fork(watchUpdateUserEmail),
  fork(watchDeleteAccountVerificationCode),
  fork(watchVerifyDeleteAccountCode),
  fork(watchVerifyEmailAddress),
];

export default saga;
