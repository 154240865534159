import { AnalyticsState } from "../../redux_store/analytics/models";
import { AnalyticsType } from "./model";

export const abandonCartPayload = (state: AnalyticsState): AnalyticsType => {
  return {
    product_id: state.productId,
    product_name: state.productName,
    product_category: state.productCategory,
    product_quantity: state.productQuantity,
    store_name: state.storeName,
    store_id: state.storeId,
    order_type: state.orderType,
    order_date: state.orderDate,
    order_time: state.orderTime,
    basket_id: state.basketId,
    points_added_to_loyalty: state.pointsAddedtoLoyalty,
    points_to_conversion: state.pointsToConversion,
    recent_orders: state.recentOrders,
    table_service_origin: state.tableInit,
    featured_items: state.featuredItems,

    //TODO: To implement once features is implemented
    // group_order: ''
  };
};
