import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { isOlderThanActiveOrderThreshold } from "../../../common/modules/Order/utils";
import {
  BasketVerificationLoyalty,
  GetOrderResponse,
} from "../../services/api/order/model";
import { CheckoutResponse, RecentAndCurrentOrders } from "../checkout/model";
import { AllActionsOf } from "../utils/Actions";
import { GuestDetailsState, GuestPersistState } from "./models";

const initialState: GuestPersistState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  orders: [],
  currentOrders: [],
  recentOrders: [],
  guestLoyalty: {
    accruedPoints: 0,
    convertedDollars: 0,
    rewardAmount: 0,
    rewardConversionLimit: 0,
  },
};

const guestSlice = createSlice({
  name: "Guest Detail",
  initialState: initialState,
  reducers: {
    updateGuestDetails(state, action: PayloadAction<GuestDetailsState>) {
      const payload = action.payload;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.phoneNumber = payload.phoneNumber;
      state.email = payload.email;
    },
    setGuestOrderLoyaltyPoints(
      state,
      action: PayloadAction<BasketVerificationLoyalty>
    ) {
      state.guestLoyalty = {
        accruedPoints: action.payload.accruedPoints,
        convertedDollars: action.payload.convertedDollars,
        rewardAmount: action.payload.loyaltyRewardAmount,
        rewardConversionLimit: action.payload.loyaltyRewardConversionLimit,
      };
    },
    clearGuestOrderLoyaltyPoints(state) {
      state.guestLoyalty = {
        accruedPoints: 0,
        convertedDollars: 0,
        rewardAmount: 0,
        rewardConversionLimit: 0,
      };
    },
    updateOrderStatus(state, action: PayloadAction<GetOrderResponse>) {
      const index = state.orders.findIndex(
        (obj) => obj.order.orderId == action.payload.orderId
      );
      if (
        index !== -1 &&
        state.orders[index].order.orderFinalisationStatus !==
          action.payload.orderFinalisationStatus
      ) {
        const order = state.orders[index].order;
        order.orderFinalisationStatus = action.payload.orderFinalisationStatus;
        order.orderProgressStatus = action.payload.orderProgressStatus;
      }
    },
    storeGuestOrder(state, action: PayloadAction<CheckoutResponse>) {
      // Since the latest order should always on top of the array, therefore `unshift` is used instead of push
      state.orders.unshift(action.payload);
      if (isOlderThanActiveOrderThreshold(action.payload)) {
        state.recentOrders.unshift(action.payload);
      } else {
        state.currentOrders.unshift(action.payload);
      }
    },
    storeGuestOrders(state, action: PayloadAction<CheckoutResponse[]>) {
      state.orders = action.payload;
      const orders: RecentAndCurrentOrders = {
        recentOrders: [],
        currentOrders: [],
      };
      action.payload.forEach((order) => {
        if (isOlderThanActiveOrderThreshold(order)) {
          orders.recentOrders.push(order);
        } else {
          orders.currentOrders.push(order);
        }
      });
      state.recentOrders = orders.recentOrders;
      state.currentOrders = orders.currentOrders;
    },
    removeGuestOrder(state, action: PayloadAction<string[]>) {
      // removes from guest state orders that were assigned to registered account thru addtoAccount
      const ordersToRemove = new Set(action.payload);
      const orders = state.orders.filter(
        (order: CheckoutResponse) => !ordersToRemove.has(order.order.orderId)
      );
      state.orders = orders;
    },
  },
});

const { actions, reducer } = guestSlice;
export type GuestDetailActionType = AllActionsOf<typeof actions>;
export { actions as guestActions, reducer as guestReducer, initialState };
