import React from "react";
import {
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";

import * as PlatformUtils from "../../modules/platformUtils";
import closeImg from "../assets/icons/icon_close.png";
import { PressableOpacity } from "../BaseComponents/PressableOpacity/PressableOpacity";
import PrimaryBlackButton from "../Buttons/PrimaryBlackButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import colours from "../styles/colours";
import { Spacing } from "../styles/number";
import { Typography } from "../styles/typography";
import MiddleModal, { WebModalSize } from "./MiddleModal";

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  containerWithoutClose: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: Spacing.Regular,
  },
  image: {
    marginTop: Spacing.Light,
    marginBottom: Spacing.Medium,
    marginHorizontal: Spacing.Regular,
    width: 264,
    height: 212,
  },
  icon: {
    alignSelf: "flex-end",
    width: 32,
    height: 32,
    marginTop: Spacing.Light,
    marginRight: Spacing.Light,
  },
  headingWrapper: {
    ...Platform.select({
      web: {
        width: "100%",
        paddingHorizontal: Spacing.Light,
      },
      ios: {
        width: 294,
        marginBottom: Spacing.Thin,
      },
      android: {
        width: 300,
        marginBottom: Spacing.Thin,
      },
    }),
  },
  heading: {
    ...Typography.largeTitle,
    color: colours.darkGrey,
    textAlign: "center",
  },
  messageContainer: {
    paddingHorizontal: Spacing.Light,
  },
  message: {
    marginTop: Spacing.Light,
    ...Typography.body,
    color: colours.darkGrey,
    textAlign: "center",
  },
  messageBold: {
    marginTop: Spacing.Light,
    ...Typography.bodyBold,
    color: colours.darkGrey,
    textAlign: "center",
  },
  linkText: {
    marginTop: Spacing.Light,
    ...Typography.linkText,
    textAlign: "center",
  },
  secondaryButton: {
    flex: 0.5,
    marginRight: Spacing.Thin,
  },
  primaryButton: {
    flex: 0.5,
    marginLeft: Spacing.Thin,
  },
  fullPrimaryButton: {
    flex: 1,
    marginLeft: Spacing.Thin,
  },
  buttonContainer: {
    marginTop: Spacing.Medium,
    marginBottom: Spacing.Light,
    paddingHorizontal: Spacing.Light,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  emptyView: {
    marginBottom: Spacing.Light,
  },
});
export interface ButtonProps {
  name: string;
  action: () => void;
}
export interface ModalWithButtonProps {
  primaryButton: ButtonProps;
  secondaryButton?: ButtonProps;
  title: string;
  message?: string | null;
  messageBold?: string | null;
  image?: ImageSourcePropType;
  isVisible: boolean;
  testId?: string;
  onDismiss?: () => void;
  onModalClose: () => void;
  linkText?: string;
  linkPress?: () => void;
  showCloseButton?: boolean;
  primaryButtonLoading?: boolean;
  small?: boolean;
  large?: boolean;
  modalId?: string;
}
const ModalWithButton: React.FC<ModalWithButtonProps> = ({
  primaryButton,
  secondaryButton,
  title,
  message,
  messageBold,
  image,
  isVisible,
  testId,
  modalId,
  onDismiss,
  onModalClose,
  linkText,
  linkPress,
  showCloseButton = true,
  primaryButtonLoading,
  small,
  large,
}) => {
  const id = () => {
    if (Platform.OS === "web") {
      return modalId;
    } else {
      return testId ?? "ModalWith";
    }
  };

  const webModalSizes = () => {
    if (small) {
      return WebModalSize.SMALL;
    } else if (large) {
      return WebModalSize.LARGE;
    } else {
      return WebModalSize.AUTO;
    }
  };

  return (
    <MiddleModal
      testId={id()}
      onAndroidBackPress={onModalClose}
      isVisible={isVisible}
      webModalSize={webModalSizes()}
      onDimiss={onDismiss}>
      <View
        style={
          showCloseButton ? styles.container : styles.containerWithoutClose
        }>
        {showCloseButton && (
          <TouchableWithoutFeedback onPress={onModalClose}>
            <Image
              {...PlatformUtils.generateTestID(Platform.OS, "close-icon-modal")}
              style={styles.icon}
              source={closeImg as ImageSourcePropType}
            />
          </TouchableWithoutFeedback>
        )}

        {image ? (
          <Image
            {...PlatformUtils.generateTestID(Platform.OS, "main-image")}
            resizeMode='contain'
            style={styles.image}
            source={image}
          />
        ) : (
          <View style={styles.emptyView} />
        )}

        <View style={styles.headingWrapper}>
          <Text style={styles.heading}>{title}</Text>
        </View>
        <View style={styles.messageContainer}>
          {message && <Text style={styles.message}>{message}</Text>}
          {messageBold && <Text style={styles.messageBold}>{messageBold}</Text>}

          {linkText && linkPress && (
            <PressableOpacity
              onPress={linkPress}
              {...PlatformUtils.generateTestID(Platform.OS, linkText)}>
              <Text style={styles.linkText}>{linkText}</Text>
            </PressableOpacity>
          )}
        </View>

        <View style={styles.buttonContainer}>
          {secondaryButton && (
            <View style={styles.secondaryButton}>
              <TertiaryButton
                title={secondaryButton.name}
                onClick={secondaryButton.action}
              />
            </View>
          )}

          <View
            style={
              secondaryButton ? styles.primaryButton : styles.fullPrimaryButton
            }>
            <PrimaryBlackButton
              loading={primaryButtonLoading}
              testId='ModalPrimaryButton'
              buttonName={primaryButton.name}
              onClick={primaryButton.action}
            />
          </View>
        </View>
      </View>
    </MiddleModal>
  );
};

export default ModalWithButton;
