import { CoffeeLoyalty } from "redux_store/loyalty/models";

import { UpsellSource } from "../../../modules/Upsell/model";
import {
  AssociatedOffer,
  Modifier,
  ProductDiscountsApplied,
} from "../../../redux_store/cart/models";
import {
  OrderFinalisationStatusType,
  OrderFulfilmentStatusType,
  OrderProgressStatus,
} from "../../../redux_store/checkout/model";
import {
  CollectionType,
  DeliveryOrder,
} from "../../../redux_store/order/models";
import { VersionAndPlatformData } from "../../httpClient";
import { TagLookup } from "../menu/model";

export interface ItemPayload {
  id: number;
  name: string;
  miamGUID?: string;
  upsellSource?: string;
  quantity: number;
  removeModifiers?: number[];
  addModifiers?: number[];
  extraModifiers?: number[];
  addFillings?: number[];
  associatedOffers?: AssociatedOffer[];
  productDiscountsApplied?: ProductDiscountsApplied[];
  parts?: ItemPayload[];
  menuFlowId?: number;
}
export interface GetOrderPayload {
  sessionId: string;
}

export interface CreateOrderPayload extends VersionAndPlatformData {
  storeId: number;
  basketItems: ItemPayload[];
  collectionType: CollectionType;
  delivery: DeliveryOrder | null;
  pickUpTime: string;
  posMenuId: number;
  tableNumber?: string;
  coupons?: {
    code: string;
  }[];
  gygDollars?: {
    amount: number;
  };
}

export interface UpdateOrderPayload extends VersionAndPlatformData {
  orderId: string;
  orderPayload: CreateOrderPayload;
  orderTimeUpdated?: boolean;
}

export interface OrderResponse {
  basket: Basket;
  orderTimes: OrderTimes[];
  pickupTimeOffsets: PickupTimeOffsets[];
  orderNumber: string;
  orderId: string;
  loyalty: BasketVerificationLoyalty;
  gygDollars?: GYGDollars;
  errors?: OrderErrorResponse[];
  pickUpTime?: string;
  orderFinalisationStatus?: OrderFinalisationStatusType;
  orderFulfilmentStatus?: OrderFulfilmentStatusType;
  orderProgressStatus?: OrderProgressStatus;
  delivery?: DeliveryOrder;
}

export interface UpdateOrderResponse {
  orderResponse: OrderResponse;
  orderTimeUpdated: boolean;
}

export interface OrderErrorResponse {
  error: number;
  errorDescription: string;
}

export interface BasketVerificationLoyalty {
  actualDollarsBalance: number;
  actualPointsBalance: number;
  cardNumber: string;
  loyaltyRewardAmount: number;
  loyaltyRewardConversionLimit: number;
  accruedPoints: number;
  convertedDollars: number;
  afterCheckoutDollarsBalance: number;
  afterCheckoutPointsBalance: number;
  totalBonusPoints: number;
  totalBonusDollars: number;
}

export interface GYGDollars {
  applied: number;
  errors: OrderErrorResponse[];
}

export enum LoyaltyBonusType {
  GYGDollar = "GYGDOLLAR",
  GYGPoints = "GYGPOINTS",
}

export interface ChildItem {
  id: number;
  productId: number;
  name: string;
  childItems: ChildItem[];
  quantity: number;
  unitPrice: number;
  price: number;
  isFavourite: boolean;
}

export interface BasketItem {
  id: number;
  name: string;
  miamGUID?: string;
  quantity: number;
  unitPrice: number;
  price: number;
  removeModifiers?: Modifier[];
  addModifiers?: Modifier[];
  extraModifiers?: Modifier[];
  addFillings?: Modifier[];
  defaultModifiers?: Modifier[];
  parts?: BasketItem[];
  menuFlowId?: number;
  posPlu?: number;
  category?: string;
  displayText?: string;
  originalPrice?: number;
  tags?: string[];
  upsellSource?: UpsellSource;
  associatedOffers?: AssociatedOffer[];
  productDiscountsApplied?: ProductDiscountsApplied[];
  isOfferProduct?: boolean;
}

export interface DeliveryAvailability {
  deliveryAvailable: boolean;
  deliveryFeeItemAdded: boolean;
  deliveryFee?: unknown;
  deliveryDistance?: unknown;
  storeDeliveryRadius?: unknown;
  minBasketSize?: unknown;
  deliveryErrorMessage: string;
  deliveryAccepted: boolean;
}

export enum BasketCouponType {
  LoyaltyCoffee = "FREE_ITEM_BY_SECTION_ID",
}
export interface BasketCoupon {
  code: string;
  errors: [
    {
      error: number;
      errorDescription: string;
    },
  ];
  couponType?: BasketCouponType;
}

export interface Basket {
  coupons?: BasketCoupon[];
  tagLookup: TagLookup[];
  basketItems: BasketItem[];
  salesTax: number; // US tax
  taxRate: number;
  totalIncl: number; // total order with tax
  totalExcl: number; // total order without tax
  surcharges?: Surcharge[];
  coffeeLoyalty?: CoffeeLoyalty;
  discountApplied: boolean;
  discountAmount: number;
  totalBeforeDiscount: number;
  loyalty?: unknown;
  voucherCode?: unknown;
  storeInvalidProducts: BasketItem[];
  errors?: [
    {
      error: number;
      errorDescription: string;
    },
  ];
  basketValue: number;
  total: number;
  subtotal: number;
}

export interface OrderTimes {
  date: string;
  timeSlots: string[];
}

export interface PickupTimeOffsets {
  periodStart: string; //"2023-05-13T12:00:00", For readability in the Datadog logs
  periodEnd: string; //"2023-05-13T13:59:59", For readability in the Datadog logs
  offset: number; //4
}

export interface GetOrderByOrderIdPayload {
  orderId: string;
}

export interface GetOrderOffsetPayload {
  offset?: number;
}

export interface GetOrderResponse {
  orderId: string;
  timeSubmitted: string;
  pickUpTime: string;
  activeOrderUntil: string;
  orderFinalisationStatus: OrderFinalisationStatusType;
  orderFulfilmentStatus: OrderFulfilmentStatusType;
  orderProgressStatus: OrderProgressStatus;
  orderCollectionType: CollectionType;
  loyalty: Partial<BasketVerificationLoyalty>;
  delivery?: { trackingUrl: string; trackingUrlExpiresAt: string; fee: number };
}

export interface AddToAccountPayload {
  ordersWithLoyalty: string[];
  ordersToAssign: string[];
}

export interface AddToAccountResponse {
  assignedOrders: string[];
  skippedOrders: string[];
  loyaltyAwardedOrders: string[];
  emailMismatch: string[];
}

export interface RateOrderPayload {
  rating: number;
  brazeId: string;
  userFeedback: string;
  orderId: string;
}

export interface Surcharge {
  name: string;
  description: string;
  amount: number;
  surchargeAmount: number;
  surchargePercentage: number;
}

export interface RecentAddressesResponse {
  city: string;
  googlePlaceId: string;
  postCode: number;
  userId: string;
  longitude: number;
  createdAt: string;
  country: string;
  latitude: number;
  formattedAddress: string;
  state: string;
  addressLines: string[];
}
