import { CartItem } from "../../../redux_store/cart/models";
import { FavouriteCartItem } from "../../../redux_store/favourite/models";
import { compareCartPredicates } from "./compareCartPredicates";

export const compareCartItemWithFavourite = (
  cartItem: CartItem,
  favouriteCartItem: FavouriteCartItem
): boolean => {
  const failedPredicate = compareCartPredicates.find((predicate) => {
    const result = predicate({
      cartItem,
      favouriteCartItem,
    });
    return !result;
  });

  const hasFailedPredicate = !!failedPredicate;
  if (hasFailedPredicate) {
    return false;
  }
  return true;
};

export const isFavouriteItem = (
  cartItem: CartItem,
  favouriteCartItems: FavouriteCartItem[]
): boolean => {
  const matchingFavouriteItem = favouriteCartItems.find((favouriteCartItem) => {
    const result = compareCartItemWithFavourite(cartItem, favouriteCartItem);
    return result;
  });

  return !!matchingFavouriteItem;
};
