import GoogleMapReact from "google-map-react";
import React from "react";
import { Image, ImageSourcePropType, StyleSheet, View } from "react-native";

import config from "../../config";
import GYGMapPin from "../assets/images/pin_big_yellow.png";

export interface StaticMapProps {
  lat: number;
  lng: number;
  icon: ImageSourcePropType;
}

const styles = StyleSheet.create({
  map: {
    width: "100%",
    height: 180,
  },
  marker: {
    width: 60,
    height: 68,
  },
});

const mapOptions = {
  disableDefaultUI: true,
  mapTypeControl: false,
  zoomControl: false,
  streetViewControl: false,
  rotateControl: false,
  scaleControl: false,
  fullscreenControl: false,
  gestureHandling: "none",
  draggable: false,
  clickableIcons: false,
};

const StaticMap: React.FC<StaticMapProps> = ({ lat, lng, icon }) => {
  return (
    <View style={styles.map}>
      <GoogleMapReact
        defaultCenter={{ lat, lng }}
        defaultZoom={15}
        bootstrapURLKeys={{
          key: config.googleMapsKey,
          libraries: ["places"],
          id: config.googleMapsScriptId,
        }}
        yesIWantToUseGoogleMapApiInternals
        options={mapOptions}>
        <Image source={icon || GYGMapPin} style={styles.marker} />
      </GoogleMapReact>
    </View>
  );
};

export default StaticMap;
