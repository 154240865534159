/* eslint-disable @typescript-eslint/no-empty-function */
import * as braze from "@braze/web-sdk";

import config from "config";
import { AnalyticsConst } from "gyg_common";
import { IAnalytics, TealiumView } from "gyg_common/analytics";
import { AnalyticsType } from "gyg_common/modules/Analytics/model";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const utag = (window as any).utag || {
  link: () => {},
  view: () => {},
  loader: {
    SC: () => {},
  },
};

const tealimConfig = {
  tealium_environment: config.analyticsEnvironment,
  tealium_datasource: config.analyticsDataSourceKey,
  tealium_profile: config.analyticsProfile,
};

export class Analytics implements IAnalytics {
  trackView = (view: TealiumView): void => {
    utag.view({
      ...tealimConfig,
      ...view,
    });
    try {
      braze.logCustomEvent("page_view", { screen: view.page_name });
    } catch (error) {
      // braze likely not initialised yet
    }
  };

  trackEvent = (
    event: AnalyticsConst.Events,
    // eslint-disable-next-line @typescript-eslint/ban-types
    eventPayload: AnalyticsType
  ): void => {
    utag.link({
      ...tealimConfig,
      tealium_event: event,
      ...eventPayload,
    });
    try {
      if (eventPayload) {
        braze.logCustomEvent(event, eventPayload);
      } else {
        braze.logCustomEvent(event);
      }
    } catch (error) {
      // braze likely not initialised yet
    }
  };

  trackBrazeEvent = (event: string | AnalyticsConst.Events): void => {
    braze.logCustomEvent(event);
  };

  setCustomAttribute(
    attributeName: string,
    attributeValue: string | number | boolean | Date
  ) {
    braze.getUser()?.setCustomUserAttribute(attributeName, attributeValue);
  }
}
