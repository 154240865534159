import { CurrentUser } from "../../redux_store/auth/models";
import { UserAccountRequest, UserProfile } from "../../redux_store/user/models";
import { RegistrationPayload } from "../../services/api/registration/model";
import {
  convertDateMonthFirstToDayFirst,
  dateFormatter,
} from "../stringBuilders";

export const formatUserData = (userData: UserProfile): UserProfile => {
  return {
    ...userData,
    email: userData?.email || "",
    dateOfBirth: dateFormatter(userData?.dateOfBirth) || "",
  };
};

/*
  When registering or updating user it formats the payload before
  sending e.g. dateOfBirth to accepted format "DD/MM/YYYY"
*/
export const formatUserDataPayload = (
  userDataPayload: UserAccountRequest | RegistrationPayload
) => {
  let dateOfBirth = userDataPayload.dateOfBirth;
  if (dateOfBirth) {
    dateOfBirth = convertDateMonthFirstToDayFirst(dateOfBirth);
    return { ...userDataPayload, ...{ dateOfBirth } };
  }
  return userDataPayload;
};

export const mapCurrentUserToUserProfile = (
  currentUser: CurrentUser
): UserProfile => {
  return {
    id: null,
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    phoneNumber: currentUser.phoneNumber,
    verifiedEmail: currentUser.verifiedEmail,
    verifiedMobileNumber: currentUser.verifiedMobileNumber,
    email: currentUser.email,
    dateOfBirth: null,
    cardNumber: currentUser.cardNumber,
    updatedTCs: false,
    loyalty: {
      loyaltyRewardConversionLimit: 0,
      loyaltyRewardAmount: 0,
      actualPointsBalance: 0,
      actualDollarsBalance: 0,
    },
    activatedAuthenticationTypes: [],
    authenticationSourceType: "",
    brazeId: currentUser.brazeId,
    accessToken: currentUser.accessToken,
    expiry: currentUser.expiry,
    refreshToken: currentUser.refreshToken,
    registrationComplete:
      currentUser.registrationComplete ?? currentUser.isExistingAccount
        ? "true"
        : undefined,
    referralLink: currentUser.referralLink,
    branchId: currentUser.branchId,
    userName: currentUser.userName,
  };
};
