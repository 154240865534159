/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  RegistrationPayload,
  SocialRegistrationPayload,
  VerificationCodePayload,
  VerifyCodePayload,
  VerifyResponse,
} from "../../services/api/registration/model";
import { ErrorResponse } from "../error/models";
import { AllActionsOf } from "../utils/Actions";
import { RegistrationState } from "./model";

const initialState: RegistrationState = {
  loading: false,
  isVerified: false,
  isOTPError: false,
  isMobileEmailUpdate: false,
  getVerificationCodeSuccess: false,
  registerErrorResponse: undefined,
  verifyCodeErrorResponse: undefined,
};

const registrationSlice = createSlice({
  name: "Registration",
  initialState: initialState,
  reducers: {
    getVerificationCode(state, action: PayloadAction<VerificationCodePayload>) {
      state.loading = true;
      state.isMobileEmailUpdate = false;
    },

    getVerificationCodeSuccess(state, action: PayloadAction<VerifyResponse>) {
      state.getVerificationCodeSuccess = true;
      state.loading = false;
    },

    getVerificationCodeError(state, action: PayloadAction<ErrorResponse>) {
      state.loading = false;
      state.verifyCodeErrorResponse = action.payload;
    },

    verifyCode(state, action: PayloadAction<VerifyCodePayload>) {
      state.loading = true;
    },

    verifyCodeSuccess(state, action: PayloadAction<VerifyResponse>) {
      state.loading = false;
      state.isVerified = true;
      state.isOTPError = false;
    },

    verifyCodeError(state, action: PayloadAction<ErrorResponse>) {
      state.loading = false;
      state.isVerified = false;
      state.isOTPError = true;
      state.verifyCodeErrorResponse = action.payload;
    },

    resetMobileVerification(state) {
      if (state.isVerified) state.isMobileEmailUpdate = true;
      state.isVerified = false;
      state.isOTPError = false;
      state.verifyCodeErrorResponse = undefined;
      state.registerErrorResponse = undefined;
      state.getVerificationCodeSuccess = false;
    },

    registerUser(state, action: PayloadAction<RegistrationPayload>) {
      state.loading = true;
    },

    registerSocialUser(
      state,
      action: PayloadAction<SocialRegistrationPayload>
    ) {
      state.loading = true;
    },

    registerSocialUserSuccess(state) {
      state.loading = false;
    },

    clearRegistration(state) {
      return (state = initialState);
    },

    registerError(state, action: PayloadAction<ErrorResponse>) {
      state.loading = false;
      state.isVerified = false;
      state.registerErrorResponse = action.payload;
    },
  },
});

const { actions, reducer } = registrationSlice;
export type RegistrationActionType = AllActionsOf<typeof actions>;
export {
  actions as RegistrationAction,
  reducer as registrationReducer,
  initialState,
};
