import { createSlice } from "@reduxjs/toolkit";
import { AllActionsOf } from "../utils/Actions";
import { StorybookState } from "./model";

const initialState: StorybookState = {
  showStorybook: false,
};

const storybookSlice = createSlice({
  name: "storybook",
  initialState: initialState,
  reducers: {
    showStorybook(state) {
      state.showStorybook = true;
    },
  },
});

const { actions, reducer } = storybookSlice;
export type storybookActionType = AllActionsOf<typeof actions>;
export {
  actions as storybookActions,
  reducer as storybookReducer,
  initialState,
};
