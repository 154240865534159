/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AllActionsOf } from "../../redux_store/utils/Actions";
import { BrazeSubscriptionGroup, NotificationsCenterState } from "./models";
import { ErrorResponse } from "../error/models";

export const initialState: NotificationsCenterState = {
  subscriptionGroups: [],
  isLoading: false,
  errorResponse: null,
  isUpdateSuccess: false,
};

const notificationsCenterSlice = createSlice({
  name: "notificationsCenter",
  initialState,
  reducers: {
    getNotificationsCenter(state) {
      state.isLoading = true;
      state.errorResponse = null;
      state.isUpdateSuccess = false;
    },

    getNotificationsCenterError(state) {
      state.isLoading = false;
      state.isUpdateSuccess = false;
    },

    getNotificationsCenterSuccess(
      state,
      action: PayloadAction<BrazeSubscriptionGroup[]>
    ) {
      state.isLoading = false;
      state.errorResponse = null;
      state.isUpdateSuccess = false;
      if (action.payload != undefined) {
        state.subscriptionGroups = action.payload;
      }
    },

    updateNotificationsCenter(
      state,
      action: PayloadAction<BrazeSubscriptionGroup[]>
    ) {
      state.isLoading = true;
      state.errorResponse = null;
      state.isUpdateSuccess = false;
    },

    updateNotificationsCenterStatusSuccess(state) {
      state.isLoading = false;
      state.errorResponse = null;
      state.isUpdateSuccess = true;
    },

    updateNotificationsCenterStatusError(
      state,
      action: PayloadAction<ErrorResponse | undefined>
    ) {
      state.isLoading = false;
      if (action.payload) {
        state.errorResponse = action.payload;
        state.isUpdateSuccess = false;
      }
    },

    reset(state) {
      state.errorResponse = null;
      state.isLoading = false;
      state.isUpdateSuccess = false;
    },
  },
});

const { actions, reducer } = notificationsCenterSlice;
export type NotificationsCenterActionsType = AllActionsOf<typeof actions>;
export {
  reducer as notificationsCenterReducer,
  actions as notificationsCenterActions,
};
