import { FavouriteCartItem } from "../../redux_store/favourite/models";
import { MenuListItemProps } from "../../redux_store/menu/models";

export const mapFavouriteToMenu = (
  favouriteCartItems: FavouriteCartItem[]
): MenuListItemProps[] => {
  const itemList: MenuListItemProps[] = [];

  for (const favouriteCartItem of favouriteCartItems) {
    const favouriteItem = favouriteCartItem.cartItem;
    if (favouriteItem) {
      const item: MenuListItemProps = {
        favourite: {
          id: favouriteItem.productId,
          price: favouriteItem.unitPrice,
          imageTopDownUrl: favouriteCartItem.imageTopDownUrl,
          name: favouriteItem.name,
          description: "",
          displayOrder: 0,
        },
      };
      itemList.push(item);
    }
  }
  return itemList;
};
