import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  AuthReduxAction,
  RootState,
  SocialActions,
  UserReduxAction,
} from "gyg_common";
import ModalWithButton from "gyg_common/components/modals/ModalWithButton";
import { Screens } from "navigation/const";

export const MobileVerificationContainer: React.FC = () => {
  const { showMobileVerifyModal } = useSelector(
    (state: RootState) => state.user
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const onContinue = () => {
    dispatch(UserReduxAction.setShowMobileVerifyModal(false));
    dispatch(UserReduxAction.setExistingUserMobileVerify(true));
    history.push(Screens.UserProfile);
  };

  const onLogout = () => {
    dispatch(UserReduxAction.setShowMobileVerifyModal(false));
    dispatch(SocialActions.setSocialRegister(false));
    localStorage.removeItem("CurrentUser");
    dispatch(AuthReduxAction.logoutCurrentDevice());
    history.push(Screens.Login);
  };

  return (
    <ModalWithButton
      image={require("gyg_common/components/assets/images/illustration_error.png")}
      isVisible={showMobileVerifyModal}
      title={t("UserManagement:existingVerifyModalTitle")}
      messageBold={t("UserManagement:existingVerifyModalDescription")}
      primaryButton={{
        name: t("UserManagement:existingVerifyModalContinueButton"),
        action: onContinue,
      }}
      secondaryButton={{
        name: t("UserManagement:existingVerifyModalLogoutButton"),
        action: onLogout,
      }}
      onModalClose={() => undefined}
      showCloseButton={false}
      small={true}
      modalId='mobile-verify-alert'
    />
  );
};
